// import React, { useContext,useState } from 'react'
// import { Link } from 'react-router-dom'
// import AuthContext from '../context/AuthContext'
// import ForgotPassword from './ForgetPasswordPage'
// import Modal from 'react-modal';
// import sin from './images/sin.png'



// function Loginpage() {

//   const {loginUser} = useContext(AuthContext)
//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   const openModal = () => {
//     setModalIsOpen(true);
//   };
  
//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   const handleSubmit = e => {
//     e.preventDefault()
//     const email = e.target.email.value
//     const password = e.target.password.value

//     email.length > 0 && loginUser(email, password)

//     console.log(email)
//     console.log(password)
   
//   }

//   return (
//     <div>
//       <>
//   <section className="vh-100" style={{ backgroundColor: "#7cb474" }}>
//     <div className="container py-5 h-100">
//       <div className="row d-flex justify-content-center align-items-center h-100">
//         <div className="col col-xl-10">
//           <div className="card" style={{ borderRadius: "1rem" }}>
//             <div className="row g-0">
//               <div className="col-md-6 col-lg-5 d-none d-md-block">
//                 <img
//                   src= {sin}
//                   alt="login form"
//                   className="img-fluid"
//                   style={{ borderRadius: "1rem 0 0 1rem" }}
//                 />
//               </div>
//               <div className="col-md-6 col-lg-7 d-flex align-items-center">
//                 <div className="card-body p-4 p-lg-5 text-black">
//                   <form onSubmit={handleSubmit}>
//                     <div className="d-flex align-items-center mb-3 pb-1">
//                       <i
//                         className="fas fa-cubes fa-2x me-3"
//                         style={{ color: "#ff6219" }}
//                       />
//                       <div className="d-flex align-items-center mb-3 pb-1">
//                         <i
//                           className="fas fa-cubes fa-2x me-3"
//                           style={{ color: "#ff6219" }}
//                         />
//                         <span className="h2 fw-bold mb-0">Welcome back 👋</span>
//                       </div>
//                     </div>
//                     <h5
//                       className="fw-normal mb-3 pb-3"
//                       style={{ letterSpacing: 1 }}
//                     >
//                       Sign into your account
//                     </h5>
//                     <div className="form-outline mb-4">
//                       <input
//                         type="email"
//                         id="form2Example17"
//                         className="form-control form-control-lg"
//                         name='email'
//                       />
//                       <label className="form-label" htmlFor="form2Example17">
//                         Email address
//                       </label>
//                     </div>
//                     <div className="form-outline mb-4">
//                       <input
//                         type="password"
//                         id="form2Example27"
//                         className="form-control form-control-lg"
//                         name='password'
//                       />
//                       <label className="form-label" htmlFor="form2Example27">
//                         Password
//                       </label>
//                     </div>
//                     <div className="pt-1 mb-4">
//                       <button
//                         className="btn btn-dark btn-lg btn-block"
//                         type="submit"
//                       >
//                         Login
//                       </button>
//                     </div>
//                       <a className="small text-muted" href="#!" onClick={openModal}>
//                          Forgot password?
//                       </a>

//                            <Modal
//                             isOpen={modalIsOpen}
//                             onRequestClose={closeModal}
//                             contentLabel="Forgot Password Modal"
//                             >
//                             <ForgotPassword />
//                             <button onClick={closeModal}>Close</button>
//                           </Modal>

//                     <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
//                       Don't have an account?{" "}
//                       <Link to="/register" style={{ color: "#393f81" }}>
//                         Register Now 
//                       </Link>
//                     </p>
//                     <a href="#!" className="small text-muted">
//                       Terms of use.
//                     </a>
//                     <a href="#!" className="small text-muted">
//                       Privacy policy
//                     </a>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
//   <footer className="bg-light text-center text-lg-start">
//     <div
//       className="text-center p-3"
//       style={{ backgroundColor: '#7cb474' }}
//     >
//       © Geoneer Geospatial Services
//       <a className="text-dark" href="https://geoneer.com.np/">
//         Geoneer
//       </a>
//     </div>
//   </footer>
// </>

//     </div>
//   )
// }

// export default Loginpage

// import React, { useContext,useState } from 'react'
// import { Link } from 'react-router-dom'
// import AuthContext from '../context/AuthContext'
// import ForgotPassword from './ForgetPasswordPage'
// import Modal from 'react-modal';
// import sin from './images/sin.png'



// function Loginpage() {

//   const {loginUser} = useContext(AuthContext)
//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   const openModal = () => {
//     setModalIsOpen(true);
//   };
  
//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   const handleSubmit = e => {
//     e.preventDefault()
//     const email = e.target.email.value
//     const password = e.target.password.value

//     email.length > 0 && loginUser(email, password)

//     console.log(email)
//     console.log(password)
   
//   }

//   return (
//     <div>
//       <>
//   <section className="vh-100" style={{ backgroundColor: "#7cb474" }}>
//     <div className="container py-5 h-100">
//       <div className="row d-flex justify-content-center align-items-center h-100">
//         <div className="col col-xl-10">
//           <div className="card" style={{ borderRadius: "1rem" }}>
//             <div className="row g-0">
//               <div className="col-md-6 col-lg-5 d-none d-md-block">
//                 <img
//                   src= {sin}
//                   alt="login form"
//                   className="img-fluid"
//                   style={{ borderRadius: "1rem 0 0 1rem" }}
//                 />
//               </div>
//               <div className="col-md-6 col-lg-7 d-flex align-items-center">
//                 <div className="card-body p-4 p-lg-5 text-black">
//                   <form onSubmit={handleSubmit}>
//                     <div className="d-flex align-items-center mb-3 pb-1">
//                       <i
//                         className="fas fa-cubes fa-2x me-3"
//                         style={{ color: "#ff6219" }}
//                       />
//                       <div className="d-flex align-items-center mb-3 pb-1">
//                         <i
//                           className="fas fa-cubes fa-2x me-3"
//                           style={{ color: "#ff6219" }}
//                         />
//                         <span className="h2 fw-bold mb-0">Welcome back 👋</span>
//                       </div>
//                     </div>
//                     <h5
//                       className="fw-normal mb-3 pb-3"
//                       style={{ letterSpacing: 1 }}
//                     >
//                       Sign into your account
//                     </h5>
//                     <div className="form-outline mb-4">
//                       <input
//                         type="email"
//                         id="form2Example17"
//                         className="form-control form-control-lg"
//                         name='email'
//                       />
//                       <label className="form-label" htmlFor="form2Example17">
//                         Email address
//                       </label>
//                     </div>
//                     <div className="form-outline mb-4">
//                       <input
//                         type="password"
//                         id="form2Example27"
//                         className="form-control form-control-lg"
//                         name='password'
//                       />
//                       <label className="form-label" htmlFor="form2Example27">
//                         Password
//                       </label>
//                     </div>
//                     <div className="pt-1 mb-4">
//                       <button
//                         className="btn btn-dark btn-lg btn-block"
//                         type="submit"
//                       >
//                         Login
//                       </button>
//                     </div>
//                       <a className="small text-muted" href="#!" onClick={openModal}>
//                          Forgot password?
//                       </a>

//                            <Modal
//                             isOpen={modalIsOpen}
//                             onRequestClose={closeModal}
//                             contentLabel="Forgot Password Modal"
//                             >
//                             <ForgotPassword />
//                             <button onClick={closeModal}>Close</button>
//                           </Modal>

//                     <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
//                       Don't have an account?{" "}
//                       <Link to="/register" style={{ color: "#393f81" }}>
//                         Register Now 
//                       </Link>
//                     </p>
//                     <a href="#!" className="small text-muted">
//                       Terms of use.
//                     </a>
//                     <a href="#!" className="small text-muted">
//                       Privacy policy
//                     </a>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
//   <footer className="bg-light text-center text-lg-start">
//     <div
//       className="text-center p-3"
//       style={{ backgroundColor: '#7cb474' }}
//     >
//       © Geoneer Geospatial Services
//       <a className="text-dark" href="https://geoneer.com.np/">
//         Geoneer
//       </a>
//     </div>
//   </footer>
// </>

//     </div>
//   )
// }

// export default Loginpage






import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import ForgotPassword from './ForgetPasswordPage';
import Modal from 'react-modal';
import sin from './images/sin.png';

function LoginPage() {
  const { loginUser } = useContext(AuthContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    email.length > 0 && loginUser(email, password);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-500 to-green-600">
      <div className="container mx-auto px-4 py-8 h-full">
        <div className="flex justify-center items-center min-h-[calc(100vh-4rem)]">
          <div className="w-full max-w-6xl">
            <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
              <div className="flex flex-col md:flex-row">
                {/* Image Section */}
                <div className="md:w-5/12 relative hidden md:block">
                  <img
                    src={sin}
                    alt="login visual"
                    className="w-full h-full object-cover"
                    style={{ minHeight: '600px' }}
                  />
                  <div className="absolute inset-0 bg-green-600/20"></div>
                </div>

                {/* Form Section */}
                <div className="md:w-7/12 p-8 md:p-12">
                  <div className="max-w-md mx-auto">
                    <div className="text-center mb-8">
                      <h2 className="text-3xl font-bold text-gray-800 mb-2">Welcome back 👋</h2>
                      <p className="text-gray-600">Sign into your account</p>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-6">
                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700" htmlFor="email">
                          Email address
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all"
                          placeholder="Enter your email"
                          required
                        />
                      </div>

                      <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700" htmlFor="password">
                          Password
                        </label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all"
                          placeholder="Enter your password"
                          required
                        />
                      </div>

                      <button
                        type="submit"
                        className="w-full bg-green-600 text-white py-3 rounded-lg font-medium hover:bg-green-700 transition-colors duration-200"
                      >
                        Sign In
                      </button>

                      <div className="flex items-center justify-between text-sm">
                        <button
                          type="button"
                          onClick={openModal}
                          className="text-green-600 hover:text-green-700 transition-colors"
                        >
                          Forgot password?
                        </button>
                        <Link
                          to="/register"
                          className="text-green-600 hover:text-green-700 transition-colors"
                        >
                          Register Now
                        </Link>
                      </div>
                    </form>

                    <div className="mt-8 pt-6 border-t border-gray-200">
                      <div className="flex justify-center space-x-4 text-xs text-gray-500">
                        <a href="/" className="hover:text-green-600 transition-colors">
                          Terms of use
                        </a>
                        <span>•</span>
                        <a href="/" className="hover:text-green-600 transition-colors">
                          Privacy policy
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-green-600/10 py-4">
        <div className="container mx-auto px-4 text-center text-green-800">
          © Examneer - Exam of Engineers
          <a href="https://examneer.com" className="ml-2 hover:text-green-700 transition-colors">
            Visit Website
          </a>
        </div>
      </footer>

      {/* Forgot Password Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Forgot Password Modal"
        className="max-w-md mx-auto mt-20 bg-white p-6 rounded-lg shadow-xl"
        overlayClassName="fixed inset-0 bg-black/50 flex items-center justify-center px-4"
      >
        <div className="relative">
          <button
            onClick={closeModal}
            className="absolute top-0 right-0 p-2 text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
          <ForgotPassword />
        </div>
      </Modal>
    </div>
  );
}

export default LoginPage;

