// // QRPopup.js
// import React from 'react';
// import qrCodeImage from './images/geoqr.jpeg'; // Add your QR code image here
// import'./qr.css';

// const QRPopup = ({ onClose }) => {
//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <h3>Payment Instructions</h3>
//         <p>Please scan QR code below to make the payment.</p>
//         <p><bold>For Survey Officer (PSC) Preparation Rs. 2000</bold> <br/>
//            <bold>For Geomatics Engineering NEC License Preparation Rs. 1000</bold>
//         </p>
//         <img src={qrCodeImage} alt="QR Code for payment" style={{ width: '300px', height: '300px' }} />
//         <p>After payment, upload the payment screenshot using the form below.</p>
//         <p>If your account is not activated in half an hour of the payment please contact at <b>9857885572,9862467445</b></p>
//         <button className="btn btn-dark" onClick={onClose}>Close</button>
//       </div>
//     </div>
//   );
// };

// export default QRPopup;


import React from 'react';
import qrCodeImage from './images/geoqr.jpeg';
import { X } from 'lucide-react';

const QRPopup = ({ onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg sm:w-auto animate-fadeIn relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition"
          aria-label="Close popup"
        >
          <X className="w-5 h-5 text-gray-700" />
        </button>

     

        {/* Content */}
        <div className="p-4 space-y-6">
          {/* Instructions */}
          <div className="text-center text-gray-700">
            <p className="text-sm">
              Please scan the QR code below to make the payment.
            </p>
          </div>

          {/* Price Information */}
          <div className="bg-green-50 p-2 rounded-md shadow text-center">
  <p className="text-[10px] font-medium text-green-800">
    Geomatics Engineering NEC License Preparation
  </p>
  <p className="text-sm font-bold text-green-700 mt-0.5">Rs. 1,000</p>
</div>
<div className="bg-green-50 p-2 rounded-md shadow text-center mt-2">
  <p className="text-[10px] font-medium text-green-800">
    Survey Officer PSC Preparation
  </p>
  <p className="text-sm font-bold text-green-700 mt-0.5">Rs. 2,000</p>
</div>



          {/* QR Code */}
          <div className="flex justify-center">
            <img
              src={qrCodeImage}
              alt="QR Code"
              className="w-40 h-40 object-contain border border-green-200 rounded-lg shadow-sm"
            />
          </div>

          {/* Additional Instructions */}
          <div className="text-sm text-gray-600">
            <p>
              After payment, upload the payment screenshot using the form
              below. If your account is not activated within 30 minutes of
              payment, please contact: 📞 9857885572 📞 9862467445
            </p>
          </div>
        </div>

        {/* Footer */}
        <div className="border-t border-gray-200 p-3">
          <button
            onClick={onClose}
            className="w-full py-2 bg-gradient-to-r from-green-600 to-green-700 text-white rounded-lg hover:shadow-lg transition-all duration-300 flex items-center justify-center gap-2 font-semibold"
          >
            <X className="w-5 h-5" />
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRPopup;



