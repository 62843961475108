

import React, { useState, useEffect, useContext,useRef } from 'react';
import axios from 'axios';
import '../../components/quiz.css';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AuthContext from '../../context/AuthContext';

const AnswerSheet = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [questions, setQuestions] = useState([]);
  const location = useLocation();
  const { authTokens,user} = useContext(AuthContext);
  const questionRef = useRef(null);
    const [testDetails, setTestDetails] = useState(null);



  const id = location.state?.id;


  useEffect(() => {
    // Fetch the exam details
    axios
      .get(`https://nec.geoneer.com.np/api/exam/user/tests/store/${user.user_id}/${id}/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      })
      .then((response) => {
        setIsLoaded(true);
        setQuestions(response.data.questions);
        setTestDetails(response.data.test_result);
      })
      .catch((error) => {
        setIsLoaded(true);
        setError(error);
      });
  }, [authTokens,id,user.user_id]);

  const getOptionClassName = (question, option) => {
    if (question.user_selected_option === option) {
      return question.is_correct ? 'option selected' : 'option wrong';
    }
    return 'option';
  };

  const safeParse = (text) => (typeof text === 'string' ? parse(text) : text || '');

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }


    const { unattempted_count, wrong_count, weekly_test_name, total_questions } = testDetails;


  return (


    
    <div className="quiz-container">

     <h5>Weekly Test: {weekly_test_name}</h5>
      <div className="stats">
        <p>
          <strong>Total Questions:</strong> {total_questions}
        </p>
        <p>
          <strong>Unattempted Questions:</strong> {unattempted_count}
        </p>
        <p>
          <strong>Correct Answers:</strong> {total_questions-wrong_count-unattempted_count}
        </p>

        <p>
          <strong>Wrong Answers:</strong> {wrong_count}
        </p>
      </div>

      
      {/* <h1>Chapter-Wise Exam</h1> */}

      {questions
        .slice() // This creates a shallow copy of the array to avoid mutating the original `questions` array
        .reverse() // Reverse the array
        
        .map((question, index ) => (

        
        <div key={question.id} className="question-container">
          {/* <p>
            <strong>Q{index + 1}:</strong> {safeParse(question.question_text)}
          </p> */}

<h6>
  <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
    <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
    Q{index + 1}:
   
    </span>
    <span style={{ flex: 1 }}>
    {safeParse(question.question_text)}
    </span>
  </div>
</h6>
          {/* <div className="option-text" style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
            {[1, 2, 3, 4].map((optIndex) => {
              const optionKey = `option_${optIndex}`;
              return (
                <button
                  key={optIndex}
                  type="button"
                  className={getOptionClassName(question, `${optIndex}`)}
                  disabled
                >
                  {safeParse(question.options?.[optionKey])}
                </button>
              );
            })}
              
          </div> */}

<div className="option-text" style={{ textAlign: 'left', padding: '10px', background: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
  <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
    {[1, 2, 3, 4].map((optIndex) => {
      const optionKey = `option_${optIndex}`;
      return (
        <li key={optIndex} style={{ marginBottom: '12px' }}>
          <button
            type="button"
            className={getOptionClassName(question, `${optIndex}`)}
            disabled
            style={{
              width: '100%',
              padding: '12px 16px',
              fontSize: '16px',
              color: '#333',
            //   background: '#fff',
              border: '2px solid #ddd',
              borderRadius: '8px',
              textAlign: 'left',
              transition: 'all 0.3s ease',
              cursor: 'not-allowed',
            }}
          >
            {safeParse(question.options?.[optionKey])}
          </button>
          
        </li>
        
      );
    })}
  </ul>
</div>

<div className="correct-answer" >
              <p>
                <strong>Correct Answer:</strong>{' '}
                {safeParse(
                   question.correctOpt
                )}
              </p>
              <p
  className="explanation text-left" style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}
//   style={{
//     whiteSpace: 'nowrap', // Prevent text wrapping
//     overflow: 'hidden', // Hide overflowing text
//     textOverflow: 'ellipsis', // Add "..." for overflow
//   }}
>
  <strong>Explanation:  </strong> {safeParse(question.explanation)}
</p>
<br></br>
<br></br>
            </div>


          {question.user_selected_option === null && (
            <div className="correct-answer">
              <p>
                {/* <strong>Correct Answer:</strong>{' '}
                {safeParse(
                //   question.options?.[`option_${question.correctOpt}`]
                question.correctOpt
                )} */}
              </p>
              <p className="explanation text-left">
                {/* <strong>Explanation: {safeParse(question.explanation)}</strong>  */}
              
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AnswerSheet;