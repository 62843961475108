// import React ,{useState,useEffect} from 'react';
// import './Profile.css'; // Import a CSS file for styling
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { Tab, Tabs } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';


// const Profile = ({ match }) => {

//   const [key, setKey] = useState('tab1');


//   const [userDetails, setUserDetails] = useState(null);
  
//   useEffect(() => {
//     // Retrieve user details from localStorage once (only on mount)
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       setUserDetails(JSON.parse(storedUserDetails));
//     }
//   }, []); // Empty dependency array means this effect only runs once on mount

//   useEffect(() => {
//     // If userDetails is available and courseName is part of the URL, fetch the course details
//     if (userDetails && match.params.courseName) {

//     }
//   }, [userDetails, match.params.courseName]); 
  
//   return (
    
//     <div className="profile-container">
       

//        {userDetails ? (
//   <>
//     <div className="profile-header">
//       <h1>User Profile</h1>
//       {/* <p>Status: {is_active ? 'Active' : 'Inactive'}</p> */}
//     </div>
//     <div className="row">
//       <div className="col-lg-6 col-md-6 col-sm-12">
//         <div className="profile-details">
//           <div className="profile-info">
//             <h2>Personal Information</h2>
//             <p><strong>Email:{userDetails.email}</strong> {}</p>
//             <p><strong>First Name:</strong> {userDetails.first_name}</p>
//             <p><strong>Last Name:</strong> {userDetails.last_name}</p>
//             <p><strong>Phone Number :{userDetails.phone_number}</strong></p>
//             <a href='/update-password/'>
//               <Button variant="warning btn-sm">Update Password</Button>
//             </a>
//           </div>
//         </div>
//       </div>
//       <div className="col-lg-6 col-md-6 col-sm-12">
//         <div className="course-info">
//           <h2>Course Information</h2>
//           <p><strong>Course Name:</strong> {userDetails.course.courseName}</p>
//           <p><strong>Course Code:</strong> {userDetails.course.courseCode}</p>
//           <p><strong>Description:</strong> {userDetails.course.courseDescription}</p>
//           <p><strong>Validity :</strong> {userDetails.validity}</p>
//         </div>
//       </div>
//     </div>
//   </>
// ) : (
//   <p>Loading course details...</p>
// )}


// <div>
  
// </div>


//   <hr/>

//       <div className="container mt-3">
//         <h4>Results</h4>
//       <Tabs
//                 id="controlled-tab-example"
//                 activeKey={key}
//                 onSelect={(k) => setKey(k)}
//                 className="mb-3"
//             >
//                 <Tab eventKey="tab1" title="Chapterwise Test Result">
//                     <div className="table-responsive">
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                     <th>S.N.</th>
//                                     <th>Date /Time</th>
//                                     <th>Chapter</th>
//                                     <th>Sub-Chapter</th>
//                                     <th>Score</th>
//                                     <th>Total Score</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr>
//                                     <td>Data 1</td>
//                                     <td>Data 2</td>
//                                     <td>Data 3</td>
//                                     <td>Data 4</td>
//                                     <td>Data 5</td>
//                                     <td>Data 6</td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div>
//                 </Tab>
//                 <Tab eventKey="tab2" title="Mock Exam Results">
//                     <div className="table-responsive">
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                 <th>S.N.</th>
//                                 <th>Date /Time</th>
//                                 <th>Score</th>
//                                 <th>Total Score</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr>
//                                     <td>Data 3</td>
//                                     <td>Data 4</td>
//                                     <td>Data 5</td>
//                                     <td>Data 6</td>
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div>
//                 </Tab>
//                 <Tab eventKey="tab3" title="Weekly Test Results">
//                     <div className="table-responsive">
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                 <th>S.N.</th>
//                                 <th>Date /Time</th>
//                                 <th>Test Name</th>
//                                 <th>Score</th>
//                                 <th>Total Score</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 <tr>
//                                     <td>Data 5</td>
//                                     <td>Data 6</td>
//                                     <td>Data 7</td>
//                                     <td>Data 8</td>
                                      
//                                 </tr>
//                             </tbody>
//                         </table>
//                     </div>
//                 </Tab>
//             </Tabs>
//       </div>
     
    
   
//       </div>
      
    
//   );
// };

// export default Profile;


// import React, { useState, useEffect } from 'react';
// import './Profile.css'; // Import a CSS file for styling
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// // import { Tab, Tabs } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';

// const Profile = ({ match }) => {
//   // const [key, setKey] = useState('tab1');
//   const [userDetails, setUserDetails] = useState(null);
//   const [isExpired, setIsExpired] = useState(false);

//   useEffect(() => {
//     // Retrieve user details from localStorage once (only on mount)
//     const storedUserDetails = localStorage.getItem('userDetails');
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);
//       setUserDetails(parsedDetails);

//       // Check if the validity date is expired
//       const today = new Date();
//       const validityDate = new Date(parsedDetails.validity);
//       if (validityDate < today) {
//         setIsExpired(true);
//       }
//     }
//   }, []);

//   return (
//     <div className="profile-container">
//       {userDetails ? (
//         <>
//           <div className="profile-header">
//             <h1>User Profile</h1>
//           </div>

//           {isExpired && (
//         <div className="marquee-container">
//         <p>Please pay the fee for activation of the course</p>
//       </div>
//           )}

//           <div className="row">
//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <div className="profile-details">
//                 <div className="profile-info">
//                   <h2>Personal Information</h2>
//                   <p>
//                     <strong>Email:</strong> {userDetails.email}
//                   </p>
//                   <p>
//                     <strong>First Name:</strong> {userDetails.first_name}
//                   </p>
//                   <p>
//                     <strong>Last Name:</strong> {userDetails.last_name}
//                   </p>
//                   <p>
//                     <strong>Phone Number:</strong> {userDetails.phone_number}
//                   </p>
//                   <a href="/update-password/">
//                     <Button variant="warning btn-sm">Update Password</Button>
//                   </a>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-6 col-md-6 col-sm-12">
//               <div className="course-info">
//                 <h2>Course Information</h2>
//                 <p>
//                   <strong>Course Name:</strong> {userDetails.course.courseName}
//                 </p>
//                 <p>
//                   <strong>Course Code:</strong> {userDetails.course.courseCode}
//                 </p>
//                 <p>
//                   <strong>Description:</strong> {userDetails.course.courseDescription}
//                 </p>
//                 <p>
//                   <strong>Validity:</strong>{' '}
//                   <span style={{ color: isExpired ? 'red' : 'inherit' }}>
//                     {userDetails.validity}
//                   </span>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </>
//       ) : (
//         <p>Loading course details...</p>
//       )}

//       <hr />

//       {/* <div className="container mt-3">
//         <h4>Results</h4>
//         <Tabs
//           id="controlled-tab-example"
//           activeKey={key}
//           onSelect={(k) => setKey(k)}
//           className="mb-3"
//         >
//           <Tab eventKey="tab1" title="Chapterwise Test Result">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th>S.N.</th>
//                     <th>Date / Time</th>
//                     <th>Chapter</th>
//                     <th>Sub-Chapter</th>
//                     <th>Score</th>
//                     <th>Total Score</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Data 1</td>
//                     <td>Data 2</td>
//                     <td>Data 3</td>
//                     <td>Data 4</td>
//                     <td>Data 5</td>
//                     <td>Data 6</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           </Tab>
//           <Tab eventKey="tab2" title="Mock Exam Results">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th>S.N.</th>
//                     <th>Date / Time</th>
//                     <th>Score</th>
//                     <th>Total Score</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Data 3</td>
//                     <td>Data 4</td>
//                     <td>Data 5</td>
//                     <td>Data 6</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           </Tab>
//           <Tab eventKey="tab3" title="Weekly Test Results">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th>S.N.</th>
//                     <th>Date / Time</th>
//                     <th>Test Name</th>
//                     <th>Score</th>
//                     <th>Total Score</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Data 5</td>
//                     <td>Data 6</td>
//                     <td>Data 7</td>
//                     <td>Data 8</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           </Tab>
//         </Tabs>
//       </div> */}
//     </div>
//   );
// };

// export default Profile;



import React, { useState, useEffect } from 'react';
import './Profile.css';
import { Button } from 'react-bootstrap';

const Profile = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const storedUserDetails = localStorage.getItem('userDetails');
    if (storedUserDetails) {
      const parsedDetails = JSON.parse(storedUserDetails);
      setUserDetails(parsedDetails);

      const today = new Date();
      const validityDate = new Date(parsedDetails.validity);
      if (validityDate < today) {
        setIsExpired(true);
      }
    }
  }, []);

  if (!userDetails) {
    return <div className="loading">Loading course details...</div>;
  }

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1>User Profile</h1>
      </div>

      {isExpired && (
        <div className="marquee-container">
          <p>Please pay the fee for activation of the course</p>
        </div>
      )}

      <div className="profile-content">
        <div className="profile-section">
          <h2>Personal Information</h2>
          <div className="info-grid">
            <div className="info-item">
              <strong>Email:</strong>
              <span>{userDetails.email}</span>
            </div>
            <div className="info-item">
              <strong>First Name:</strong>
              <span>{userDetails.first_name}</span>
            </div>
            <div className="info-item">
              <strong>Last Name:</strong>
              <span>{userDetails.last_name}</span>
            </div>
            <div className="info-item">
              <strong>Phone Number:</strong>
              <span>{userDetails.phone_number}</span>
            </div>
          </div>
          <div className="button-container">
            <Button variant="warning" href="/update-password/">
              Update Password
            </Button>
          </div>
        </div>

        <div className="profile-section">
          <h2>Course Information</h2>
          <div className="info-grid">
            <div className="info-item">
              <strong>Course Name:</strong>
              <span>{userDetails.course.courseName}</span>
            </div>
            <div className="info-item">
              <strong>Course Code:</strong>
              <span>{userDetails.course.courseCode}</span>
            </div>
            <div className="info-item">
              <strong>Description:</strong>
              <span>{userDetails.course.courseDescription}</span>
            </div>
            <div className="info-item">
              <strong>Validity:</strong>
              <span className={isExpired ? 'expired' : ''}>
                {userDetails.validity}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
