
// import React, { useState, useEffect } from 'react';
// import './home.css'; 
// import c1 from './images/c1.png';
// import c2 from './images/c2.png';
// import c3 from './images/c3.png';

// const Homepage = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const slides = [
//     { image: c1 },
//     { image: c2 },
//     { image: c3 },
//   ];

//   const totalSlides = slides.length;

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
//     }, 5000);

//     return () => clearInterval(interval);
//   }, [totalSlides]);

//   const goToSlide = (index) => {
//     setCurrentSlide(index);
//   };

//   // const nextSlide = () => {
//   //   setCurrentSlide((currentSlide + 1) % totalSlides);
//   // };

//   // const prevSlide = () => {
//   //   setCurrentSlide((currentSlide - 1 + totalSlides) % totalSlides);
//   // };

//   return (
//     <div className="carousel-container">
//       {slides.map((slide, index) => (
//         <div
//           key={index}
//           className={`slide ${index === currentSlide ? 'active' : ''}`}
//         >
//           <img src={slide.image} alt={`Slide ${index}`} />
//           <div className="text-box">
//             {/* <h1>{slide.text}</h1>
//             <button>Learn More</button> */}
//           </div>
//         </div>
//       ))}
//       <div className="dots-container">
//         {slides.map((_, index) => (
//           <div
//             key={index}
//             className={`dot ${index === currentSlide ? 'active' : ''}`}
//             onClick={() => goToSlide(index)}
//           ></div>
//         ))}
//       </div>
//       {/* <div className="arrow left" onClick={prevSlide}>&lt;</div>
//       <div className="arrow right" onClick={nextSlide}>&gt;</div> */}
//     </div>
//   );
// };

// export default Homepage;



// import React, { useState, useEffect } from 'react';
// import './home.css'; 
// import c1 from './images/c1.png';
// import c2 from './images/c2.png';
// import c3 from './images/c3.png';

// const Homepage = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [isHovered, setIsHovered] = useState(false);

//   const slides = [
//     { image: c1 },
//     { image: c2 },
//     { image: c3 },
//   ];

//   const totalSlides = slides.length;

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (!isHovered) {
//         setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
//       }
//     }, 5000);

//     return () => clearInterval(interval);
//   }, [totalSlides, isHovered]);

//   const goToSlide = (index) => {
//     setCurrentSlide(index);
//   };

//   const nextSlide = () => {
//     setCurrentSlide((currentSlide + 1) % totalSlides);
//   };

//   const prevSlide = () => {
//     setCurrentSlide((currentSlide - 1 + totalSlides) % totalSlides);
//   };

//   return (
//     <div 
//       className="carousel-container" 
//       onMouseEnter={() => setIsHovered(true)} 
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       {slides.map((slide, index) => (
//         <div
//           key={index}
//           className={`slide ${index === currentSlide ? 'active' : ''}`}
//         >
//           <img src={slide.image} alt={`Slide ${index}`} loading="lazy" />
//           <div className="text-box">
//             <h1>Slide {index + 1}</h1>
//             <button>Learn More</button>
//           </div>
//         </div>
//       ))}
//       <div className="dots-container">
//         {slides.map((_, index) => (
//           <div
//             key={index}
//             className={`dot ${index === currentSlide ? 'active' : ''}`}
//             onClick={() => goToSlide(index)}
//           ></div>
//         ))}
//       </div>
//       <div className="arrow left" onClick={prevSlide}>&lt;</div>
//       <div className="arrow right" onClick={nextSlide}>&gt;</div>
//     </div>
//   );
// };

// export default Homepage;

// import React from 'react';
// import { motion } from 'framer-motion';

// const HomePage = () => {
//   return (
//     <div className="min-h-screen bg-white text-black">
//       <main className="container mx-auto px-4 py-12">
//         <section className="text-center mb-16">
//           <motion.h1 
//             className="text-5xl font-bold mb-6"
//             initial={{ opacity: 0, y: -20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.8 }}
//           >
//             Welcome to Examneer
//           </motion.h1>
//           <motion.p 
//             className="text-xl mb-8"
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             transition={{ delay: 0.5, duration: 0.8 }}
//           >
//             Your Gateway to Success in Survey Officer PSC and NEC License Exams
//           </motion.p>
//           <motion.div
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ delay: 1, duration: 0.8 }}
//           >
//             <a 
//               href="#get-started" 
//               className="bg-green-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-green-700 transition duration-300"
//             >
//               Start Your Preparation
//             </a>
//           </motion.div>
//         </section>

//         <section className="grid md:grid-cols-2 gap-12 mb-16">
//           <div>
//             <h2 className="text-3xl font-semibold mb-4">Practice for Survey Officer PSC</h2>
//             <p className="mb-4">
//               Examneer offers an extensive collection of objective questions tailored specifically for the Survey Officer Public Service Commission exam. Our platform provides:
//             </p>
//             <ul className="list-disc list-inside space-y-2">
//               <li>Comprehensive question bank covering all exam topics</li>
//               <li>Timed mock tests to simulate real exam conditions</li>
//               <li>Detailed explanations for each question</li>
//               <li>Performance tracking and analysis</li>
//             </ul>
//           </div>
//           <div>
//             <img 
//               src="/placeholder.svg?height=300&width=400" 
//               alt="Survey Officer Exam Preparation" 
//               className="rounded-lg shadow-lg"
//             />
//           </div>
//         </section>

//         <section className="grid md:grid-cols-2 gap-12 mb-16">
//           <div className="order-2 md:order-1">
//             <img 
//               src="/placeholder.svg?height=300&width=400" 
//               alt="NEC License Exam Preparation" 
//               className="rounded-lg shadow-lg"
//             />
//           </div>
//           <div className="order-1 md:order-2">
//             <h2 className="text-3xl font-semibold mb-4">Prepare for The NEC License</h2>
//             <p className="mb-4">
//               Master the Nepal Engineering Council License exam with our specialized resources:
//             </p>
//             <ul className="list-disc list-inside space-y-2">
//               <li>Topic-wise practice questions</li>
//               <li>Up-to-date content aligned with the latest NEC syllabus</li>
//               <li>Interactive problem-solving exercises</li>
//               <li>Progress monitoring and weak area identification</li>
//             </ul>
//           </div>
//         </section>

//         <section className="text-center mb-16">
//           <h2 className="text-3xl font-semibold mb-6">Why Choose Examneer?</h2>
//           <div className="grid md:grid-cols-3 gap-8">
//             <div className="bg-green-50 p-6 rounded-lg">
//               <h3 className="text-xl font-semibold mb-3">Expert-Curated Content</h3>
//               <p>Questions and materials developed by experienced professionals in the field</p>
//             </div>
//             <div className="bg-green-50 p-6 rounded-lg">
//               <h3 className="text-xl font-semibold mb-3">Adaptive Learning</h3>
//               <p>Personalized study plans that adapt to your strengths and weaknesses</p>
//             </div>
//             <div className="bg-green-50 p-6 rounded-lg">
//               <h3 className="text-xl font-semibold mb-3">Mobile-Friendly</h3>
//               <p>Study anytime, anywhere with our responsive design</p>
//             </div>
//           </div>
//         </section>

//         <section id="get-started" className="text-center mb-16">
//           <h2 className="text-3xl font-semibold mb-6">Ready to Excel?</h2>
//           <p className="mb-8 text-lg">
//             Join thousands of successful candidates who have trusted Examneer for their exam preparation.
//           </p>
//           <a 
//             href="/signup" 
//             className="bg-green-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-green-700 transition duration-300"
//           >
//             Create Your Free Account
//           </a>
//         </section>

//         <section className="text-center">
//           <p className="text-sm">
//             Developed with expertise by <a href="https://geoneer.com.np" target="_blank" rel="noopener noreferrer" className="text-green-600 hover:underline">geoneer.com.np</a>
//           </p>
//         </section>
//       </main>
//     </div>
//   );
// };

// export default HomePage;



// 'use client'

// import React from 'react'
// import { motion } from 'framer-motion'
// import { CheckCircle, ArrowRight } from 'lucide-react'

// // Animation variants
// const fadeInUp = {
//   initial: { opacity: 0, y: 20 },
//   animate: { opacity: 1, y: 0 },
//   transition: { duration: 0.6 }
// }

// const staggerChildren = {
//   animate: {
//     transition: {
//       staggerChildren: 0.1
//     }
//   }
// }

// export default function LandingPage() {
//   return (
//     <div className="min-h-screen bg-gradient-to-b from-slate-50 to-white">
//       <style jsx global>{`
//         @keyframes float {
//           0% { transform: translateY(0px); }
//           50% { transform: translateY(-10px); }
//           100% { transform: translateY(0px); }
//         }
        
//         .floating {
//           animation: float 3s ease-in-out infinite;
//         }
        
//         .text-gradient {
//           background: linear-gradient(to right, #1e40af, #3b82f6);
//           -webkit-background-clip: text;
//           -webkit-text-fill-color: transparent;
//         }
        
//         .hover-lift {
//           transition: transform 0.2s ease;
//         }
        
//         .hover-lift:hover {
//           transform: translateY(-5px);
//         }
//       `}</style>

//       <main className="container mx-auto px-4 py-16 max-w-6xl">
//         {/* Hero Section */}
//         <motion.section 
//           initial="initial"
//           animate="animate"
//           variants={staggerChildren}
//           className="text-center mb-20"
//         >
//           <motion.h1 
//             variants={fadeInUp}
//             className="text-5xl md:text-6xl font-bold mb-6 text-gradient"
//           >
//             Your Gateway to Success in NEC License Exams
//           </motion.h1>
//           <motion.p 
//             variants={fadeInUp}
//             className="text-xl md:text-2xl text-gray-600 mb-8"
//           >
//             Your Gateway to Success in Survey Officer PSC and NEC License Exams
//           </motion.p>
//           <motion.div
//             variants={fadeInUp}
//             className="flex justify-center gap-4"
//           >
//             <button className="px-8 py-3 bg-blue-600 text-white rounded-full font-semibold hover:bg-blue-700 transition-colors duration-200 hover-lift flex items-center gap-2">
//               Get Started
//               <ArrowRight className="w-5 h-5" />
//             </button>
//           </motion.div>
//         </motion.section>

//         {/* Practice Section */}
//         <motion.section 
//           initial="initial"
//           animate="animate"
//           variants={staggerChildren}
//           className="grid md:grid-cols-2 gap-12 mb-20"
//         >
//           <motion.div variants={fadeInUp}>
//             <h2 className="text-3xl font-bold mb-6 text-gray-800">
//               Practice for Survey Officer PSC
//             </h2>
//             <p className="text-gray-600 mb-6">
//               Examneer offers an extensive collection of objective questions tailored specifically for the Survey Officer Public Service Commission exam. Our platform provides:
//             </p>
//             <ul className="space-y-4">
//               {[
//                 'Comprehensive question bank covering all exam topics',
//                 'Timed mock tests to simulate real exam conditions',
//                 'Detailed explanations for each question',
//                 'Performance tracking and analysis'
//               ].map((item, index) => (
//                 <motion.li
//                   key={index}
//                   variants={fadeInUp}
//                   className="flex items-start gap-3 text-gray-700"
//                 >
//                   <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" />
//                   <span>{item}</span>
//                 </motion.li>
//               ))}
//             </ul>
//           </motion.div>
//           <motion.div 
//             variants={fadeInUp}
//             className="relative"
//           >
//             <div className="rounded-2xl overflow-hidden shadow-2xl floating">
//               <img
//                 src="/placeholder.svg?height=400&width=600"
//                 alt="Survey Officer Exam Preparation"
//                 className="w-full h-full object-cover"
//               />
//             </div>
//           </motion.div>
//         </motion.section>

//         {/* NEC License Section */}
//         <motion.section 
//           initial="initial"
//           animate="animate"
//           variants={staggerChildren}
//           className="grid md:grid -cols-2 gap-12 mb-20"
//         >
//           <motion.div 
//             variants={fadeInUp}
//             className="relative order-2 md:order-1"
//           >
//             <div className="rounded-2xl overflow-hidden shadow-2xl floating">
//               <img
//                 src="/placeholder.svg?height=400&width=600"
//                 alt="NEC License Exam Preparation"
//                 className="w-full h-full object-cover"
//               />
//             </div>
//           </motion.div>
//           <motion.div variants={fadeInUp} className="order-1 md:order-2">
//             <h2 className="text-3xl font-bold mb-6 text-gray-800">
//               Prepare for The NEC License
//             </h2>
//             <p className="text-gray-600 mb-6">
//               Master the Nepal Engineering Council License exam with our specialized resources:
//             </p>
//             <ul className="space-y-4">
//               {[
//                 'Topic-wise practice questions',
//                 'Up-to-date content aligned with the latest NEC syllabus',
//                 'Interactive problem-solving exercises',
//                 'Progress monitoring and weak area identification'
//               ].map((item, index) => (
//                 <motion.li
//                   key={index}
//                   variants={fadeInUp}
//                   className="flex items-start gap-3 text-gray-700"
//                 >
//                   <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" />
//                   <span>{item}</span>
//                 </motion.li>
//               ))}
//             </ul>
//           </motion.div>
//         </motion.section>

//         {/* Features Grid */}
//         <motion.section 
//           initial="initial"
//           animate="animate"
//           variants={staggerChildren}
//           className="mb-20"
//         >
//           <motion.h2 
//             variants={fadeInUp}
//             className="text-3xl font-bold text-center mb-12 text-gray-800"
//           >
//             Why Choose Examneer?
//           </motion.h2>
//           <div className="grid md:grid-cols-3 gap-8">
//             {[
//               {
//                 title: 'Expert-Curated Content',
//                 description: 'Questions and materials developed by experienced professionals in the field'
//               },
//               {
//                 title: 'Adaptive Learning',
//                 description: 'Personalized study plans that adapt to your strengths and weaknesses'
//               },
//               {
//                 title: 'Mobile-Friendly',
//                 description: 'Study anytime, anywhere with our responsive design'
//               }
//             ].map((feature, index) => (
//               <motion.div
//                 key={index}
//                 variants={fadeInUp}
//                 className="p-6 rounded-xl bg-white shadow-lg hover-lift"
//                 whileHover={{ scale: 1.02 }}
//               >
//                 <h3 className="text-xl font-semibold mb-4 text-gray-800">
//                   {feature.title}
//                 </h3>
//                 <p className="text-gray-600">
//                   {feature.description}
//                 </p>
//               </motion.div>
//             ))}
//           </div>
//         </motion.section>

//         {/* CTA Section */}
//         <motion.section 
//           initial="initial"
//           animate="animate"
//           variants={staggerChildren}
//           className="text-center"
//         >
//           <motion.h2 
//             variants={fadeInUp}
//             className="text-3xl font-bold mb-6 text-gray-800"
//           >
//             Ready to Excel?
//           </motion.h2>
//           <motion.p 
//             variants={fadeInUp}
//             className="text-xl text-gray-600 mb-8"
//           >
//             Join thousands of successful candidates who have trusted Examneer for their exam preparation.
//           </motion.p>
//           <motion.button
//             variants={fadeInUp}
//             className="px-8 py-3 bg-blue-600 text-white rounded-full font-semibold hover:bg-blue-700 transition-colors duration-200 hover-lift flex items-center gap-2 mx-auto"
//           >
//             Get Started Now
//             <ArrowRight className="w-5 h-5" />
//           </motion.button>
//         </motion.section>
//       </main>
//     </div>
//   )
// // }

// import React, { useState, useEffect } from 'react';
// import { CheckCircle, ArrowRight } from 'lucide-react'
// import { motion, AnimatePresence } from 'framer-motion';


// import c1 from './images/c1.png';
// import c2 from './images/c2.png';

// // Animation variants
// const fadeInUp = {
//   initial: { opacity: 0, y: 20 },
//   animate: { opacity: 1, y: 0 },
//   transition: { duration: 0.6 }
// }

// const staggerChildren = {
//   animate: {
//     transition: {
//       staggerChildren: 0.1
//     }
//   }
// }


// const stagger = {
//   animate: {
//     transition: {
//       staggerChildren: 0.1
//     }
//   }
// };

// const textVariants = {
//   hidden: { opacity: 0, y: 20 },
//   visible: { opacity: 1, y: 0 },
//   exit: { opacity: 0, y: -20 }
// };

// const fadeIn = {
//   initial: { opacity: 0, y: 20 },
//   animate: { opacity: 1, y: 0 },
//   transition: { duration: 0.6 }
// };

// const welcomeTexts = [
//   "Welcome to Examneer",
//   "Your Gateway to Success in Survey Officer PSC",
//   "Your Gateway to Success in NEC License Exams"
// ];

// export default function LandingPage() {


//   const [currentTextIndex, setCurrentTextIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentTextIndex((prevIndex) => (prevIndex + 1) % welcomeTexts.length);
//     }, 3000);
//     return () => clearInterval(interval);
//   }, []);


//   return (
//     <div className="min-h-screen bg-gradient-to-b from-slate-50 to-white">
//       <style>
//         {`
//         @keyframes float {
//           0% { transform: translateY(0px); }
//           50% { transform: translateY(-10px); }
//           100% { transform: translateY(0px); }
//         }
        
//         .floating {
//           animation: float 3s ease-in-out infinite;
//         }
        
//         .text-gradient {
//           background: linear-gradient(to right, #1e40af, #3b82f6);
//           -webkit-background-clip: text;
//           -webkit-text-fill-color: transparent;
//         }
        
//         .hover-lift {
//           transition: transform 0.2s ease;
//         }
        
//         .hover-lift:hover {
//           transform: translateY(-5px);
//         }
//         `}
//       </style>

//       <main className="container mx-auto px-4 py-16 max-w-6xl">




//       <motion.section 
//         className="text-center mb-16"
//         initial="initial"
//         animate="animate"
//         variants={stagger}
//       >
//         <div className="h-20 mb-4">
//           <AnimatePresence mode="wait">
//             <motion.h1 
//               key={currentTextIndex}
//               className="text-5xl font-bold text-green-800"
//               initial="hidden"
//               animate="visible"
//               exit="exit"
//               variants={textVariants}
//               transition={{ duration: 0.5 }}
//             >
//               {welcomeTexts[currentTextIndex]}
//             </motion.h1>
//           </AnimatePresence>
//         </div>
//         <motion.p 
//           className="text-xl mb-8 text-gray-600"
//           variants={fadeIn}
//         >
//           Your Gateway to Success in Survey Officer PSC and NEC License Exams
//         </motion.p>
//         <motion.div variants={fadeIn}>
//           <a 
//             href="#get-started" 
//             className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out"
//           >
//             Start Your Preparation
//             <ArrowRight className="ml-2 -mr-1 h-5 w-5" />
//           </a>
//         </motion.div>
//       </motion.section>

//         {/* Practice Section */}
//         <motion.section 
//           initial="initial"
//           animate="animate"
//           variants={staggerChildren}
//           className="grid md:grid-cols-2 gap-12 mb-20"
//         >
//           <motion.div variants={fadeInUp}>
//             <h2 className="text-3xl font-bold mb-6 text-gray-800">
//               Practice for Survey Officer PSC
//             </h2>
//             <p className="text-gray-600 mb-6">
//               Examneer offers an extensive collection of objective questions tailored specifically for the Survey Officer Public Service Commission exam. Our platform provides:
//             </p>
//             <ul className="space-y-4">
//               {[
//                 'Comprehensive question bank covering all exam topics',
//                 'Timed mock tests to simulate real exam conditions',
//                 'Detailed explanations for each question',
//                 'Performance tracking and analysis'
//               ].map((item, index) => (
//                 <motion.li
//                   key={index}
//                   variants={fadeInUp}
//                   className="flex items-start gap-3 text-gray-700"
//                 >
//                   <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" />
//                   <span>{item}</span>
//                 </motion.li>
//               ))}
//             </ul>
//           </motion.div>
//           <motion.div 
//             variants={fadeInUp}
//             className="relative"
//           >
//             <div className="rounded-2xl overflow-hidden shadow-2xl floating">
//               <img
//                 src= {c1}
//                 alt="Survey Officer Exam Preparation"
//                 className="w-full h-full object-cover"
//               />
//             </div>
//           </motion.div>
//         </motion.section>

//         {/* NEC License Section */}
//         <motion.section 
//           initial="initial"
//           animate="animate"
//           variants={staggerChildren}
//           className="grid md:grid-cols-2 gap-12 mb-20"
//         >
//           <motion.div 
//             variants={fadeInUp}
//             className="relative order-2 md:order-1"
//           >
//             <div className="rounded-2xl overflow-hidden shadow-2xl floating">
//               <img
//                 src= {c2}
//                 alt="NEC License Exam Preparation"
//                 className="w-full h-full object-cover"
//               />
//             </div>
//           </motion.div>
//           <motion.div variants={fadeInUp} className="order-1 md:order-2">
//             <h2 className="text-3xl font-bold mb-6 text-gray-800">
//               Prepare for The NEC License
//             </h2>
//             <p className="text-gray-600 mb-6">
//               Master the Nepal Engineering Council License exam with our specialized resources:
//             </p>
//             <ul className="space-y-4">
//               {[
//                 'Topic-wise practice questions',
//                 'Up-to-date content aligned with the latest NEC syllabus',
//                 'Interactive problem-solving exercises',
//                 'Progress monitoring and weak area identification'
//               ].map((item, index) => (
//                 <motion.li
//                   key={index}
//                   variants={fadeInUp}
//                   className="flex items-start gap-3 text-gray-700"
//                 >
//                   <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" />
//                   <span>{item}</span>
//                 </motion.li>
//               ))}
//             </ul>
//           </motion.div>
//         </motion.section>

//         {/* Features Grid */}
//         <motion.section 
//           initial="initial"
//           animate="animate"
//           variants={staggerChildren}
//           className="mb-20"
//         >
//           <motion.h2 
//             variants={fadeInUp}
//             className="text-3xl font-bold text-center mb-12 text-gray-800"
//           >
//             Why Choose Examneer?
//           </motion.h2>
//           <div className="grid md:grid-cols-3 gap-8">
//             {[
//               {
//                 title: 'Expert-Curated Content',
//                 description: 'Questions and materials developed by experienced professionals in the field'
//               },
//               {
//                 title: 'Adaptive Learning',
//                 description: 'Personalized study plans that adapt to your strengths and weaknesses'
//               },
//               {
//                 title: 'Mobile-Friendly',
//                 description: 'Study anytime, anywhere with our responsive design'
//               }
//             ].map((feature, index) => (
//               <motion.div
//                 key={index}
//                 variants={fadeInUp}
//                 className="p-6 rounded-xl bg-white shadow-lg hover-lift"
//                 whileHover={{ scale: 1.02 }}
//               >
//                 <h3 className="text-xl font-semibold mb-4 text-gray-800">
//                   {feature.title}
//                 </h3>
//                 <p className="text-gray-600">
//                   {feature.description}
//                 </p>
//               </motion.div>
//             ))}
//           </div>
//         </motion.section>

//         {/* CTA Section */}
//         <motion.section 
//           initial="initial"
//           animate="animate"
//           variants={staggerChildren}
//           className="text-center"
//         >
//           <motion.h2 
//             variants={fadeInUp}
//             className="text-3xl font-bold mb-6 text-gray-800"
//           >
//             Ready to Excel?
//           </motion.h2>
//           <motion.p 
//             variants={fadeInUp}
//             className="text-xl text-gray-600 mb-8"
//           >
//             Join thousands of successful candidates who have trusted Examneer for their exam preparation.
//           </motion.p>
//           <motion.button
//             variants={fadeInUp}
//             className="px-8 py-3 bg-blue-600 text-white rounded-full font-semibold hover:bg-blue-700 transition-colors duration-200 hover-lift flex items-center gap-2 mx-auto"
//           >
//             Get Started Now
//             <ArrowRight className="w-5 h-5" />
//           </motion.button>
//         </motion.section>
//       </main>
//     </div>
//   )
// }


import React, { useState, useEffect } from 'react';
import { CheckCircle, ArrowRight, Brain, Zap, Book, Smartphone } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import c1 from './images/c1.png';
import c2 from './images/c2.png';

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const textVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }
};

const welcomeTexts = [
  "Welcome to Examneer",
  "Your Gateway to Success in Survey Officer PSC",
  "Your Gateway to Success in NEC License Exams"
];

export default function LandingPage() {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % welcomeTexts.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white">
      <style jsx>{`
        @keyframes float {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-10px); }
        }
        
        .floating {
          animation: float 4s ease-in-out infinite;
        }
        
        .text-gradient {
          background: linear-gradient(to right, #2e7d32, #4CAF50);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        
        .hover-lift {
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        
        .hover-lift:hover {
          transform: translateY(-5px);
          box-shadow: 0 10px 20px rgba(0,0,0,0.1);
        }

        .parallax-bg {
          background-attachment: fixed;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      `}</style>


      <main className="container mx-auto px-4 py-16 max-w-6xl">
        <motion.section 
          id="home"
          className="text-center mb-24"
          initial="initial"
          animate="animate"
          variants={staggerChildren}
        >
          <div className="h-24 mb-8">
            <AnimatePresence mode="wait">
              <motion.h1 
                key={currentTextIndex}
                className="text-5xl md:text-6xl font-bold text-gradient"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={textVariants}
                transition={{ duration: 0.5 }}
              >
                {welcomeTexts[currentTextIndex]}
              </motion.h1>
            </AnimatePresence>
          </div>
          <motion.p 
            className="text-xl md:text-2xl mb-12 text-gray-600"
            variants={fadeInUp}
          >
            Empowering Engineers to Excel in Their Exams
          </motion.p>
          <motion.div variants={fadeInUp}>
            <a 
              href="/login" 
              className="inline-flex items-center px-8 py-4 text-lg font-medium rounded-full shadow-lg text-white bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-300 ease-in-out hover:scale-105"
            >
              Start Your Preparation
              <ArrowRight className="ml-2 -mr-1 h-6 w-6" />
            </a>
          </motion.div>
        </motion.section>

        <motion.section 
          id="practice"
          initial="initial"
          animate="animate"
          variants={staggerChildren}
          className="grid md:grid-cols-2 gap-16 mb-32"
        >
          <motion.div variants={fadeInUp}>
            <h2 className="text-4xl font-bold mb-8 text-green-800">
              Practice for Survey Officer PSC
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Examneer offers an extensive collection of objective questions tailored specifically for the Survey Officer Public Service Commission exam. Our platform provides:
            </p>
            <ul className="space-y-6">
              {[
                'Comprehensive question bank covering all exam topics',
                'Timed mock tests to simulate real exam conditions',
                'Detailed explanations for each question',
                'Performance tracking and analysis'
              ].map((item, index) => (
                <motion.li
                  key={index}
                  variants={fadeInUp}
                  className="flex items-start gap-4 text-gray-700 text-lg"
                >
                  <CheckCircle className="w-8 h-8 text-green-500 flex-shrink-0 mt-1" />
                  <span>{item}</span>
                </motion.li>
              ))}
            </ul>
          </motion.div>
          <motion.div 
            variants={fadeInUp}
            className="relative"
          >
            <div className="rounded-3xl overflow-hidden shadow-2xl floating">
              <img
                src={c1}
                alt="Survey Officer Exam Preparation"
                className="w-full h-full object-cover"
              />
            </div>
          </motion.div>
        </motion.section>

        <motion.section 
          initial="initial"
          animate="animate"
          variants={staggerChildren}
          className="grid md:grid-cols-2 gap-16 mb-32"
        >
          <motion.div 
            variants={fadeInUp}
            className="relative order-2 md:order-1"
          >
            <div className="rounded-3xl overflow-hidden shadow-2xl floating">
              <img
                src={c2}
                alt="NEC License Exam Preparation"
                className="w-full h-full object-cover"
              />
            </div>
          </motion.div>
          <motion.div variants={fadeInUp} className="order-1 md:order-2">
            <h2 className="text-4xl font-bold mb-8 text-green-800">
              Prepare for The NEC License
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Master the Nepal Engineering Council License exam with our specialized resources:
            </p>
            <ul className="space-y-6">
              {[
                'Topic-wise practice questions',
                'Up-to-date content aligned with the latest NEC syllabus',
                'Interactive problem-solving exercises',
                'Progress monitoring and weak area identification'
              ].map((item, index) => (
                <motion.li
                  key={index}
                  variants={fadeInUp}
                  className="flex items-start gap-4 text-gray-700 text-lg"
                >
                  <CheckCircle className="w-8 h-8 text-green-500 flex-shrink-0 mt-1" />
                  <span>{item}</span>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </motion.section>

        <motion.section 
          id="features"
          initial="initial"
          animate="animate"
          variants={staggerChildren}
          className="mb-32"
        >
          <motion.h2 
            variants={fadeInUp}
            className="text-4xl font-bold text-center mb-16 text-green-800"
          >
            Why Choose Examneer?
          </motion.h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12">
            {[
              {
                title: 'Expert-Curated Content',
                description: 'Questions and materials developed by experienced professionals in the field',
                icon: Brain
              },
              {
                title: 'Adaptive Learning',
                description: 'Personalized study plans that adapt to your strengths and weaknesses',
                icon: Zap
              },
              {
                title: 'Comprehensive Resources',
                description: 'Access a wide range of study materials, practice tests, and reference guides',
                icon: Book
              },
              {
                title: 'Mobile Friendly',
                description: 'Study anytime, anywhere with our responsive design',
                icon: Smartphone
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                variants={fadeInUp}
                className="p-8 rounded-2xl bg-white shadow-xl hover-lift flex flex-col items-center text-center"
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <feature.icon className="w-16 h-16 text-green-500 mb-6" />
                <h3 className="text-2xl font-semibold mb-4 text-green-800">
                  {feature.title}
                </h3>
                <p className="text-gray-600 text-lg">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          id="contact"
          initial="initial"
          animate="animate"
          variants={staggerChildren}
          className="text-center bg-gradient-to-r from-green-500 to-green-600 text-white py-20 px-4 rounded-3xl"
        >
          <motion.h2 
            variants={fadeInUp}
            className="text-4xl font-bold mb-8"
          >
            Ready to Excel in Your Exams?
          </motion.h2>
          <motion.p 
            variants={fadeInUp}
            className="text-2xl mb-12 max-w-2xl mx-auto"
          >
            Join thousands of successful candidates who have trusted Examneer for their exam preparation.
          </motion.p>
          <motion.a
            href="/register"
            variants={fadeInUp}
            className="inline-flex items-center px-12 py-4 bg-white text-green-600 rounded-full text-xl font-semibold hover:bg-gray-100 transition-colors duration-300 hover-lift"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Get Started Now
            <ArrowRight className="ml-2 w-6 h-6" />
          </motion.a>
        </motion.section>
      </main>

      <footer className="bg-gray-100 py-12">
        <div className="container mx-auto px-4 text-center">
          <p className="text-gray-600">&copy; 2024 Examneer. All rights reserved.</p>
          <div className="mt-4">
            <a href="/" className="text-green-600 hover:text-green-700 mx-2">Privacy Policy</a>
            <a href="/" className="text-green-600 hover:text-green-700 mx-2">Terms of Service</a>
            <a href="/" className="text-green-600 hover:text-green-700 mx-2">Contact Us</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

