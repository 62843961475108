// import React, { useState, useContext } from 'react';
// import { Link} from 'react-router-dom';
// import AuthContext from '../context/AuthContext';
// import reg from './images/reg.png';

// function Registerpage() {
//   const [email, setEmail] = useState('');
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [password2, setPassword2] = useState('');
//   const [course, setCourse] = useState('civil'); // Default to civil engineering
//   const [paymentDetails, setPaymentDetails] = useState(null);
//   const [phoneNumber, setPhoneNumber] = useState('');
  
//   const { registerUser } = useContext(AuthContext);

  
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     registerUser(email, username, password, password2, course, paymentDetails, phoneNumber);
//   };

//   const handlePaymentDetailsChange = (e) => {
//     setPaymentDetails(e.target.files[0]); // Handle image upload
//   };

//   return (
//     <div>
//       <section className="vh-100" style={{ backgroundColor: '#147d04' }}>
//         <div className="container py-5 h-100">
//           <div className="row d-flex justify-content-center align-items-center h-100">
//             <div className="col col-xl-10">
//               <div className="card" style={{ borderRadius: '1rem' }}>
//                 <div className="row g-0">
//                   <div className="col-md-6 col-lg-5 d-none d-md-block">
//                     <img
//                       src={reg}
//                       alt="registration form"
//                       className="img-fluid"
//                       style={{
//                         width: '100%',
//                         height: 'auto',
//                         borderRadius: '1rem 0 0 1rem',
//                       }}
//                     />
//                   </div>
//                   <div className="col-md-6 col-lg-7 d-flex align-items-center">
//                     <div className="card-body p-4 p-lg-5 text-black">
//                       <form onSubmit={handleSubmit}>
//                         <div className="d-flex align-items-center mb-3 pb-1">
//                           <i
//                             className="fas fa-cubes fa-2x me-3"
//                             style={{ color: '#ff6219' }}
//                           />
//                           <span className="h2 fw-bold mb-0">Welcome</span>
//                         </div>
//                         <h5
//                           className="fw-normal mb-3 pb-3"
//                           style={{ letterSpacing: 1 }}
//                         >
//                           Sign Up
//                         </h5>

//                         {/* Email */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="email"
//                             id="email"
//                             className="form-control form-control-lg"
//                             placeholder="Email Address"
//                             onChange={(e) => setEmail(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Username */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             id="username"
//                             className="form-control form-control-lg"
//                             placeholder="Your Name"
//                             onChange={(e) => setUsername(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Password */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="password"
//                             className="form-control form-control-lg"
//                             placeholder="Password"
//                             onChange={(e) => setPassword(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Confirm Password */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="password2"
//                             className="form-control form-control-lg"
//                             placeholder="Confirm Password"
//                             onChange={(e) => setPassword2(e.target.value)}
//                             required
//                           />
//                         </div>

//                         {/* Course Dropdown */}
//                         <div className="form-outline mb-4">
//                           <select
//                             id="course"
//                             className="form-control form-control-lg"
//                             onChange={(e) => setCourse(e.target.value)}
//                             required
//                           >
//                             <option value="civil">Civil NEC license</option>
//                             <option value="geomatics">Geomatics license</option>
//                           </select>
//                         </div>

//                         {/* Phone Number */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             id="phone_number"
//                             className="form-control form-control-lg"
//                             placeholder="Phone Number"
//                             maxLength="15"
//                             onChange={(e) => setPhoneNumber(e.target.value)}
//                           />
//                         </div>

//                         {/* Payment Details (Image Upload) */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="file"
//                             id="payment_details"
//                             className="form-control form-control-lg"
//                             accept="image/*"
//                             onChange={handlePaymentDetailsChange}
//                           />
//                         </div>

//                         {/* Submit Button */}
//                         <div className="pt-1 mb-4">
//                           <button
//                             className="btn btn-dark btn-lg btn-block"
//                             type="submit"
//                           >
//                             Register
//                           </button>
//                         </div>

//                         <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>
//                           Already have an account?{' '}
//                           <Link to="/login" style={{ color: '#393f81' }}>
//                             Login Now
//                           </Link>
//                         </p>

//                         <a href="#!" className="small text-muted">
//                           Terms of use
//                         </a>
//                         <a href="#!" className="small text-muted">
//                           Privacy policy
//                         </a>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <footer className="bg-light text-center text-lg-start">
//         <div
//           className="text-center p-3"
//           style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
//         >
//           © Geoneer Geospatial Services: <a className="text-dark" href="https://geoneer.com.np/">Geoneer</a>
//         </div>
//       </footer>
//     </div>
//   );
// }

// export default Registerpage;




//this is commented out code

// import React, { useState, useContext, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import AuthContext from '../context/AuthContext';
// import reg from './images/reg.png';
// import QRPopup from './QRPopup'; 

// function Registerpage() {
//   const [email, setEmail] = useState('');
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [password2, setPassword2] = useState('');
//   const [course, setCourse] = useState('civil'); // Default to civil engineering
//   const [paymentDetails, setPaymentDetails] = useState(null);
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
//   const [showPopup, setShowPopup] = useState(false); // State to control the QR Popup


//   const { registerUser } = useContext(AuthContext);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     registerUser(email, username, password, password2, course, paymentDetails, phoneNumber);
//     setHasUnsavedChanges(false); // Reset unsaved changes after submission
//   };

//   const handlePaymentDetailsChange = (e) => {
//     setPaymentDetails(e.target.files[0]); // Handle image upload
//     setHasUnsavedChanges(true);
//   };

  
//   const handlePopupClose = () => {
//     setShowPopup(false); // Close popup
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       if (hasUnsavedChanges) {
//         const message = "You have unsaved changes. Are you sure you want to leave?";
//         event.returnValue = message; // Most browsers display a generic message
//         return message;
//       }
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [hasUnsavedChanges]);

//   return (
//     <div>
//       <section className="vh-100" style={{ backgroundColor: '#147d04' }}>
//         <div className="container py-5 h-100">
//           <div className="row d-flex justify-content-center align-items-center h-100">
//             <div className="col col-xl-10">
//               <div className="card" style={{ borderRadius: '1rem' }}>
//                 <div className="row g-0">
//                   <div className="col-md-6 col-lg-5 d-none d-md-block">
//                     <img
//                       src={reg}
//                       alt="registration form"
//                       className="img-fluid"
//                       style={{
//                         width: '100%',
//                         height: 'auto',
//                         borderRadius: '1rem 0 0 1rem',
//                       }}
//                     />
//                   </div>
//                   <div className="col-md-6 col-lg-7 d-flex align-items-center">
//                     <div className="card-body p-4 p-lg-5 text-black">
//                       <form onSubmit={handleSubmit}>
//                         <div className="d-flex align-items-center mb-3 pb-1">
//                           <i
//                             className="fas fa-cubes fa-2x me-3"
//                             style={{ color: '#ff6219' }}
//                           />
//                           <span className="h2 fw-bold mb-0">Welcome</span>
//                         </div>
//                         <h5
//                           className="fw-normal mb-3 pb-3"
//                           style={{ letterSpacing: 1 }}
//                         >
//                           Sign Up
//                         </h5>

//                         {/* Email */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="email"
//                             id="email"
//                             className="form-control form-control-lg"
//                             placeholder="Email Address"
//                             onChange={(e) => {
//                               setEmail(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                             required
//                           />
//                         </div>

//                         {/* Username */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             id="username"
//                             className="form-control form-control-lg"
//                             placeholder="Your Name"
//                             onChange={(e) => {
//                               setUsername(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                             required
//                           />
//                         </div>

//                         {/* Password */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="password"
//                             className="form-control form-control-lg"
//                             placeholder="Password at least 8 character"
//                             onChange={(e) => {
//                               setPassword(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                             required
//                           />
//                           <p>at least 8 character</p>
//                         </div>

//                         {/* Confirm Password */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             id="password2"
//                             className="form-control form-control-lg"
//                             placeholder="Confirm Password"
//                             onChange={(e) => {
//                               setPassword2(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                             required
//                           />
//                         </div>

//                         {/* Course Dropdown */}
//                         <div className="form-outline mb-4">
//                           <select
//                             id="course"
//                             className="form-control form-control-lg"
//                             onChange={(e) => {
//                               setCourse(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                             required
//                           >
//                             <option value="civil">Civil NEC license</option>
//                             <option value="geomatics">Geomatics license</option>
//                           </select>
//                         </div>

//                         {/* Phone Number */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             id="phone_number"
//                             className="form-control form-control-lg"
//                             placeholder="Phone Number"
//                             maxLength="15"
//                             onChange={(e) => {
//                               setPhoneNumber(e.target.value);
//                               setHasUnsavedChanges(true);
//                             }}
//                           />
//                         </div>
//                                               {/* Payment Popup Button */}
//                         <div className="form-outline mb-4">
//                           <button
//                             type="button"
//                             className="btn btn-success btn-lg btn-block"
//                             onClick={() => setShowPopup(true)}
//                           >
//                             Click here for payment
//                           </button>
//                           {showPopup && <QRPopup onClose={handlePopupClose} />}
//                         </div>

//                         {/* Payment Details (Image Upload) */}
//                         <div className="form-outline mb-4">
//                           <input
//                             type="file"
//                             id="payment_details"
//                             className="form-control form-control-lg"
//                             accept="image/*"
//                             onChange={handlePaymentDetailsChange}
//                           />
//                           <p>Attach the screen shot of the payment detail here</p>
//                         </div>

//                         {/* Submit Button */}
//                         <div className="pt-1 mb-4">
//                           <button
//                             className="btn btn-dark btn-lg btn-block"
//                             type="submit"
//                           >
//                             Register
//                           </button>
//                         </div>

//                         <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>
//                           Already have an account?{' '}
//                           <Link to="/login" style={{ color: '#393f81' }}>
//                             Login Now
//                           </Link>
//                         </p>

//                         <a href="#!" className="small text-muted">
//                           Terms of use
//                         </a>
//                         <a href="#!" className="small text-muted">
//                           Privacy policy
//                         </a>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <footer className="bg-light text-center text-lg-start">
//         <div
//           className="text-center p-3"
//           style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
//         >
//           © Geoneer Geospatial Services: <a className="text-dark" href="https://geoneer.com.np/">Geoneer</a>
//         </div>
//       </footer>
//     </div>
//   );
// }

// export default Registerpage;




//This is the test code


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import './rp.css'


// const RegisterPage = () => {
//   const [formData, setFormData] = useState({
//     email: "",
//     first_name: "",
//     last_name: "",
//     password: "",
//     password_confirmation: "",
//     course: "",
//     validity: "",
//     payment_detail: null, // File input
//   });

//   const [courses, setCourses] = useState([]);
//   const [errors, setErrors] = useState({});
//   const [successMessage, setSuccessMessage] = useState("");

//   // Fetch available courses on component mount
//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get("https://nec.geoneer.com.np/api/accounts/courses/");
//         setCourses(response.data);
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };
//     fetchCourses();
//   }, []);

//   // Handle form input changes
//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     if (name === "payment_detail") {
//       setFormData({ ...formData, [name]: files[0] });
//     } else {
//       setFormData({ ...formData, [name]: value });
//     }
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formDataToSend = new FormData();
//     for (const key in formData) {
//       formDataToSend.append(key, formData[key]);
//     }

//     try {
//       const response = await axios.post("https://nec.geoneer.com.np/api/accounts/register/", formDataToSend, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       setSuccessMessage(response.data.message);
//       setErrors({});
//       setFormData({
//         email: "",
//         first_name: "",
//         last_name: "",
//         password: "",
//         password_confirmation: "",
//         course: "",
//         validity: "",
//         payment_detail: null,
//       });
//     } catch (error) {
//       if (error.response && error.response.data) {
//         setErrors(error.response.data);
//       }
//     }
//   };

//   return (
//     <div className="register-container">
//       <h2>Register</h2>
//       {successMessage && <p className="success-message">{successMessage}</p>}
//       <form onSubmit={handleSubmit} encType="multipart/form-data">
//         <div>
//           <label>Email</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//           />
//           {errors.email && <p className="error">{errors.email[0]}</p>}
//         </div>

//         <div>
//           <label>First Name</label>
//           <input
//             type="text"
//             name="first_name"
//             value={formData.first_name}
//             onChange={handleChange}
//           />
//         </div>

//         <div>
//           <label>Last Name</label>
//           <input
//             type="text"
//             name="last_name"
//             value={formData.last_name}
//             onChange={handleChange}
//           />
//         </div>

//         <div>
//           <label>Password</label>
//           <input
//             type="password"
//             name="password"
//             value={formData.password}
//             onChange={handleChange}
//           />
//           {errors.password && <p className="error">{errors.password[0]}</p>}
//         </div>

//         <div>
//           <label>Confirm Password</label>
//           <input
//             type="password"
//             name="password_confirmation"
//             value={formData.password_confirmation}
//             onChange={handleChange}
//           />
//           {errors.password_confirmation && (
//             <p className="error">{errors.password_confirmation[0]}</p>
//           )}
//         </div>

//         <div>
//           <label>Course</label>
//           <select name="course" value={formData.course} onChange={handleChange}>
//             <option value="">Select a course</option>
//             {courses.map((course) => (
//               <option key={course.id} value={course.id}>
//                 {course.courseName}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div>
//           <label>Validity</label>
//           <input
//             type="date"
//             name="validity"
//             value={formData.validity}
//             onChange={handleChange}
//           />
//         </div>

//         <div>
//           <label>Payment Detail</label>
//           <input
//             type="file"
//             name="payment_detail"
//             accept="image/*"
//             onChange={handleChange}
//           />
//         </div>

//         <button type="submit">Register</button>
//       </form>
//     </div>
//   );
// };

// export default RegisterPage;

// import React, { useState, useEffect, useContext } from "react";
// import AuthContext from "../context/AuthContext";
// import axios from "axios";

// const RegisterPage = () => {
//   const { registerUser } = useContext(AuthContext);

//   const [formData, setFormData] = useState({
//     email: "",
//     first_name: "",
//     last_name: "",
//     password: "",
//     password_confirmation: "",
//     course: "",
//     validity: "",
//     payment_detail: null, // File input
//   });

//   const [courses, setCourses] = useState([]);

//   // Fetch available courses on component mount
//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get(
//           "https://nec.geoneer.com.np/api/accounts/courses/"
//         );
//         setCourses(response.data); // Assuming response.data is the array of courses
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };
//     fetchCourses();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({ ...prevState, [name]: value }));
//   };

//   const handleFileChange = (e) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       payment_detail: e.target.files[0],
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Create FormData and append all fields
//     const data = new FormData();
//     data.append("email", formData.email);
//     data.append("first_name", formData.first_name);
//     data.append("last_name", formData.last_name);
//     data.append("password", formData.password);
//     data.append("password_confirmation", formData.password_confirmation);
//     data.append("course", formData.course);
//     data.append("validity", formData.validity);
//     if (formData.payment_detail) {
//       data.append("payment_detail", formData.payment_detail);
//     }

//     try {
//       // Debugging: Log the data for verification
//       for (const [key, value] of data.entries()) {
//         console.log(`${key}: ${value}`);
//       }

//       // Send registration data
//       const response = await axios.post(
//         "https://nec.geoneer.com.np/api/accounts/register/",
//         data,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       console.log("Registration successful:", response.data);
//     } catch (error) {
//       if (error.response) {
//         console.error("Error during registration:", error.response.data);
//       } else {
//         console.error("Error:", error.message);
//       }
//     }
//   };

//   return (
//     <div className="register-page">
//       <form onSubmit={handleSubmit}>
//         <h2>Register</h2>
//         <div>
//           <label>Email:</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>First Name:</label>
//           <input
//             type="text"
//             name="first_name"
//             value={formData.first_name}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Last Name:</label>
//           <input
//             type="text"
//             name="last_name"
//             value={formData.last_name}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Password:</label>
//           <input
//             type="password"
//             name="password"
//             value={formData.password}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Confirm Password:</label>
//           <input
//             type="password"
//             name="password_confirmation"
//             value={formData.password_confirmation}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Course:</label>
//           <select
//             name="course"
//             value={formData.course}
//             onChange={handleChange}
//             required
//           >
//             <option value="">Select a course</option>
//             {courses.map((course) => (
//               <option key={course.id} value={course.id}>
//                 {course.courseName}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div>
//           <label>Validity:</label>
//           <input
//             type="text"
//             name="validity"
//             value={formData.validity}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div>
//           <label>Payment Details:</label>
//           <input
//             type="file"
//             name="payment_detail"
//             onChange={handleFileChange}
//           />
//         </div>
//         <button type="submit">Register</button>
//       </form>
//     </div>
//   );
// };

// export default RegisterPage;



// import React, { useState, useEffect, useContext } from "react";
// import AuthContext from "../context/AuthContext";
// import axios from "axios";
// import reg from "./images/reg.png"; // Adjust the path to your image as needed
// import QRPopup from "./QRPopup"; // Assuming QRPopup is a separate component for displaying a QR code
// import { Link } from 'react-router-dom';

// const RegisterPage = () => {
//   const { registerUser } = useContext(AuthContext);

//   const [formData, setFormData] = useState({
//     email: "",
//     first_name: "",
//     last_name: "",
//     password: "",
//     password_confirmation: "",
//     course: "",
//     phone_number: "",
//     payment_detail: null, // File input
//   });

//   const [courses, setCourses] = useState([]);
//   const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
//   const [showPopup, setShowPopup] = useState(false);



//   // Fetch available courses on component mount
//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get(
//           "https://nec.geoneer.com.np/api/accounts/courses/"
//         );
//         setCourses(response.data);
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };
//     fetchCourses();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({ ...prevState, [name]: value }));
//     setHasUnsavedChanges(true);
//   };

//   const handleFileChange = (e) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       payment_detail: e.target.files[0],
//     }));
//     setHasUnsavedChanges(true);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const data = new FormData();
//     Object.keys(formData).forEach((key) => {
//       if (formData[key]) data.append(key, formData[key]);
//     });

//     registerUser(data); // Call the context function to handle registration
//     setHasUnsavedChanges(false);
//   };

//   const handlePopupClose = () => setShowPopup(false);



  
//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       if (hasUnsavedChanges) {
//         const message = "You have unsaved changes. Are you sure you want to leave?";
//         event.returnValue = message; // For most browsers
//         return message;
//       }
//     };

//     window.addEventListener("beforeunload", handleBeforeUnload);
//     return () => window.removeEventListener("beforeunload", handleBeforeUnload);
//   }, [hasUnsavedChanges]);

//   return (
//     <div>
//       <section className="vh-100" style={{ backgroundColor: "#147d04" }}>
//         <div className="container py-5 h-100">
//           <div className="row d-flex justify-content-center align-items-center h-100">
//             <div className="col col-xl-10">
//               <div className="card" style={{ borderRadius: "1rem" }}>
//                 <div className="row g-0">
//                   <div className="col-md-6 col-lg-5 d-none d-md-block">
//                     <img
//                       src={reg}
//                       alt="registration form"
//                       className="img-fluid"
//                       style={{
//                         width: "100%",
//                         height: "auto",
//                         borderRadius: "1rem 0 0 1rem",
//                       }}
//                     />
//                   </div>
//                   <div className="col-md-6 col-lg-7 d-flex align-items-center">
//                     <div className="card-body p-4 p-lg-5 text-black">
//                       <form onSubmit={handleSubmit}>
//                         <div className="d-flex align-items-center mb-3 pb-1">
//                           <i
//                             className="fas fa-cubes fa-2x me-3"
//                             style={{ color: "#ff6219" }}
//                           />
//                           <span className="h2 fw-bold mb-0">Register</span>
//                         </div>
//                         <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: 1 }}>
//                           Create Your Account
//                         </h5>

//                         <div className="form-outline mb-4">
//                           <input
//                             type="email"
//                             name="email"
//                             className="form-control form-control-lg"
//                             placeholder="Email Address"
//                             value={formData.email}
//                             onChange={handleChange}
//                             required
//                           />
//                         </div>

//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             name="first_name"
//                             className="form-control form-control-lg"
//                             placeholder="First Name"
//                             value={formData.first_name}
//                             onChange={handleChange}
//                             required
//                           />
//                         </div>

//                         <div className="form-outline mb-4">
//                           <input
//                             type="text"
//                             name="last_name"
//                             className="form-control form-control-lg"
//                             placeholder="Last Name"
//                             value={formData.last_name}
//                             onChange={handleChange}
//                             required
//                           />
//                         </div>

//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             name="password"
//                             className="form-control form-control-lg"
//                             placeholder="Password"
//                             value={formData.password}
//                             onChange={handleChange}
//                             required
//                           />
//                         </div>

//                         <div className="form-outline mb-4">
//                           <input
//                             type="password"
//                             name="password_confirmation"
//                             className="form-control form-control-lg"
//                             placeholder="Confirm Password"
//                             value={formData.password_confirmation}
//                             onChange={handleChange}
//                             required
//                           />
//                         </div>

//                         <div className="form-outline mb-4">
//                           <select
//                             name="course"
//                             className="form-control form-control-lg"
//                             value={formData.course}
//                             onChange={handleChange}
//                             required
//                           >
//                             <option value="">Select Course</option>
//                             {courses.map((course) => (
//                               <option key={course.id} value={course.id}>
//                                 {course.courseName}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                                              { /* Phone Number */ }
//                                              <div className="form-outline mb-4">
//                               <input
//                                 type="text"
//                                 name="phone_number" // Updated to 'name' for consistency
//                                 className="form-control form-control-lg"
//                                 placeholder="Phone Number"
//                                 maxLength="15"
//                                 value={formData.phone_number}
//                                 onChange={handleChange}
//                                 required
//                               />
//                             </div>


//                                               {/* Payment Popup Button */}
//                         <div className="form-outline mb-4">
//                           <button
//                             type="button"
//                             className="btn btn-success btn-lg btn-block"
//                             onClick={() => setShowPopup(true)}
//                           >
//                             Click here for payment
//                           </button>
//                           {showPopup && <QRPopup onClose={handlePopupClose} />}
//                         </div>

                   
                       

//                         <div className="form-outline mb-4">
//                           <input
//                             type="file"
//                             name="payment_detail"
//                             className="form-control form-control-lg"
//                             onChange={handleFileChange}
//                           />
//                        <p>Attach the screen shot of the payment detail here</p>

//                         </div>

//                         <button className="btn btn-dark btn-lg btn-block" type="submit">
//                           Register
//                         </button>
//                         <p className="mt-2">
//                            Already have an account? <Link to="/login">Login</Link>
//                         </p>
//                       </form>

//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default RegisterPage;


// import React, { useState, useEffect, useContext } from "react";
// import { motion } from "framer-motion";
// import AuthContext from "../context/AuthContext";
// import axios from "axios";
// import reg from "./images/reg.png";
// import QRPopup from "./QRPopup";
// import { Link } from 'react-router-dom';
// import { ArrowRight, Mail, User, Lock, Phone, FileText, Brain } from 'lucide-react';

// const RegisterPage = () => {
//   const { registerUser } = useContext(AuthContext);
//   const [formData, setFormData] = useState({
//     email: "",
//     first_name: "",
//     last_name: "",
//     password: "",
//     password_confirmation: "",
//     course: "",
//     phone_number: "",
//     payment_detail: null,
//   });

//   const [courses, setCourses] = useState([]);
//   const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
//   const [showPopup, setShowPopup] = useState(false);

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get(
//           "https://nec.geoneer.com.np/api/accounts/courses/"
//         );
//         setCourses(response.data);
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };
//     fetchCourses();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({ ...prevState, [name]: value }));
//     setHasUnsavedChanges(true);
//   };

//   const handleFileChange = (e) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       payment_detail: e.target.files[0],
//     }));
//     setHasUnsavedChanges(true);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const data = new FormData();
//     Object.keys(formData).forEach((key) => {
//       if (formData[key]) data.append(key, formData[key]);
//     });
//     registerUser(data);
//     setHasUnsavedChanges(false);
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       if (hasUnsavedChanges) {
//         const message = "You have unsaved changes. Are you sure you want to leave?";
//         event.returnValue = message;
//         return message;
//       }
//     };
//     window.addEventListener("beforeunload", handleBeforeUnload);
//     return () => window.removeEventListener("beforeunload", handleBeforeUnload);
//   }, [hasUnsavedChanges]);

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-green-400 via-green-500 to-green-600">
//       <div className="container mx-auto px-4 py-8">
//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5 }}
//           className="max-w-6xl mx-auto"
//         >
//           <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
//             <div className="grid md:grid-cols-2 gap-0">
//               {/* Left Side - Image */}
//               <div className="relative hidden md:block">
//                 <img
//                   src={reg}
//                   alt="registration visual"
//                   className="absolute inset-0 h-full w-full object-cover"
//                 />
//                 <div className="absolute inset-0 bg-gradient-to-br from-green-600/80 to-green-800/80"></div>
//                 <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-8">
//                   <Brain className="w-20 h-20 mb-6" />
//                   <h2 className="text-4xl font-bold text-center mb-4">
//                     Welcome to Examneer
//                   </h2>
//                   <p className="text-lg text-center text-green-100">
//                     Join our community of engineers and start your journey today
//                   </p>
//                 </div>
//               </div>

//               {/* Right Side - Form */}
//               <div className="p-8">
//                 <div className="max-w-md mx-auto">
//                   <h2 className="text-3xl font-bold text-gray-900 mb-8">
//                     Create Your Account
//                   </h2>

//                   <form onSubmit={handleSubmit} className="space-y-6">
//                     {/* Email Input */}
//                     <div className="relative group">
//                       <Mail className="absolute top-3 left-3 text-gray-400 group-focus-within:text-green-500 transition-colors" size={20} />
//                       <input
//                         type="email"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-lg focus:border-green-500 focus:ring-0 transition-colors"
//                         placeholder="Email Address"
//                         required
//                       />
//                     </div>

//                     {/* Name Fields */}
//                     <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
//                       <div className="relative group">
//                         <User className="absolute top-3 left-3 text-gray-400 group-focus-within:text-green-500 transition-colors" size={20} />
//                         <input
//                           type="text"
//                           name="first_name"
//                           value={formData.first_name}
//                           onChange={handleChange}
//                           className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-lg focus:border-green-500 focus:ring-0 transition-colors"
//                           placeholder="First Name"
//                           required
//                         />
//                       </div>
//                       <div className="relative group">
//                         <User className="absolute top-3 left-3 text-gray-400 group-focus-within:text-green-500 transition-colors" size={20} />
//                         <input
//                           type="text"
//                           name="last_name"
//                           value={formData.last_name}
//                           onChange={handleChange}
//                           className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-lg focus:border-green-500 focus:ring-0 transition-colors"
//                           placeholder="Last Name"
//                           required
//                         />
//                       </div>
//                     </div>

//                     {/* Password Fields */}
//                     <div className="space-y-4">
//                       <div className="relative group">
//                         <Lock className="absolute top-3 left-3 text-gray-400 group-focus-within:text-green-500 transition-colors" size={20} />
//                         <input
//                           type="password"
//                           name="password"
//                           value={formData.password}
//                           onChange={handleChange}
//                           className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-lg focus:border-green-500 focus:ring-0 transition-colors"
//                           placeholder="Password"
//                           required
//                         />
//                       </div>
//                       <div className="relative group">
//                         <Lock className="absolute top-3 left-3 text-gray-400 group-focus-within:text-green-500 transition-colors" size={20} />
//                         <input
//                           type="password"
//                           name="password_confirmation"
//                           value={formData.password_confirmation}
//                           onChange={handleChange}
//                           className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-lg focus:border-green-500 focus:ring-0 transition-colors"
//                           placeholder="Confirm Password"
//                           required
//                         />
//                       </div>
//                     </div>

//                     {/* Course Selection */}
//                     <div className="relative">
//                       <select
//                         name="course"
//                         value={formData.course}
//                         onChange={handleChange}
//                         className="w-full px-4 py-3 border-2 border-gray-200 rounded-lg focus:border-green-500 focus:ring-0 transition-colors appearance-none bg-white"
//                         required
//                       >
//                         <option value="">Select Course</option>
//                         {courses.map((course) => (
//                           <option key={course.id} value={course.id}>
//                             {course.courseName}
//                           </option>
//                         ))}
//                       </select>
//                       <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
//                         <ArrowRight className="h-4 w-4 text-gray-400" />
//                       </div>
//                     </div>

//                     {/* Phone Number */}
//                     <div className="relative group">
//                       <Phone className="absolute top-3 left-3 text-gray-400 group-focus-within:text-green-500 transition-colors" size={20} />
//                       <input
//                         type="text"
//                         name="phone_number"
//                         value={formData.phone_number}
//                         onChange={handleChange}
//                         className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-lg focus:border-green-500 focus:ring-0 transition-colors"
//                         placeholder="98XXXXXXXX"
//                         maxLength="10"
//                         required
//                       />
//                     </div>

//                     {/* Payment Section */}
//                     <div className="space-y-4">
//                       <button
//                         type="button"
//                         onClick={() => setShowPopup(true)}
//                         className="w-full py-3 px-4 bg-green-100 text-green-700 rounded-lg hover:bg-green-200 transition-colors flex items-center justify-center gap-2"
//                       >
//                         <FileText size={20} />
//                         View Payment QR Code
//                       </button>

//                       <div className="relative group">
//                         <FileText className="absolute top-3 left-3 text-gray-400 group-focus-within:text-green-500 transition-colors" size={20} />
//                         <input
//                           type="file"
//                           name="payment_detail"
//                           onChange={handleFileChange}
//                           className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-lg focus:border-green-500 focus:ring-0 transition-colors file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-green-50 file:text-green-700 hover:file:bg-green-100"
//                         />
//                         <p className="mt-2 text-sm text-gray-500">
//                           Upload payment screenshot
//                         </p>
//                       </div>
//                     </div>

//                     {/* Submit Button */}
//                     <button
//                       type="submit"
//                       className="w-full py-3 px-4 bg-gradient-to-r from-green-600 to-green-700 text-white rounded-lg hover:from-green-700 hover:to-green-800 transition-all transform hover:scale-[1.02] duration-200 flex items-center justify-center gap-2"
//                     >
//                       Create Account
//                       <ArrowRight size={20} />
//                     </button>

//                     {/* Login Link */}
//                     <p className="text-center text-gray-600">
//                       Already have an account?{" "}
//                       <Link
//                         to="/login"
//                         className="text-green-600 hover:text-green-700 font-medium"
//                       >
//                         Sign in
//                       </Link>
//                     </p>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </motion.div>
//       </div>

//       {/* QR Popup */}
//       {showPopup && (
//         <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
//           <div className="bg-white rounded-lg p-6 max-w-md w-full">
//             <div className="flex justify-between items-center mb-4">
//               <h3 className="text-lg font-semibold">Payment QR Code</h3>
//               <button
//                 onClick={() => setShowPopup(false)}
//                 className="text-gray-500 hover:text-gray-700"
//               >
//                 ✕
//               </button>
//             </div>
//             <QRPopup onClose={() => setShowPopup(false)} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default RegisterPage;


// import React, { useState, useEffect, useContext } from "react";
// import { motion } from "framer-motion";
// import AuthContext from "../context/AuthContext";
// import axios from "axios";
// // import reg from "./images/reg.png";
// import QRPopup from "./QRPopup";
// import { Link } from 'react-router-dom';
// import { ArrowRight, Mail, User, Lock, Phone, FileText, Brain, CheckCircle } from 'lucide-react';

// const RegisterPage = () => {
//   // ... [Previous state and handlers remain unchanged]
//     const { registerUser } = useContext(AuthContext);
//   const [formData, setFormData] = useState({
//     email: "",
//     first_name: "",
//     last_name: "",
//     password: "",
//     password_confirmation: "",
//     course: "",
//     phone_number: "",
//     payment_detail: null,
//   });

//   const [courses, setCourses] = useState([]);
//   const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
//   const [showPopup, setShowPopup] = useState(false);

//   useEffect(() => {
//     const fetchCourses = async () => {
//       try {
//         const response = await axios.get(
//           "https://nec.geoneer.com.np/api/accounts/courses/"
//         );
//         setCourses(response.data);
//       } catch (error) {
//         console.error("Error fetching courses:", error);
//       }
//     };
//     fetchCourses();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevState) => ({ ...prevState, [name]: value }));
//     setHasUnsavedChanges(true);
//   };

//   const handleFileChange = (e) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       payment_detail: e.target.files[0],
//     }));
//     setHasUnsavedChanges(true);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const data = new FormData();
//     Object.keys(formData).forEach((key) => {
//       if (formData[key]) data.append(key, formData[key]);
//     });
//     registerUser(data);
//     setHasUnsavedChanges(false);
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//       if (hasUnsavedChanges) {
//         const message = "You have unsaved changes. Are you sure you want to leave?";
//         event.returnValue = message;
//         return message;
//       }
//     };
//     window.addEventListener("beforeunload", handleBeforeUnload);
//     return () => window.removeEventListener("beforeunload", handleBeforeUnload);
//   }, [hasUnsavedChanges]);

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-green-400 via-green-500 to-green-600 py-8 px-4 sm:px-6 lg:px-8">
//       <div className="container mx-auto">
//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5 }}
//           className="max-w-7xl mx-auto"
//         >
//           <div className="bg-white/95 backdrop-blur-sm rounded-3xl shadow-2xl overflow-hidden">
//             <div className="lg:grid lg:grid-cols-2 lg:gap-6">
//               {/* Left Side - Image and Welcome Message */}
//               <div className="hidden lg:block relative">
//                 <div className="absolute inset-0">
//                   {/* <img
//                     src={reg}
//                     alt="registration visual"
//                     className="h-full w-full object-cover"
//                   /> */}
//                   <div className="absolute inset-0 bg-gradient-to-br from-green-600/90 to-green-900/90 backdrop-blur-sm"></div>
//                 </div>
//                 <div className="relative h-full flex flex-col items-center justify-center px-8 py-12 text-center">
//                   <motion.div
//                     initial={{ scale: 0 }}
//                     animate={{ scale: 1 }}
//                     transition={{ delay: 0.2, type: "spring", stiffness: 260, damping: 20 }}
//                   >
//                     <Brain className="w-24 h-24 text-white/90 mb-8" />
//                   </motion.div>
//                   <motion.h2
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ delay: 0.3 }}
//                     className="text-4xl font-bold text-white mb-6"
//                   >
//                     Welcome to Examneer
//                   </motion.h2>
//                   <motion.p
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ delay: 0.4 }}
//                     className="text-lg text-green-50 max-w-md"
//                   >
//                     Join our community of engineers and start your journey towards excellence today
//                   </motion.p>
//                   <motion.div
//                     initial={{ opacity: 0 }}
//                     animate={{ opacity: 1 }}
//                     transition={{ delay: 0.5 }}
//                     className="mt-12 space-y-4 text-left text-green-50"
//                   >
//                     <Feature text="Access to comprehensive study materials" />
//                     <Feature text="Practice with real exam questions" />
//                     <Feature text="Track your progress" />
//                     <Feature text="Join a community of learners" />
//                   </motion.div>
//                 </div>
//               </div>

//               {/* Right Side - Form */}
//               <div className="px-6 py-12 lg:px-12 lg:py-16">
//                 <div className="max-w-md mx-auto">
//                   <motion.div
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.5 }}
//                   >
//                     <h2 className="text-3xl font-bold text-gray-900 mb-2">Create Your Account</h2>
//                     <p className="text-gray-600 mb-8">Start your preparation journey today</p>

//                     <form onSubmit={handleSubmit} className="space-y-6">
//                       {/* Email Input */}
//                       <div className="form-group">
//                         <label className="text-sm font-medium text-gray-700 mb-1 block">
//                           Email Address
//                         </label>
//                         <div className="relative">
//                           <Mail className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
//                           <input
//                             type="email"
//                             name="email"
//                             value={formData.email}
//                             onChange={handleChange}
//                             className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
//                             placeholder="your@email.com"
//                             required
//                           />
//                         </div>
//                       </div>

//                       {/* Name Fields */}
//                       <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
//                         <div className="form-group">
//                           <label className="text-sm font-medium text-gray-700 mb-1 block">
//                             First Name
//                           </label>
//                           <div className="relative">
//                             <User className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
//                             <input
//                               type="text"
//                               name="first_name"
//                               value={formData.first_name}
//                               onChange={handleChange}
//                               className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
//                               placeholder="First Name"
//                               required
//                             />
//                           </div>
//                         </div>
//                         <div className="form-group">
//                           <label className="text-sm font-medium text-gray-700 mb-1 block">
//                             Last Name
//                           </label>
//                           <div className="relative">
//                             <User className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
//                             <input
//                               type="text"
//                               name="last_name"
//                               value={formData.last_name}
//                               onChange={handleChange}
//                               className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
//                               placeholder="Last Name"
//                               required
//                             />
//                           </div>
//                         </div>
//                       </div>

//                       {/* Password Fields */}
//                       <div className="space-y-4">
//                         <div className="form-group">
//                           <label className="text-sm font-medium text-gray-700 mb-1 block">
//                             Password
//                           </label>
//                           <div className="relative">
//                             <Lock className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
//                             <input
//                               type="password"
//                               name="password"
//                               value={formData.password}
//                               onChange={handleChange}
//                               className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
//                               placeholder="••••••••"
//                               required
//                             />
//                           </div>
//                         </div>
//                         <div className="form-group">
//                           <label className="text-sm font-medium text-gray-700 mb-1 block">
//                             Confirm Password
//                           </label>
//                           <div className="relative">
//                             <Lock className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
//                             <input
//                               type="password"
//                               name="password_confirmation"
//                               value={formData.password_confirmation}
//                               onChange={handleChange}
//                               className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
//                               placeholder="••••••••"
//                               required
//                             />
//                           </div>
//                         </div>
//                       </div>

//                       {/* Course Selection */}
//                       <div className="form-group">
//                         <label className="text-sm font-medium text-gray-700 mb-1 block">
//                           Select Course
//                         </label>
//                         <div className="relative">
//                           <select
//                             name="course"
//                             value={formData.course}
//                             onChange={handleChange}
//                             className="w-full pl-4 pr-10 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200 appearance-none bg-white"
//                             required
//                           >
//                             <option value="">Choose your course</option>
//                             {courses.map((course) => (
//                               <option key={course.id} value={course.id}>
//                                 {course.courseName}
//                               </option>
//                             ))}
//                           </select>
//                           <ArrowRight className="absolute top-3 right-3 text-gray-400" size={20} />
//                         </div>
//                       </div>

//                       {/* Phone Number */}
//                       <div className="form-group">
//                         <label className="text-sm font-medium text-gray-700 mb-1 block">
//                           Phone Number
//                         </label>
//                         <div className="relative">
//                           <Phone className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
//                           <input
//                             type="text"
//                             name="phone_number"
//                             value={formData.phone_number}
//                             onChange={handleChange}
//                             className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
//                             placeholder="98XXXXXXXX"
//                             maxLength="10"
//                             required
//                           />
//                         </div>
//                       </div>

//                       {/* Payment Section */}
//                       <div className="space-y-4">
//                         <motion.button
//                           type="button"
//                           onClick={() => setShowPopup(true)}
//                           className="w-full py-3 px-4 bg-green-50 hover:bg-green-100 text-green-700 rounded-xl transition-all duration-200 flex items-center justify-center gap-2 group"
//                           whileHover={{ scale: 1.01 }}
//                           whileTap={{ scale: 0.99 }}
//                         >
//                           <FileText size={20} className="group-hover:rotate-6 transition-transform duration-200" />
//                           View Payment QR Code
//                         </motion.button>

//                         <div className="form-group">
//                           <label className="text-sm font-medium text-gray-700 mb-1 block">
//                             Payment Screenshot
//                           </label>
//                           <div className="relative">
//                             <input
//                               type="file"
//                               name="payment_detail"
//                               onChange={handleFileChange}
//                               className="w-full pl-4 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200
//                                 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 
//                                 file:text-sm file:font-semibold file:bg-green-50 file:text-green-700 
//                                 hover:file:bg-green-100 file:cursor-pointer"
//                             />
//                           </div>
//                           <p className="mt-2 text-sm text-gray-500">
//                             Upload the screenshot of your payment
//                           </p>
//                         </div>
//                       </div>

//                       {/* Submit Button */}
//                       <motion.button
//                         type="submit"
//                         className="w-full py-4 px-6 bg-gradient-to-r from-green-600 to-green-700 text-white rounded-xl hover:from-green-700 hover:to-green-800 transition-all duration-200 flex items-center justify-center gap-2 group shadow-lg shadow-green-500/25"
//                         whileHover={{ scale: 1.01 }}
//                         whileTap={{ scale: 0.99 }}
//                       >
//                         Create Account
//                         <ArrowRight size={20} className="group-hover:translate-x-1 transition-transform duration-200" />
//                       </motion.button>

//                       {/* Login Link */}
//                       <p className="text-center text-gray-600 mt-6">
//                         Already have an account?{" "}
//                         <Link
//                           to="/login"
//                           className="text-green-600 hover:text-green-700 font-medium transition-colors duration-200"
//                         >
//                           Sign in
//                         </Link>
//                       </p>
//                     </form>
//                   </motion.div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </motion.div>
//       </div>

//       {/* QR Popup */}
//       {showPopup && (
//         <motion.div
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           exit={{ opacity: 0 }}
//           className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50"
//         >
//           <motion.div
//             initial={{ scale: 0.9, opacity: 0 }}
//             animate={{ scale: 1, opacity: 1 }}
//             exit={{ scale: 0.9, opacity: 0 }}
//             className="bg-white rounded-2xl p-6 max-w-md w-full shadow-2xl"
//           >
//             <div className="flex justify-between items-center mb-4">
//               <h3 className="text-xl font-semibold text-gray-900">Payment QR Code</h3>
//               <button
//                 onClick={() => setShowPopup(false)}
//                 className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
//               >
//                 <span className="sr-only">Close</span>
//                 <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
//                 </svg>
//               </button>
//             </div>
//             <QRPopup onClose={() => setShowPopup(false)} />
//           </motion.div>
//         </motion.div>
//       )}
//     </div>
//   );
// };

// // Feature component for the welcome section
// const Feature = ({ text }) => (
//   <div className="flex items-center space-x-3">
//     <CheckCircle className="w-5 h-5 text-green-300" />
//     <span>{text}</span>
//   </div>
// );

// export default RegisterPage;


import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import reg from "./images/reg.png";
import QRPopup from "./QRPopup";
import { Link } from 'react-router-dom';
import { ArrowRight, Mail, User, Lock, Phone, FileText, Brain, CheckCircle } from 'lucide-react';

const RegisterPage = () => {
  const { registerUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
    course: "",
    phone_number: "",
    payment_detail: null,
  });

  const [courses, setCourses] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          "https://nec.geoneer.com.np/api/accounts/courses/"
        );
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    setHasUnsavedChanges(true);
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      payment_detail: e.target.files[0],
    }));
    setHasUnsavedChanges(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key]) data.append(key, formData[key]);
    });
    registerUser(data);
    setHasUnsavedChanges(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-400 via-green-500 to-green-600 py-8 px-4 sm:px-6 lg:px-8">
      <div className="container mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-7xl mx-auto"
        >
          <div className="bg-white/95 backdrop-blur-sm rounded-3xl shadow-2xl overflow-hidden">
            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
              {/* Left Side - Image and Welcome Message */}
              <div className="hidden lg:block relative">
                <div className="absolute inset-0">
                  <img
                    src={reg}
                    alt="registration visual"
                    className="h-full w-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-br from-green-600/90 to-green-900/90 backdrop-blur-sm"></div>
                </div>
                <div className="relative h-full flex flex-col items-center justify-center px-8 py-12 text-center">
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ delay: 0.2, type: "spring", stiffness: 260, damping: 20 }}
                  >
                    <Brain className="w-24 h-24 text-white/90 mb-8" />
                  </motion.div>
                  <motion.h2
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                    className="text-4xl font-bold text-white mb-6"
                  >
                    Welcome to Examneer
                  </motion.h2>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                    className="text-lg text-green-50 max-w-md"
                  >
                    Join our community of engineers and start your journey towards excellence today
                  </motion.p>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                    className="mt-12 space-y-4 text-left text-green-50"
                  >
                    <Feature text="Access to comprehensive study materials" />
                    <Feature text="Practice with real exam questions" />
                    <Feature text="Track your progress" />
                    <Feature text="Join a community of learners" />
                  </motion.div>
                </div>
              </div>

              {/* Right Side - Form */}
              <div className="px-6 py-12 lg:px-12 lg:py-16">
                <div className="max-w-md mx-auto">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h2 className="text-3xl font-bold text-gray-900 mb-2">Create Your Account</h2>
                    <p className="text-gray-600 mb-8">Start your preparation journey today</p>

                    <form onSubmit={handleSubmit} className="space-y-6">
                      {/* Email Input */}
                      <div className="form-group">
                        <label className="text-sm font-medium text-gray-700 mb-1 block">
                          Email Address
                        </label>
                        <div className="relative">
                          <Mail className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
                            placeholder="your@email.com"
                            required
                          />
                        </div>
                      </div>

                      {/* Name Fields */}
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div className="form-group">
                          <label className="text-sm font-medium text-gray-700 mb-1 block">
                            First Name
                          </label>
                          <div className="relative">
                            <User className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
                            <input
                              type="text"
                              name="first_name"
                              value={formData.first_name}
                              onChange={handleChange}
                              className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
                              placeholder="First Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="text-sm font-medium text-gray-700 mb-1 block">
                            Last Name
                          </label>
                          <div className="relative">
                            <User className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
                            <input
                              type="text"
                              name="last_name"
                              value={formData.last_name}
                              onChange={handleChange}
                              className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
                              placeholder="Last Name"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      {/* Password Fields */}
                      <div className="space-y-4">
                        <div className="form-group">
                          {/* <label className="text-sm font-medium text-gray-700 mb-1 block">
                            Password
                          </label>
                          <div className="relative">
                            <Lock className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
                            <input
                              type="password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
                              placeholder="••••••••"
                              required
                            />
                          </div> */}
                          <label className="text-sm font-medium text-gray-700 mb-1 block">
  Password
</label>
<div className="relative">
  <Lock className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
  <input
    type="password"
    name="password"
    value={formData.password}
    onChange={handleChange}
    className={`w-full pl-10 pr-4 py-3 border-2 rounded-xl transition-all duration-200 ${
      formData.password.length < 8 && formData.password !== '' 
        ? 'border-red-500 focus:border-red-500 focus:ring-red-200' 
        : 'border-gray-200 focus:border-green-500 focus:ring-green-200'
    }`}
    placeholder="••••••••"
    required
  />
  {formData.password.length > 0 && formData.password.length < 8 && (
    <p className="text-red-500 text-xs mt-1">Password must be at least 8 characters long.</p>
  )}
</div>

                        </div>
                        <div className="form-group">
                          <label className="text-sm font-medium text-gray-700 mb-1 block">
                            Confirm Password
                          </label>
                          <div className="relative">
                            <Lock className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
                            <input
                              type="password"
                              name="password_confirmation"
                              value={formData.password_confirmation}
                              onChange={handleChange}
                              className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
                              placeholder="••••••••"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      {/* Course Selection */}
                      <div className="form-group">
                        <label className="text-sm font-medium text-gray-700 mb-1 block">
                          Select Course
                        </label>
                        <div className="relative">
                          <select
                            name="course"
                            value={formData.course}
                            onChange={handleChange}
                            className="w-full pl-4 pr-10 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200 appearance-none bg-white"
                            required
                          >
                            <option value="">Choose your course</option>
                            {courses.map((course) => (
                              <option key={course.id} value={course.id}>
                                {course.courseName}
                              </option>
                            ))}
                          </select>
                          <ArrowRight className="absolute top-3 right-3 text-gray-400" size={20} />
                        </div>
                      </div>

                      {/* Phone Number */}
                      <div className="form-group">
                        <label className="text-sm font-medium text-gray-700 mb-1 block">
                          Phone Number
                        </label>
                        <div className="relative">
                          <Phone className="absolute top-3 left-3 text-gray-400 transition-colors duration-200" size={20} />
                          <input
                            type="text"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200"
                            placeholder="98XXXXXXXX"
                            maxLength="10"
                            required
                          />
                        </div>
                      </div>

                      {/* Payment Section */}
                      <div className="space-y-4">
                        <motion.button
                          type="button"
                          onClick={() => setShowPopup(true)}
                          className="w-full py-3 px-4 bg-green-50 hover:bg-green-100 text-green-700 rounded-xl transition-all duration-200 flex items-center justify-center gap-2 group"
                          whileHover={{ scale: 1.01 }}
                          whileTap={{ scale: 0.99 }}
                        >
                          <FileText size={20} className="group-hover:rotate-6 transition-transform duration-200" />
                          View Payment QR Code
                        </motion.button>

                        <div className="form-group">
                          <label className="text-sm font-medium text-gray-700 mb-1 block">
                            Payment Screenshot
                          </label>
                          <div className="relative">
                            <input
                              type="file"
                              name="payment_detail"
                              onChange={handleFileChange}
                              className="w-full pl-4 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 transition-all duration-200
                                file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 
                                file:text-sm file:font-semibold file:bg-green-50 file:text-green-700 
                                hover:file:bg-green-100 file:cursor-pointer"
                            />
                          </div>
                          <p className="mt-2 text-sm text-gray-500">
                            Upload the screenshot of your payment
                          </p>
                        </div>
                      </div>

                      {/* Submit Button */}
                      <motion.button
                        type="submit"
                        className="w-full py-4 px-6 bg-gradient-to-r from-green-600 to-green-700 text-white rounded-xl hover:from-green-700 hover:to-green-800 transition-all duration-200 flex items-center justify-center gap-2 group shadow-lg shadow-green-500/25"
                        whileHover={{ scale: 1.01 }}
                        whileTap={{ scale: 0.99 }}
                      >
                        Create Account
                        <ArrowRight size={20} className="group-hover:translate-x-1 transition-transform duration-200" />
                      </motion.button>

                      {/* Login Link */}
                      <p className="text-center text-gray-600 mt-6">
                        Already have an account?{" "}
                        <Link
                          to="/login"
                          className="text-green-600 hover:text-green-700 font-medium transition-colors duration-200"
                        >
                          Sign in
                        </Link>
                      </p>
                    </form>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* QR Popup */}
      {showPopup && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-2xl p-6 max-w-md w-full shadow-2xl"
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Payment QR Code</h3>
              <button
                onClick={() => setShowPopup(false)}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
              >
                <span className="sr-only">Close</span>
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <QRPopup onClose={() => setShowPopup(false)} />
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

// Feature component for the welcome section
const Feature = ({ text }) => (
  <div className="flex items-center space-x-3">
    <CheckCircle className="w-5 h-5 text-green-300" />
    <span>{text}</span>
  </div>
);

export default RegisterPage;



