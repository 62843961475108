// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import PrivateRoute from "./utils/PrivateRoute"
// import { AuthProvider } from './context/AuthContext'

// import Homepage from './views/Homepage'
// import Registerpage from './views/Registerpage'
// import Loginpage from './views/Loginpage'
// import Dashboard from './components/Dashboard'
// import Navbar from './views/Navbar'
// import Quiz from './components/Quiz'
// import RandomQuiz from './components/RandomQuiz'


// import Sallybus from './noticeandsallybus/Sallybus'
// import Chapter from './surveyofficer/Chapter';
// import ChapterCard from './surveyofficer/ChapterCard';




// function App() {
//   return (
//     <Router>
//       <AuthProvider>
//         < Navbar/>
 
//         <Switch>
          
//           <PrivateRoute component={Dashboard} path="/dashboard" exact /> 
//           <PrivateRoute component={RandomQuiz} path="/rquiz" exact /> 
//                  <PrivateRoute component={Quiz} path="/quiz" exact /> 
//       <PrivateRoute component={Sallybus} path="/sallybus" exact />
//             <Route component={Chapter} path="/chapter" exact />
//           <Route component={Loginpage} path="/login" />
//           <Route component={Registerpage} path="/register" exact />
//           <Route component={ChapterCard} path="/chapterlist" exact />
         
//           <Route component={Homepage} path="/" exact />
//         </Switch>
//       </AuthProvider>
//     </Router>
//   )
// }

// export default App


import React,{useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from "./utils/PrivateRoute";
// import { AuthProvider } from './context/AuthContext';
import { AuthProvider} from "./context/AuthContext";

import Homepage from './views/Homepage';
import Registerpage from './views/Registerpage';
import Loginpage from './views/Loginpage';
import Dashboard from './components/Dashboard';
import Navbar from './views/Navbar';
import Quiz from './components/Quiz';
import Chapter from './surveyofficer/Chapter';
import ChapterCard from './surveyofficer/ChapterCard';
import ChapterCardUn from './surveyofficer/ChapterCardUnverified';
import RandomQuiz from './components/RandomQuiz'
import CourseHome from "./views/CourseHome";
import Profile from "./views/Profile"
import CourseDetail from './views/Course-detail';
import SubChapter from './views/Sub-chapter';
import WeeklyResults from './views/results/WeeklyResults';
import MockTestResults from './views/results/MockTestResults';
import ChapterTestResults from './views/results/ChapterTestResults';
import ForgotPassword from './views/ForgetPasswordPage';
import UpdatePassword from './views/password/UpdatePassword';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import WeeklyTests from './views/Weekly-test';
import ChapterWiseExam from './views/exams/ChaperWiseExam';
import SubChapterWiseExam from './views/exams/SubChapterWiseExam';
import MockTestExam from './views/exams/MockTestExam';
import WeeklyExam from './views/exams/WeeklyExam';
import QuizResults from './views/results/QuizResults';
import SubChapterTestResult from './views/results/SubChapterTestResult';
import EveryWeeklyTestResult from './views/results/EveryWeeklyTestResult';

import Demo from './views/exams/demo';
// import ValidityExpired from "./views/ValidityExpired"; // Add a validity-expired page

import UnAttemptedQuestion from './views/exams/UnAttempted';

import AnswerSheet from './views/exams/AnswerSheet';
import ChapterWiseSubjectiveExam from './views/exams/ChapterWiseSubjectiveExam';
import SubWeeklytest from './views/Weekly-test-sub';
import WeeklySubjectiveExam from './views/exams/WeeklyExamSub';
import SubWeeklyTestResult from './views/results/SubWeeklyTestResult';
import ViewCheckedAnswer from './views/exams/ViewCheckedAnswer'

import './index.css'




function App() {



  useEffect(() => {
    // Disable right-click
    const disableContextMenu = (e) => e.preventDefault();
    document.addEventListener("contextmenu", disableContextMenu);

    // Disable specific key combinations
    const disableKeys = (e) => {
      if (
        (e.ctrlKey && ["c", "u", "s", "p"].includes(e.key.toLowerCase())) || // Ctrl+C, Ctrl+U, Ctrl+S, Ctrl+P
        e.key === "PrintScreen"
      ) {
        e.preventDefault();
        alert("This action is not allowed!");
        window.location.href = "https://geoneer.com"; // Redirect on PrintScreen
      }
    };
    document.addEventListener("keydown", disableKeys);

    // Disable screenshots (blank out screen content)
    const disableScreenshots = () => {
      const inputElement = document.createElement("input");
      inputElement.setAttribute("type", "password");
      inputElement.style.position = "absolute";
      inputElement.style.opacity = "0";
      document.body.appendChild(inputElement);
      inputElement.focus();
      setTimeout(() => {
        document.body.removeChild(inputElement);
      }, 100);
    };
    window.addEventListener("keyup", (e) => {
      if (e.key === "PrintScreen") {
        disableScreenshots();
      }
    });

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
      document.removeEventListener("keydown", disableKeys);
      window.removeEventListener("keyup", disableScreenshots);
    };
  }, []);
  return (

    <Router>
      <AuthProvider>
        <Navbar />
        <Switch>
          <PrivateRoute component={Dashboard} path="/dashboard" exact />
          <PrivateRoute component={Quiz} path="/quiz" exact />
         <PrivateRoute component={RandomQuiz} path="/rquiz" exact />
          {/* <PrivateRoute component={ChapterCard} path="/chapterlist" exact /> */}
          <Route component={Chapter} path="/chapter" exact />
          <Route component={ChapterCardUn} path="/cun" exact />
          <PrivateRoute component={Profile} path="/profile" exact />
          <PrivateRoute component={CourseDetail} path="/course-detail" exact />
          <PrivateRoute component={SubChapter} path="/subchapters" exact />
          <PrivateRoute component={WeeklyTests} path="/weekly-tests" exact />

          <PrivateRoute component={WeeklyResults} path="/weekly-test-results" exact />
          <PrivateRoute component={MockTestResults} path="/mock-test-results" exact />
          <PrivateRoute component={ChapterTestResults} path="/chapter-test-results" exact />
          <PrivateRoute component={SubChapterTestResult} path="/sub-chapter-test-results" exact />
          <PrivateRoute component={EveryWeeklyTestResult} path="/every-weekly-test-result" exact />



          <PrivateRoute component={ChapterWiseExam} path="/chapterwise-exam" exact />
          <PrivateRoute component={SubChapterWiseExam} path="/sub-chapterwise-exam" exact />
          <PrivateRoute component={MockTestExam} path="/mock-test" exact />
          <PrivateRoute component={WeeklyExam} path="/weekly-exam" exact />
          <PrivateRoute component={Demo} path="/demo" exact />


          
          <PrivateRoute component={CourseHome} path="/course/:courseName"  />


          <Route component={Loginpage} path="/login" />
          <Route component={Registerpage} path="/register" exact />
          <PrivateRoute component={ForgotPassword} path="/forgot-password" exact />
          <PrivateRoute component={UpdatePassword} path="/update-password" exact />

          <PrivateRoute component={QuizResults} path="/qr" exact />
          <PrivateRoute component={UnAttemptedQuestion} path="/uq" exact />
          <PrivateRoute component={AnswerSheet} path="/weekly-test-sheet" exact />



          <PrivateRoute component={ChapterWiseSubjectiveExam} path="/chapterwise-subjective-exam" exact />
          <PrivateRoute component={SubWeeklytest} path="/sub-weekly-test" exact />
          <PrivateRoute component={WeeklySubjectiveExam} path="/weekly-subjective-exam" exact/>

          
          <PrivateRoute component={SubWeeklyTestResult} path="/weekly-subjective-exam-result" exact/>
          <PrivateRoute component={ViewCheckedAnswer} path="/weekly-subjective-checked-answer" exact/>


          
          <Route component={Homepage} path="/" exact />



   
            
              {/* <PrivateRoute component={Dashboard} path="/dashboard" exact /> */}
              <PrivateRoute component={ChapterCard} path="/chapterlist" exact />

       
        
        </Switch>
      </AuthProvider>
    </Router>

  );
}

export default App;



//above code is working
