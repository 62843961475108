// import React, { createContext, useState, useEffect } from "react";
// import jwt_decode from "jwt-decode";
// import { useHistory } from "react-router-dom";
// const swal = require('sweetalert2');

// const AuthContext = createContext();

// export default AuthContext;

// export const AuthProvider = ({ children }) => {
//   const [authTokens, setAuthTokens] = useState(() =>
//     localStorage.getItem("authTokens")
//       ? JSON.parse(localStorage.getItem("authTokens"))
//       : null
//   );

//   const [user, setUser] = useState(() =>
//     localStorage.getItem("authTokens")
//       ? jwt_decode(localStorage.getItem("authTokens"))
//       : null
//   );

//   const [loading, setLoading] = useState(true);
//   const history = useHistory();
//   const protocol = window.location.protocol === "https:" ? "https" : "https";

//   const loginUser = async (email, password) => {
//     try {
//       const response = await fetch(`${protocol}://auth.geoneer.com.np/api/token/`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });

//       const data = await response.json();

//       if (response.status === 200) {
//         setAuthTokens(data);
//         setUser(jwt_decode(data.access));
//         localStorage.setItem("authTokens", JSON.stringify(data));
//         history.push("/chapterlist");
//         swal.fire({
//           title: "Login Successful",
//           icon: "success",
//           toast: true,
//           timer: 6000,
//           position: 'top-right',
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       } else {
//         console.log(response.status);
//         swal.fire({
//           title: "Email or password does not exist",
//           icon: "error",
//           toast: true,
//           timer: 6000,
//           position: 'top-right',
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.error("An error occurred during login:", error);
//     }
//   };

//   const registerUser = async (email, username, password, password2, course, paymentDetails, phoneNumber) => {
//     try {
//       const formData = new FormData();
//       formData.append("email", email);
//       formData.append("username", username);
//       formData.append("password", password);
//       formData.append("password2", password2);
//       formData.append("course", course);
//       formData.append("phone_number", phoneNumber);
      
//       // Append payment details file if available
//       if (paymentDetails) {
//         formData.append("payment_details", paymentDetails);
//       }
  
//       const response = await fetch(`${protocol}://auth.geoneer.com.np/api/register/`, {
//         method: "POST",
//         body: formData,
     
//       });
  
//       if (response.status === 201) {
//         history.push("/login");
//         swal.fire({
//           title: "Registration Successful, You will get an email after your ID is activated",
//           icon: "success",
//           toast: true,
//           timer: 6000,
//           position: 'top-right',
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       } else {
//         console.log(response);
//         swal.fire({
//           // title: "You will get an email after your ID is activated",
//           title:response.error,
//           icon: "error",
//           toast: true,
//           timer: 6000,
//           position: 'top-right',
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.error("An error occurred during registration:", error);
//       swal.fire({
//         error
//       });
//     }
//   };
  
//   const fetchQuizResults = async () => {
//     if (!authTokens) {
//       console.log("No auth tokens found.");
//       return null;
//     }

//     try {
//       const response = await fetch(`${protocol}://auth.geoneer.com.np/api/quiz-results/`, {
//         method: "GET",
//         headers: {
//           "Authorization": `Bearer ${authTokens.access}`,
//           "Content-Type": "application/json",
//         },
//       });

//       if (response.status === 200) {
//         const data = await response.json();
//         return data; // Return the quiz results
//       } else {
//         console.error("Failed to fetch quiz results:", response.status);
//         const errorData = await response.json();
//         swal.fire({
//           title: "Error fetching quiz results",
//           text: errorData.detail || "An error occurred",
//           icon: "error",
//           toast: true,
//           timer: 6000,
//           position: 'top-right',
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.error("An error occurred while fetching quiz results:", error);
//       swal.fire({
//         title: "An error occurred",
//         text: error.message,
//         icon: "error",
//         toast: true,
//         timer: 6000,
//         position: 'top-right',
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     }
//   };




//   const logoutUser = () => {
//     setAuthTokens(null);
//     setUser(null);
//     localStorage.removeItem("authTokens");
//     history.push("/login");
//     swal.fire({
//       title: "You have been logged out...",
//       icon: "success",
//       toast: true,
//       timer: 6000,
//       position: 'top-right',
//       timerProgressBar: true,
//       showConfirmButton: false,
//     });
//   };

//   const contextData = {
//     user,
//     setUser,
//     authTokens,
//     setAuthTokens,
//     registerUser,
//     loginUser,
//     logoutUser,
//     fetchQuizResults,
//   };

//   useEffect(() => {
//     if (authTokens) {
//       setUser(jwt_decode(authTokens.access));
//     }
//     setLoading(false);
//   }, [authTokens, loading]);

//   return (
//     <AuthContext.Provider value={contextData}>
//       {loading ? null : children}
//     </AuthContext.Provider>
//   );
// };



// import React, { createContext, useState, useEffect } from "react";
// import jwt_decode from "jwt-decode";
// import { useHistory } from "react-router-dom";
// import Swal from "sweetalert2";

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [authTokens, setAuthTokens] = useState(() =>
//     localStorage.getItem("authTokens")
//       ? JSON.parse(localStorage.getItem("authTokens"))
//       : null
//   );

//   const [user, setUser] = useState(() =>
//     localStorage.getItem("authTokens")
//       ? jwt_decode(localStorage.getItem("authTokens"))
//       : null
//   );

//   const [loading, setLoading] = useState(true);
//   const history = useHistory();

//   const baseURL = "https://nec.geoneer.com.np/api/accounts/";

//   const loginUser = async (email, password) => {
//     try {
//       const response = await fetch(`${baseURL}token/`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });

//       const data = await response.json();

//       if (response.status === 200) {
//         setAuthTokens(data);
//         setUser(jwt_decode(data.access));
//         localStorage.setItem("authTokens", JSON.stringify(data));
//         history.push("/chapterlist");
//         Swal.fire({
//           title: "Login Successful",
//           icon: "success",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       } else {
//         Swal.fire({
//           title: "Email or password is incorrect",
//           icon: "error",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.error("An error occurred during login:", error);
//     }
//   };

//   const registerUser = async (formData) => {
//     try {
//       const response = await fetch(`${baseURL}register/`, {
//         method: "POST",
//         body: formData,
//       });

//       if (response.status === 201) {
//         history.push("/login");
//         Swal.fire({
//           title: "Registration Successful. Please wait for activation.",
//           icon: "success",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       } else {
//         Swal.fire({
//           title: "Registration Failed",
//           text: response.detail || "An error occurred",
//           icon: "error",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.error("An error occurred during registration:", error);
//     }
//   };

//   const logoutUser = () => {
//     setAuthTokens(null);
//     setUser(null);
//     localStorage.removeItem("authTokens");
//     history.push("/login");
//     Swal.fire({
//       title: "Logged out successfully",
//       icon: "success",
//       toast: true,
//       timer: 6000,
//       position: "top-right",
//       timerProgressBar: true,
//       showConfirmButton: false,
//     });
//   };

//   const contextData = {
//     user,
//     setUser,
//     authTokens,
//     setAuthTokens,
//     registerUser,
//     loginUser,
//     logoutUser,
//   };

//   useEffect(() => {
//     if (authTokens) {
//       setUser(jwt_decode(authTokens.access));
//     }
//     setLoading(false);
//   }, [authTokens]);

//   return (
//     <AuthContext.Provider value={contextData}>
//       {loading ? null : children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthContext;


//the code working is below



import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [userDetails, setUserDetails] = useState(null); 

  const baseURL = "https://nec.geoneer.com.np/api/accounts/";

  const loginUser = async (email, password) => {
    try {
      // First, get the tokens using /token
      const response = await fetch(`${baseURL}token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
      console.log(data);
  
      if (response.status === 200) {
        setAuthTokens(data);
        setUser(jwt_decode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
  
        // Now, use the access token to fetch user details (Ensure this is a POST request if needed)
        const userDetailsResponse = await fetch(`${baseURL}login/`, {
          method: "POST", // Change to POST if the login endpoint expects POST for user details
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }), // Send the email and password again
        });
  
        if (userDetailsResponse.status === 401) {
          // If token is expired, attempt refresh
          const refreshResponse = await fetch(`${baseURL}token/refresh/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: data.refresh }), // Send the refresh token
          });
  
       
          if (refreshResponse.status === 200) {
            // Set new tokens
            
  
            // Retry the user details request with the email and password in the body
            const retryUserDetailsResponse = await fetch(`${baseURL}login/`, {
              method: "POST", // Ensure this is POST
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email, password }), // Send the email and password again
            });
  
            const userDetails = await retryUserDetailsResponse.json();
            if (retryUserDetailsResponse.status === 200) {
              // Handle the user details and redirection
              handleUserRedirect(userDetails);
              setUserDetails(userDetails);
            } else {
              console.error("Failed to fetch user details after refreshing the token");
            }
          } else {
            console.error("Failed to refresh token");
          }
        } else {
          const userDetails = await userDetailsResponse.json();
          localStorage.setItem("userDetails", JSON.stringify(userDetails));
          // Handle the user details and redirection
          if (userDetails.course) {
            console.log(userDetails);
            handleUserRedirect(userDetails);
          } else {
            history.push("/home");
          }
        }
  
        // Notify the user of successful login
        Swal.fire({
          title: "Login Successful",
          icon: "success",
          toast: true,
          timer: 600,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Email or password is incorrect",
          icon: "error",
          toast: true,
          timer: 600,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
    }
  };
  
  // Helper function to handle redirection based on user details
  const handleUserRedirect = (userDetails) => {
    if (userDetails.course) {
      // const courseName = userDetails.course.courseName.replace(/\s+/g, "-").toLowerCase();
      // const courseName = userDetails.course.courseCode;
      localStorage.setItem("userDetails", JSON.stringify(userDetails));

      history.push(`/profile`);
    } else {
      history.push("/dashboard");
    }
  };
  


  const registerUser = async (formData) => {
    try {
      const response = await fetch(`${baseURL}register/`, {
        method: "POST",
        body: formData,
      });

      if (response.status === 201) {
        history.push("/login");
        Swal.fire({
          title: "Registration Successful. Please wait for activation.",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Registration Failed",
          text: response.detail || "An error occurred",
          icon: "error",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("An error occurred during registration:", error);
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    history.push("/login");
    Swal.fire({
      title: "Logged out successfully",
      icon: "success",
      toast: true,
      timer: 6000,
      position: "top-right",
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  const contextData = {
    user: user || {}, // Provide an empty object if `user` is null
    setUser,
    authTokens,
    setAuthTokens,
    userDetails,  // Add userDetails to the context
    setUserDetails,
    registerUser,
    loginUser,
    logoutUser,
  };

  useEffect(() => {
    if (authTokens) {
      const decodedUser = jwt_decode(authTokens.access);
      console.log("Decoded User:", decodedUser);
      setUser(decodedUser);
  
      // Optionally re-fetch full user details if needed
    }
    setLoading(false);
  }, [authTokens]);
  

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export default AuthContext;



// The code below is for the single device login



// import React, { createContext, useState, useEffect } from "react";
// import jwt_decode from "jwt-decode";
// import { useHistory } from "react-router-dom";
// import Swal from "sweetalert2";

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [authTokens, setAuthTokens] = useState(() =>
//     localStorage.getItem("authTokens")
//       ? JSON.parse(localStorage.getItem("authTokens"))
//       : null
//   );

//   const [user, setUser] = useState(() =>
//     localStorage.getItem("authTokens")
//       ? jwt_decode(localStorage.getItem("authTokens"))
//       : null
//   );

//   const [loading, setLoading] = useState(true);
//   const history = useHistory();
//   const [userDetails, setUserDetails] = useState(null);

//   const baseURL = "https://nec.geoneer.com.np/api/accounts/";

//   // Generate a unique Device ID or retrieve it from localStorage
//   const generateDeviceId = () => {
//     let deviceId = localStorage.getItem('deviceId');
//     if (!deviceId) {
//       deviceId = `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
//       localStorage.setItem('deviceId', deviceId);
//     }
//     return deviceId;
//   };

//   const loginUser = async (email, password) => {
//     try {
//       const deviceId = generateDeviceId(); // Get the device ID

//       // First, get the tokens using /token
//       const response = await fetch(`${baseURL}token/`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email,
//           password,
//           deviceId, // Include device ID in login request
//         }),
//       });

//       const data = await response.json();
//       console.log(data);

//       if (response.status === 200) {
//         setAuthTokens(data);
//         setUser(jwt_decode(data.access));
//         localStorage.setItem("authTokens", JSON.stringify(data));

//         // Check if the user is logged in from another device
//         const userDetailsResponse = await fetch(`${baseURL}login/`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             email,
//             password,
//             device_id: deviceId, // Send deviceId to backend for validation
//           }),
//         });

//         if (userDetailsResponse.status === 200) {
//           const userDetails = await userDetailsResponse.json();
//           localStorage.setItem("userDetails", JSON.stringify(userDetails));
//           handleUserRedirect(userDetails);
//           Swal.fire({
//             title: "Login Successful",
//             icon: "success",
//             toast: true,
//             timer: 6000,
//             position: "top-right",
//             timerProgressBar: true,
//             showConfirmButton: false,
//           });
//         } else if (userDetailsResponse.status === 409) {
//           // If status 409, it means another session is active
//           Swal.fire({
//             title: "Already logged in on another device",
//             icon: "error",
//             text: "You are already logged in on another device. Please log out from that device to continue.",
//             toast: true,
//             position: "top-right",
//             showConfirmButton: true,
//           });
//           logoutUser();
//         }
//       } else {
//         Swal.fire({
//           title: "Email or password is incorrect",
//           icon: "error",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.error("An error occurred during login:", error);
//     }
//   };

//   // Helper function to handle redirection based on user details
//   const handleUserRedirect = (userDetails) => {
//     if (userDetails.course) {
//       localStorage.setItem("userDetails", JSON.stringify(userDetails));
//       history.push(`/profile`);
//     } else {
//       history.push("/dashboard");
//     }
//   };

//   const registerUser = async (formData) => {
//     try {
//       const response = await fetch(`${baseURL}register/`, {
//         method: "POST",
//         body: formData,
//       });

//       if (response.status === 201) {
//         history.push("/login");
//         Swal.fire({
//           title: "Registration Successful. Please wait for activation.",
//           icon: "success",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       } else {
//         Swal.fire({
//           title: "Registration Failed",
//           text: response.detail || "An error occurred",
//           icon: "error",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       console.error("An error occurred during registration:", error);
//     }
//   };

//   const logoutUser = () => {
//     setAuthTokens(null);
//     setUser(null);
//     localStorage.removeItem("authTokens");
//     localStorage.removeItem("userDetails");
//     localStorage.removeItem("deviceId"); // Clear device ID on logout
//     history.push("/login");
//     Swal.fire({
//       title: "Logged out successfully",
//       icon: "success",
//       toast: true,
//       timer: 6000,
//       position: "top-right",
//       timerProgressBar: true,
//       showConfirmButton: false,
//     });
//   };

//   const contextData = {
//     user: user || {}, // Provide an empty object if `user` is null
//     setUser,
//     authTokens,
//     setAuthTokens,
//     userDetails,  // Add userDetails to the context
//     setUserDetails,
//     registerUser,
//     loginUser,
//     logoutUser,
//   };

//   useEffect(() => {
//     if (authTokens) {
//       const decodedUser = jwt_decode(authTokens.access);
//       console.log("Decoded User:", decodedUser);
//       setUser(decodedUser);
  
//       // Optionally re-fetch full user details if needed
//     }
//     setLoading(false);
//   }, [authTokens]);

//   return (
//     <AuthContext.Provider value={contextData}>
//       {loading ? null : children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthContext;




