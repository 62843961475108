// import React, { useState, useEffect } from 'react';
// import './ChapterWiseSubjectiveExam.css'; // Import custom styles
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// const ChapterWiseSubjectiveExam = () => {
//   const [questions, setQuestions] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const location = useLocation();
//   const chapterCode = location.state?.chapterCode;

//   useEffect(() => {
//     const fetchQuestions = async () => {
//       try {
//         const response = await fetch(`https://nec.geoneer.com.np/api/exam/squestions/SO-PSC/${chapterCode}/`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch questions');
//         }
//         const data = await response.json();
//         setQuestions(data);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchQuestions();
//   }, [chapterCode]);

//   if (loading) return <div className="loading">Loading questions...</div>;
//   if (error) return <div className="error">Error: {error}</div>;

//   return (
//     <div className="exam-container">
//       <h1 className="title">Subjective Questions</h1>
//       {questions.length > 0 ? (
//         questions.map((item, index) => (
//           <div key={item.id} className="question-card">
//             <div className="question-content">
//               <strong>{index + 1}. </strong>
//               <span dangerouslySetInnerHTML={{ __html: item.question }}></span>
//             </div>
//             {item.hints && item.hints.toLowerCase() !== 'na' && (
//               <div
//                 className="hints"
//                 dangerouslySetInnerHTML={{ __html: item.hints }}
//               ></div>
//             )}
//             <p className="weightage">Weightage: {item.weightage}</p>
//           </div>
//         ))
//       ) : (
//         <div className="no-questions">No questions available</div>
//       )}
//     </div>
//   );
// };

// export default ChapterWiseSubjectiveExam;



import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import './ChapterWiseSubjectiveExam.css';

const ChapterWiseSubjectiveExam = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const chapterCode = location.state?.chapterCode;

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`https://nec.geoneer.com.np/api/exam/squestions/SO-PSC/${chapterCode}/`);
        if (!response.ok) {
          throw new Error('Failed to fetch questions');
        }
        const data = await response.json();
        setQuestions(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [chapterCode]);

  if (loading) return <div className="loading">Loading questions...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="exam-container">
      <h1 className="title">Subjective Questions</h1>
      {questions.length > 0 ? (
        questions.map((item, index) => (
          <div key={item.id} className="question-card">
            <div className="question-content">
              <span className="question-number">{index + 1}. </span>
              <span dangerouslySetInnerHTML={{ __html: item.question }}></span>
            </div>
            {item.hints && item.hints.toLowerCase() !== 'na' && (
              <div
                className="hints"
                dangerouslySetInnerHTML={{ __html: item.hints }}
              ></div>
            )}
            {/* <p className="weightage">Weightage: {item.weightage}</p> */}
          </div>
        ))
      ) : (
        <div className="no-questions">No questions available</div>
      )}
    </div>
  );
};

export default ChapterWiseSubjectiveExam;


