// import {Route, Redirect} from "react-router-dom"
// import {useContext} from "react"
// import AuthContext from "../context/AuthContext"


// const PrivateRoute = ({children, ...rest}) => {
//     let {user} = useContext(AuthContext)
//     return <Route {...rest}>{!user ? <Redirect to="/login" /> : children}</Route>
// }

// export default PrivateRoute


// import { Route, Redirect } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
// import AuthContext from "../context/AuthContext";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { user } = useContext(AuthContext);
//   const [isExpired, setIsExpired] = useState(false);
//   const {courseCode} = useState(null);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);

//       // Validity Expiry Check
//       const validityDate = new Date(parsedDetails?.validity || '');
//       const courseCode = parsedDetails?.courseCode;
     

//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true); // Set the flag if validity has expired
//       }
//     }
//   }, []);

//   if (!user) {
//     return <Redirect to="/login" />;
//   } else if (isExpired && rest.path !== "/profile") {
//     return <Redirect to="/profile" />;
//   }else if (courseCode == 'WTO'){
//     return <Redirect to ='/weekly-tests'/>
//         }
//   // Render the route if everything is fine
//   return <Route {...rest}>{children}</Route>;
// };

// export default PrivateRoute;
// import { Route, Redirect } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
// import AuthContext from "../context/AuthContext";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { user } = useContext(AuthContext);
//   const [isExpired, setIsExpired] = useState(false);
//   const [courseCode, setCourseCode] = useState(null);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);

//       // Validity Expiry Check
//       const validityDate = new Date(parsedDetails?.validity || "");
//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true);
//         console.log("Validity expired");
//       }

//       // Set courseCode from user details
//       setCourseCode(parsedDetails?.course?.courseCode);
//       console.log("Course Code:", parsedDetails?.course?.courseCode);
//     }
//   }, []);

//   // Debug logs
//   console.log("User:", user);
//   console.log("isExpired:", isExpired);
//   console.log("courseCode:", courseCode);
//   console.log("Current path:", rest.path);

//   if (!user) {
//     return <Redirect to="/login" />;
//   } 

//   // Prioritize WTO course code restriction
  // if (courseCode === "WTO" && rest.path !== "/weekly-tests") {
  //   console.log("Redirecting to /weekly-tests for WTO course");
  //   return <Redirect to="/weekly-tests" />;
  // }

//   // Handle expired accounts
//   if (isExpired && rest.path !== "/profile") {
//     console.log("Redirecting to /profile due to expiration");
//     return <Redirect to="/profile" />;
//   }

//   // Render the route if all conditions pass
//   console.log("Rendering route:", rest.path);
//   return <Route {...rest}>{children}</Route>;
// };

// export default PrivateRoute;



// import { Route, Redirect } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
// import AuthContext from "../context/AuthContext";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { user } = useContext(AuthContext);
//   const [isExpired, setIsExpired] = useState(false);
//   const [courseCode, setCourseCode] = useState(null);
//   const [weeklyTestOnly, setWeeklyTestOnly] = useState(false);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);

//       // Validity Expiry Check
//       const validityDate = new Date(parsedDetails?.validity || "");
//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true);
//         console.log("Validity expired");
//       }

//       // Set courseCode and weeklyTestOnly flag from user details
//       setCourseCode(parsedDetails?.course?.courseCode);
//       setWeeklyTestOnly(parsedDetails?.weekly_test_only || false);

//       console.log("Course Code:", parsedDetails?.course?.courseCode);
//       console.log("Weekly Test Only:", parsedDetails?.weekly_test_only);
//     }
//   }, []);

//   // Debug logs
//   console.log("User:", user);
//   console.log("isExpired:", isExpired);
//   console.log("courseCode:", courseCode);
//   console.log("weeklyTestOnly:", weeklyTestOnly);
//   console.log("Current path:", rest.path);

//   if (!user) {
//     return <Redirect to="/login" />;
//   } 

//   // Restrict to /weekly-tests if weekly_test_only is true
//   if (weeklyTestOnly && rest.path !== "/weekly-tests") {
//     console.log("Redirecting to /weekly-tests for weekly_test_only users");
//     return <Redirect to="/weekly-tests" />;
//   }

//   // Prioritize WTO course code restriction
//   if (courseCode === "WTO" && rest.path !== "/weekly-tests") {
//     console.log("Redirecting to /weekly-tests for WTO course");
//     return <Redirect to="/weekly-tests" />;
//   }

//   // Handle expired accounts
//   if (isExpired && rest.path !== "/profile") {
//     console.log("Redirecting to /profile due to expiration");
//     return <Redirect to="/profile" />;
//   }

//   // Render the route if all conditions pass
//   console.log("Rendering route:", rest.path);
//   return <Route {...rest}>{children}</Route>;
// };

// export default PrivateRoute;



// import { Route, Redirect } from "react-router-dom";
// import { useContext, useEffect, useState } from "react";
// import AuthContext from "../context/AuthContext";

// const PrivateRoute = ({ children, ...rest }) => {
//   const { user } = useContext(AuthContext);
//   const [isExpired, setIsExpired] = useState(false);
//   const [courseCode, setCourseCode] = useState(null);
//   const [weeklyTestOnly, setWeeklyTestOnly] = useState(false);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);

//       // Validity Expiry Check
//       const validityDate = new Date(parsedDetails?.validity || "");
//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true);
//         console.log("Validity expired");
//       }

//       // Set courseCode and weeklyTestOnly flag from user details
//       setCourseCode(parsedDetails?.course?.courseCode);
//       setWeeklyTestOnly(parsedDetails?.weekly_test_only || false);

//       console.log("Course Code:", parsedDetails?.course?.courseCode);
//       console.log("Weekly Test Only:", parsedDetails?.weekly_test_only);
//     }
//   }, []);

//   // Debug logs
//   console.log("User:", user);
//   console.log("isExpired:", isExpired);
//   console.log("courseCode:", courseCode);
//   console.log("weeklyTestOnly:", weeklyTestOnly);
//   console.log("Current path:", rest.path);

//   // List of allowed routes for weeklyTestOnly users
//   const allowedWeeklyTestRoutes = ["/weekly-tests", "/every-weekly-test-result", "/weekly-exam"];

//   if (!user) {
//     return <Redirect to="/login" />;
//   }

//   // Restrict to allowed routes if weeklyTestOnly is true
//   if (weeklyTestOnly && !allowedWeeklyTestRoutes.includes(rest.path)) {
//     console.log("Redirecting to /weekly-tests for weekly_test_only users");
//     return <Redirect to="/weekly-tests" />;
//   }

//   // Prioritize WTO course code restriction
//   if (courseCode === "WTO" && rest.path !== "/weekly-tests") {
//     console.log("Redirecting to /weekly-tests for WTO course");
//     return <Redirect to="/weekly-tests" />;
//   }

//   // Handle expired accounts
//   if (isExpired && rest.path !== "/profile") {
//     console.log("Redirecting to /profile due to expiration");
//     return <Redirect to="/profile" />;
//   }

//   // Render the route if all conditions pass
//   console.log("Rendering route:", rest.path);
//   return <Route {...rest}>{children}</Route>;
// };

// export default PrivateRoute;

import { Route, Redirect } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode"; // Install this library using: npm install jwt-decode
import AuthContext from "../context/AuthContext";

const PrivateRoute = ({ children, ...rest }) => {
  const { authTokens, user } = useContext(AuthContext);
  const [isExpired, setIsExpired] = useState(false);
  const [courseCode, setCourseCode] = useState(null);
  const [weeklyTestOnly, setWeeklyTestOnly] = useState(false);

  useEffect(() => {
    // Decode the access token and check expiration
    if (authTokens?.access) {
      try {
        const decodedToken = jwtDecode(authTokens.access);
        const expiryTime = decodedToken.exp * 1000; // Convert to milliseconds
        const currentTime = Date.now();

        if (expiryTime < currentTime) {
          setIsExpired(true);
          console.log("Access token expired");
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }

    const storedUserDetails = localStorage.getItem("userDetails");
    if (storedUserDetails) {
      const parsedDetails = JSON.parse(storedUserDetails);

      // Set courseCode and weeklyTestOnly flag from user details
      setCourseCode(parsedDetails?.course?.courseCode);
      setWeeklyTestOnly(parsedDetails?.weekly_test_only || false);

      console.log("Course Code:", parsedDetails?.course?.courseCode);
      console.log("Weekly Test Only:", parsedDetails?.weekly_test_only);
    }
  }, [authTokens]);

  // Debug logs
  console.log("User:", user);
  console.log("isExpired:", isExpired);
  console.log("courseCode:", courseCode);
  console.log("weeklyTestOnly:", weeklyTestOnly);
  console.log("Current path:", rest.path);

  // List of allowed routes for weeklyTestOnly users
  const allowedWeeklyTestRoutes = ["/weekly-tests", "/every-weekly-test-result", "/weekly-exam"];

  if (!user || isExpired) {
    console.log("Redirecting to /login due to no user or expired token");
    return <Redirect to="/login" />;
  }

  // Restrict to allowed routes if weeklyTestOnly is true
  if (weeklyTestOnly && !allowedWeeklyTestRoutes.includes(rest.path)) {
    console.log("Redirecting to /weekly-tests for weekly_test_only users");
    return <Redirect to="/weekly-tests" />;
  }

  // Prioritize WTO course code restriction
  if (courseCode === "WTO" && rest.path !== "/weekly-tests") {
    console.log("Redirecting to /weekly-tests for WTO course");
    return <Redirect to="/weekly-tests" />;
  }

  // Render the route if all conditions pass
  console.log("Rendering route:", rest.path);
  return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;









