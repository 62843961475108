
// import React, { useState, useEffect, useRef,useContext} from 'react';
// import axios from 'axios';
// import '../../components/quiz.css';
// import Swal from 'sweetalert2';
// import parse from 'html-react-parser';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import AuthContext from '../../context/AuthContext';


// const WeeklyExam = (props) => {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);
//   const [selectedAnswers, setSelectedAnswers] = useState({});
//   const questionRef = useRef(null);
//   const [showResultButton, setShowResultButton] = useState(false);
//   const [optionStyle, setOptionStyle] = useState({});
//   const [showResultDetails, setShowResultDetails] = useState(false);
//   const [disabledQuestions, setDisabledQuestions] = useState([]);
//   let questionNumber = 1; 
//   const location = useLocation();
//   const id = location.state?.id;
//   const testname = location.state?.testname;
//   // const chapterName = location.state?.chapterName;
//   const { authTokens, user } = useContext(AuthContext);
//   const [submissionResponse, setSubmissionResponse] = useState(null);



//   const handleResultSubmit = (e) => {
//     e.preventDefault(); // Prevent default form submission
//     setShowResultButton(true);
//     setDisabledQuestions(Object.keys(selectedAnswers));
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (!showResultButton) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };
  
//     window.addEventListener('beforeunload', handleBeforeUnload);
  
//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [showResultButton]);
  

//   useEffect(() => {
//     axios
//       .get(` https://nec.geoneer.com.np/api/exam/weekly-tests/${id}/questions/ `)
//       .then(response => {
//         setIsLoaded(true);
//         setItems(response.data);
//       })
//       .catch(error => {
//         setIsLoaded(true);
//         setError(error);
//       });
//   },[id]);

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width;
//       const optionStyle = {
//         width: questionWidth + 'px',
//       };
//       setOptionStyle(optionStyle);
//     }
//   }, [items]);

//   const mergedItems = items.reduce((acc, item) => {
//     if (!acc[item.id]) {
//       acc[item.id] = item;
//     } else {
//       acc[item.id] = { ...acc[item.id], ...item };
//     }
//     return acc;
//   }, {});

//   const mergedItemsArray = Object.values(mergedItems);

//   const handleOptionClick = (itemId, option) => {
//     // Check if the option is already selected for the given question
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers(prevState => ({
//         ...prevState,
//         [itemId]: option.toString(), // Convert the option to a string
//       }));
//     }
//   };

//   const getOptionClassName = (itemId, option) => {
//     const item = mergedItemsArray.find(item => item.id === itemId);
//     const selectedOption = selectedAnswers[itemId];

//     if (selectedOption === option.toString()) {
//       if (showResultButton) {
//         if (selectedOption !== item.correctOpt.toString()) {
//           return 'option wrong';
//         }
//       }

//       return 'option selected';
//     } else if (disabledQuestions.includes(itemId)) {
//       // Disable the option if the question is disabled
//       return 'option disabled';
//     } else {
//       return 'option';
//     }
//   };


//   const handleShowResultClick = async () => {
//     const correctCount = Object.values(selectedAnswers).reduce(
//       (acc, selectedOption, index) => {
//         const item = items[index];
//         if (selectedOption === item.correctOpt.toString()) {
//           acc++;
//         }
//         return acc;
//       },
//       0
//     );
    
//     const totalScore = items.length;
//     const userScore = correctCount-0.2*(totalScore-correctCount);

//     Swal.fire({
//       title: 'Result',
//       text: `You answered ${correctCount} questions correctly! Your score id ${userScore}`,
//       icon: 'info',
//     });

    
//     // const userScore = correctCount;
//     setShowResultDetails(true);

//     try {
//       const response = await axios.post(
//         `https://nec.geoneer.com.np/api/exam/weekly-test-results/5/`,
//         {
//           user: user.id,
//           test_name: testname,
//           weekly_test: id,
        
//           score: userScore.toFixed(2),
//           total_score: totalScore.toFixed(2),

//           // "id": 14,
//           // "user": 1,
//           // "test_name": "Weekly Test 2081-08-25",
//           // "weekly_test": 12,
//           // "score": "0.25",
//           // "total_score": "0.46",
//           // "timestamp": "2024-12-07T12:39:40.798480Z"
          
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${authTokens?.access}`,
//           },
//         }
//       );
//       setSubmissionResponse(response.data);
//       Swal.fire('Success', 'Quiz results submitted successfully!', 'success');
//     } catch (error) {
//       setSubmissionResponse('Failed to submit quiz results.');
//       Swal.fire('Error', 'Failed to submit quiz results.', 'error');
//     }
//   };



//   // https://nec.geoneer.com.np/api/exam/weekly-test-ranking/?test_id=12

//   // const handleShowResultClick = () => {
//   //   const count = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
//   //     const item = mergedItemsArray[index];
//   //     if (selectedOption === item.correctOpt.toString()) {
//   //       acc++;
//   //     }
//   //     return acc;
//   //   }, 0);

//   //   setShowResultDetails(true);

//   //   Swal.fire({
//   //     title: 'Result',
//   //     text: `You answered ${count} questions correctly!`,
//   //     icon: 'info',
//   //   }).then(() => {
//   //     // Store quiz data in local storage
//   //     const quizData = {
//   //       date: new Date().toLocaleString(),
//   //       score: count,
//   //     };
//   //     localStorage.setItem('quizData', JSON.stringify(quizData));
//   //   });
//   // };

//   const attendedQuestionsCount = Object.keys(selectedAnswers).length;

//   return (
//     <div className="quiz-container">
      
    
//       <h6>Practice Questions for    <strong>"{testname}"</strong> </h6>
//       <br></br>

//       {/* {console.log(chapterName)} */}

//       <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

//       {error && <div>Error: {error.message}</div>}

//       {!isLoaded && !error && <div>Loading...</div>}

//       {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

//       {isLoaded && mergedItemsArray.length > 0 && (
//    <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
//           {mergedItemsArray.map(item => (
//             <li key={item.id}>
//               <br></br>
// <h6>
//   <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//     <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
//       Q {questionNumber++}:
//     </span>
//     <span style={{ flex: 1 }}>
//       {parse(item.question)}
//     </span>
//   </div>
// </h6>

// <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
//   <li
//     className={getOptionClassName(item.id, "1")}
//     onClick={() => handleOptionClick(item.id, "1")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option1)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "2")}
//     onClick={() => handleOptionClick(item.id, "2")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option2)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "3")}
//     onClick={() => handleOptionClick(item.id, "3")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option3)}</span>
//   </li>
//   <li
//     className={getOptionClassName(item.id, "4")}
//     onClick={() => handleOptionClick(item.id, "4")}
//     style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//   >
//     <span className="option-text">{parse(item.option4)}</span>
//   </li>
// </ul>
// {showResultDetails && (
//                   <div className="result-details">
//                   <p>Correct Option: {item.correctOpt}</p>
//                   <div className="result-details"  style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//   <p >Explanation: {parse(item.explanation)}</p>
// </div>
//                 </div>
// )}

           
//             </li>
//           ))}
//         </ul>
//       )}

//       {attendedQuestionsCount === mergedItemsArray.length && !showResultButton && (
//         <button className="submit-button" onClick={handleResultSubmit}>Submit</button>
//       )}

//       {showResultButton && (
//         <button className="show-result-button" onClick={handleShowResultClick}>Show Result</button>
//       )}


//       {console.log(submissionResponse)}
//     </div>
//   );
// };

// export default WeeklyExam;




// import React, { useState, useEffect, useRef,useContext} from 'react';
// import axios from 'axios';
// import '../../components/quiz.css';
// import Swal from 'sweetalert2';
// import parse from 'html-react-parser';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import AuthContext from '../../context/AuthContext';


// const WeeklyExam = (props) => {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);
//   const [selectedAnswers, setSelectedAnswers] = useState({});
//   const questionRef = useRef(null);
//   const [showResultButton, setShowResultButton] = useState(false);
//   const [optionStyle, setOptionStyle] = useState({});
//   const [showResultDetails, setShowResultDetails] = useState(false);
//   const [disabledQuestions, setDisabledQuestions] = useState([]);
//   let questionNumber = 1; 
//   const location = useLocation();
//   const id = location.state?.id;
//   const testname = location.state?.testname;
//   // const chapterName = location.state?.chapterName;
//   const { authTokens, user } = useContext(AuthContext);
//   const [submissionResponse, setSubmissionResponse] = useState(null);



//   const handleResultSubmit = (e) => {
//     e.preventDefault(); // Prevent default form submission
//     setShowResultButton(true);
//     setDisabledQuestions(Object.keys(selectedAnswers));
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (!showResultButton) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };
  
//     window.addEventListener('beforeunload', handleBeforeUnload);
  
//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [showResultButton]);
  

//   useEffect(() => {
//     axios
//       .get(` https://nec.geoneer.com.np/api/exam/weekly-tests/${id}/questions/ `)
//       .then(response => {
//         setIsLoaded(true);
//         setItems(response.data);
//       })
//       .catch(error => {
//         setIsLoaded(true);
//         setError(error);
//       });
//   },[id]);

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width;
//       const optionStyle = {
//         width: questionWidth + 'px',
//       };
//       setOptionStyle(optionStyle);
//     }
//   }, [items]);

//   const mergedItems = items.reduce((acc, item) => {
//     if (!acc[item.id]) {
//       acc[item.id] = item;
//     } else {
//       acc[item.id] = { ...acc[item.id], ...item };
//     }
//     return acc;
//   }, {});

//   const mergedItemsArray = Object.values(mergedItems);

//   const handleOptionClick = (itemId, option) => {
//     // Check if the option is already selected for the given question
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers(prevState => ({
//         ...prevState,
//         [itemId]: option.toString(), // Convert the option to a string
//       }));
//     }
//   };

//   const getOptionClassName = (itemId, option) => {
//     const item = mergedItemsArray.find(item => item.id === itemId);
//     const selectedOption = selectedAnswers[itemId];

//     if (selectedOption === option.toString()) {
//       if (showResultButton) {
//         if (selectedOption !== item.correctOpt.toString()) {
//           return 'option wrong';
//         }
//       }

//       return 'option selected';
//     } else if (disabledQuestions.includes(itemId)) {
//       // Disable the option if the question is disabled
//       return 'option disabled';
//     } else {
//       return 'option';
//     }
//   };

//   const attendedQuestionsCount = Object.keys(selectedAnswers).length;
//   const unattemptedQuestionsCount = mergedItemsArray.length - attendedQuestionsCount;


//   const handleShowResultClick = async () => {


//     const correctCount = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
//       const item = mergedItemsArray[index];
//       if (selectedOption === item.correctOpt.toString()) {
//         acc++;
//       }
//       return acc;
//     }, 0);
    
//     const totalScore = attendedQuestionsCount;
//     const userScore = correctCount-0.2*(totalScore-correctCount);

//     Swal.fire({
//       title: 'Result',
//       text: `You answered ${correctCount} questions correctly! Your score is ${userScore}`,
//       icon: 'info',
//     });

    
//     // const userScore = correctCount;
//     setShowResultDetails(true);

//     try {
//       const response = await axios.post(
//         `https://nec.geoneer.com.np/api/exam/weekly-test-results/5/`,
//         {
//           user: user.id,
//           test_name: testname,
//           weekly_test: id,
        
//           score: userScore.toFixed(2),
//           total_score: totalScore.toFixed(2),

     
          
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${authTokens?.access}`,
//           },
//         }
//       );
//       setSubmissionResponse(response.data);
//       Swal.fire('Success', 'Quiz results submitted successfully!', 'success');
//     } catch (error) {
//       setSubmissionResponse('Failed to submit quiz results.');
//       Swal.fire('Error', 'Failed to submit quiz results.', 'error');
//     }
//   };






//   return (
//     <div className="quiz-container">
//      <h6>Practice Questions for    <strong>"{testname}"</strong> </h6>
// <br />
//     <div className="attendance-count">
//       {attendedQuestionsCount} Questions Attended <br></br> {unattemptedQuestionsCount} Unattempted
//     </div>

//     {error && <div>Error: {error.message}</div>}
//     {!isLoaded && !error && <div>Loading...</div>}
//     {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

//     {isLoaded && mergedItemsArray.length > 0 && (
//       <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
//         {mergedItemsArray.map(item => (
//           <li key={item.id} className={selectedAnswers[item.id] ? '' : 'unattempted'}>
//             <br />
//             <h6>
//               <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//                 <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
//                   Q {questionNumber++}:
//                 </span>
//                 <span style={{ flex: 1 }}>
//                   {parse(item.question)}
//                 </span>
//               </div>
//             </h6>

//             <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
//               <li
//                 className={getOptionClassName(item.id, "1")}
//                 onClick={() => handleOptionClick(item.id, "1")}
//                 style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//               >
//                 <span className="option-text">{parse(item.option1)}</span>
//               </li>
//               <li
//                 className={getOptionClassName(item.id, "2")}
//                 onClick={() => handleOptionClick(item.id, "2")}
//                 style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//               >
//                 <span className="option-text">{parse(item.option2)}</span>
//               </li>
//               <li
//                 className={getOptionClassName(item.id, "3")}
//                 onClick={() => handleOptionClick(item.id, "3")}
//                 style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//               >
//                 <span className="option-text">{parse(item.option3)}</span>
//               </li>
//               <li
//                 className={getOptionClassName(item.id, "4")}
//                 onClick={() => handleOptionClick(item.id, "4")}
//                 style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//               >
//                 <span className="option-text">{parse(item.option4)}</span>
//               </li>
//             </ul>
//             {showResultDetails && (
//               <div className="result-details">
//                 <p>Correct Option: {item.correctOpt}</p>
//                 <div className="result-details" style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//                   <p>Explanation: {parse(item.explanation)}</p>
//                 </div>
//               </div>
//             )}
//           </li>
//         ))}
//       </ul>
//     )}

//     <button className="submit-button" onClick={handleResultSubmit} 
//     style={{ display: showResultButton ? 'none' : 'block',
//       position: 'absolute', 
//     // top: '50%', 
//     left: '50%', 
//     transform: 'translate(-50%, -50%)', 
//     padding: '10px 20px', 
//     fontSize: '16px', 
//     cursor: 'pointer'
//      }}>Submit</button>

//     {showResultButton && (
//       <button className="show-result-button" onClick={handleShowResultClick}>Show Result</button>
//     )}

//     {console.log(submissionResponse)}
//   </div>
//   );
// };

// export default WeeklyExam;














import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import '../../components/quiz.css';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AuthContext from '../../context/AuthContext';

const WeeklyExam = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const questionRef = useRef(null);
  const [showResultButton, setShowResultButton] = useState(false);
  const [optionStyle, setOptionStyle] = useState({});
  const [disabledQuestions, setDisabledQuestions] = useState([]);
  const location = useLocation();
  const id = location.state?.id;
  const testname = location.state?.testname;
  const { authTokens, user } = useContext(AuthContext);
  const [submissionResponse, setSubmissionResponse] = useState(null);
    const [showResultDetails, setShowResultDetails] = useState(false);
      let questionNumber = 1; 


  // const calculateScore = () => {
  //   const correctAnswers = items.filter(
  //     item => selectedAnswers[item.id] === item.correctOpt.toString()
  //   ).length;
  //   return correctAnswers ;
  // };



  useEffect(() => {
    // Add viewport meta tag to prevent zooming/scaling
    const viewport = document.querySelector('meta[name=viewport]');
    if (!viewport) {
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
      document.head.appendChild(meta);
    }
  }, []);

  const countCorrectAnswers = () =>
    Object.entries(selectedAnswers).filter(([id, answer]) =>
      items.find(item => item.id.toString() === id && item.correctOpt.toString() === answer)
    ).length;

  const countWrongAnswers = () =>
    Object.entries(selectedAnswers).filter(([id, answer]) =>
      items.find(item => item.id.toString() === id && item.correctOpt.toString() !== answer)
    ).length;

  const countUnattempted = () => items.length - Object.keys(selectedAnswers).length;

  useEffect(() => {
    axios
      .get(`https://nec.geoneer.com.np/api/exam/weekly-tests/${id}/questions/`)
      .then(response => {
        setIsLoaded(true);
        setItems(response.data);
      })
      .catch(error => {
        setIsLoaded(true);
        setError(error);
      });
  }, [id]);

  useEffect(() => {
    if (questionRef.current) {
      const questionWidth = questionRef.current.getBoundingClientRect().width;
      setOptionStyle({ width: `${questionWidth}px` });
    }
  }, [items]);


    const mergedItems = items.reduce((acc, item) => {
    if (!acc[item.id]) {
      acc[item.id] = item;
    } else {
      acc[item.id] = { ...acc[item.id], ...item };
    }
    return acc;
  }, {});

  const handleOptionClick = (itemId, option) => {
    if (!selectedAnswers[itemId] && !showResultButton) {
      setSelectedAnswers(prevState => ({
        ...prevState,
        [itemId]: option.toString(),
      }));
    }
  };

  const handleSubmit = async () => {
    setShowResultButton(true);



    const correctCount = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
      const item = mergedItemsArray[index];
      if (selectedOption === item.correctOpt.toString()) {
        acc++;
      }
      return acc;
    }, 0);

    const totalScore = attendedQuestionsCount;
    const userScore = correctCount-0.2*(totalScore-correctCount);
        setShowResultDetails(true);

    Swal.fire({
      title: 'Result',
      text: `You answered ${correctCount} questions correctly! Your score is ${userScore}`,
      icon: 'info',
      timer: 36000,
 
    });
 
    const payload = {
      weekly_test: id,
      score: userScore,
      total_score: String(items.length).padStart(3, '0'),
      correct_count: countCorrectAnswers(),
      wrong_count: countWrongAnswers(),
      unattempted_count: countUnattempted(),
      total_questions: items.length,
      questions: items.map(item => ({
        question: item.id,
        user_selected_option: selectedAnswers[item.id] || null,
        is_correct: item.correctOpt.toString() === selectedAnswers[item.id],
      })),
    };

    axios
      .post(`https://nec.geoneer.com.np/api/exam/user/tests/store/${user.user_id}/${id}/`,
         payload, {
        headers: {
          Authorization: `Bearer ${authTokens?.access}`,
        },
      })
      .then(response => {
        setSubmissionResponse(response.data);
        // Swal.fire({
        //   title:'Submitted!', 'Your test has been submitted.',
        //   toast: true,
        // }
        
        // );
        // Swal.fire({
        //   title: 'success',
        //   text: 'Your test has been submitted.',
        //   icon: 'error',
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: true,
        //   timer: 3000
        // });

        Swal.fire({
          title: "Your test has been submitted.",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      
        
        setShowResultButton(false);
      })
      .catch(error => {
        console.error(error);
        Swal.fire({
          title: 'Error!',
          text: 'Your test has already been submitted.',
          icon: 'error',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000
        });
      });
  };

  // const getOptionClassName = (itemId, option) => {
  //   if (selectedAnswers[itemId] === option.toString()) {
  //     return showResultButton && selectedAnswers[itemId] !== items.find(item => item.id === itemId).correctOpt.toString()
  //       ? 'option wrong'
  //       : 'option selected';
  //   }
  //   return 'option';
  // };


  const getOptionClassName = (itemId, option) => {
    const item = mergedItemsArray.find(item => item.id === itemId);
    const selectedOption = selectedAnswers[itemId];
  
    if (showResultButton) {
      if (selectedOption === option.toString()) {
        return selectedOption === item.correctOpt.toString()
          ? 'option selected' // Correctly selected option
          : 'option wrong';  // Incorrectly selected option
      }
      return option.toString() === item.correctOpt.toString()
        ? 'option selected' // Highlight the correct option after submission
        : 'option';         // Unselected options
    }
  
    // If not showing results yet, handle selection and disabled state
    if (selectedOption === option.toString()) {
      return 'option selected';
    }
    if (disabledQuestions.includes(itemId)) {
      return 'option disabled';
    }
    return 'option'; // Default state
  };
  


    const mergedItemsArray = Object.values(mergedItems);

      const attendedQuestionsCount = Object.keys(selectedAnswers).length;

        const unattemptedQuestionsCount = mergedItemsArray.length - attendedQuestionsCount;



  return (
    <div className="quiz-container">


<h6>Practice Questions for    <strong>"{testname}"</strong> </h6>
<br />
    <div className="attendance-count">
      {attendedQuestionsCount} Questions Attended <br></br> {unattemptedQuestionsCount} Unattempted
    </div>

    {error && <div>Error: {error.message}</div>}
    {!isLoaded && !error && <div>Loading...</div>}
    {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

    {isLoaded && mergedItemsArray.length > 0 && (
      <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
        {mergedItemsArray.map(item => (
          <li key={item.id} className={selectedAnswers[item.id] ? '' : 'unattempted'}>
            <br />
            <h6>
              <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
                <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
                  Q {questionNumber++}:
                </span>
                <span style={{ flex: 1 }}>
                  {parse(item.question)}
                </span>
              </div>
            </h6>

            <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
              <li
                className={getOptionClassName(item.id, "1")}
                onClick={() => handleOptionClick(item.id, "1")}
                style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
              >
                <span className="option-text">{parse(item.option1)}</span>
              </li>
              <li
                className={getOptionClassName(item.id, "2")}
                onClick={() => handleOptionClick(item.id, "2")}
                style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
              >
                <span className="option-text">{parse(item.option2)}</span>
              </li>
              <li
                className={getOptionClassName(item.id, "3")}
                onClick={() => handleOptionClick(item.id, "3")}
                style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
              >
                <span className="option-text">{parse(item.option3)}</span>
              </li>
              <li
                className={getOptionClassName(item.id, "4")}
                onClick={() => handleOptionClick(item.id, "4")}
                style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
              >
                <span className="option-text">{parse(item.option4)}</span>
              </li>
            </ul>
            {showResultDetails && (
              <div className="result-details">
                <p style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>Correct Option: {item.correctOpt}</p>
                <div className="result-details" style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
                  {/* <p>Explanation: {parse(item.explanation)}</p> */}
                  <strong>Explanation:  </strong> {parse(item.explanation)}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    )}



      
      <button  className="submit-button" onClick={handleSubmit} disabled={!Object.keys(selectedAnswers).length}>
        Submit
      </button>

      {console.log(disabledQuestions)}
      {console.log(submissionResponse)}
      {console.log(setDisabledQuestions)}
    </div>
  );
};

export default WeeklyExam;






