// import React, { useContext } from "react";
// import { useParams } from "react-router-dom";
// import AuthContext from "../context/AuthContext";

// const CourseHome = () => {
//   const { courseName } = useParams(); // Extract courseName from the URL
//   const { user } = useContext(AuthContext); // Access user data from AuthContext

//   // Log data for debugging
//   console.log("User:", user);
//   console.log("Course Data:", user.courseName);
//   console.log("Course Name from URL:", courseName);

//   // Handle loading state if user data isn't ready
//   if (!user) {
//     return <p>Loading user data...</p>;
//   }

//   // Normalize course names for comparison
//   const normalizedCourseName = courseName.replace(/-/g, " ").toLowerCase();
//   const normalizedCourseCode = user.course?.courseCode?.toLowerCase();

//   return (
//     <div className="course-home">
//       <h1>Welcome to the {courseName} Home Page</h1>

//       {!user.course ? (
//         <p>You are not enrolled in any course. Please check your account.</p>
//       ) : normalizedCourseCode === normalizedCourseName ? (
//         <>
//           <p>This is the homepage for your selected course:</p>
//           <h2>{user.course.courseName}</h2>
//           <p>Course Code: {user.course.courseCode}</p>
//           {/* Additional course details can go here */}
//         </>
//       ) : (
//         <p>No details available for this course. Please check your enrollment.</p>
//       )}
//     </div>
//   );
// };

// export default CourseHome;

// import { useEffect, useState } from "react";
// import './home.css';

// const CoursePage = ({ match }) => {
//   const [userDetails, setUserDetails] = useState(null);
//   const [courseDetails, setCourseDetails] = useState(null);

//   useEffect(() => {
//     // Retrieve user details from localStorage once (only on mount)
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       setUserDetails(JSON.parse(storedUserDetails));
//     }
//   }, []); // Empty dependency array means this effect only runs once on mount

//   useEffect(() => {
//     // If userDetails is available and courseName is part of the URL, fetch the course details
//     if (userDetails && match.params.courseName) {
//       // const courseName = match.params.courseName;
//       // Fetch or process course details based on courseName (if needed)
//       const course = userDetails.course; // Or fetch from API based on courseName
//       setCourseDetails(course);
//     }
//   }, [userDetails, match.params.courseName]); // Dependencies: userDetails and courseName

//   return (
//     <div>
//       {/* <h1>Course: {courseDetails ? courseDetails.courseName : "Loading..."}</h1> */}

//       {/* Course Card */}
//       <div className="course-card">
//         {courseDetails ? (
//           <>
//             <img
//               src={`https://nec.geoneer.com.np${courseDetails.coursePhoto}`}
//               alt={courseDetails.courseName}
//               className="course-card-photo"
//             />
//             <div className="course-card-info" >
//               <div className="row">
//                 <div className="col-lg-12"><h2>{courseDetails.courseName}</h2></div>
//               </div>
//               <div className="row">
//                 <div className="col-lg-12"><p>{courseDetails.courseDescription}</p></div>
//               </div>
              
//               <div className="row">
//                 <div className="col-lg-12"><a href="/course-detail/"><button type="button" class="btn btn-success btn-sm">Explore Course</button></a>
//                 </div>
//               </div>
              
//             </div>
            
//           </>
//         ) : (
         
//           <p>Loading course details...</p>
//         )}
//       </div>

//       {/* Display userDetails */}
//       {/* <div>
//         <h2>User Details:</h2>
//         {userDetails ? (
//           <pre>{JSON.stringify(userDetails, null, 2)}</pre>
//         ) : (
//           <p>Loading user details...</p>
//         )}
//       </div> */}
//     </div>
//   );
// };

// export default CoursePage;



// import React, { useEffect, useState } from "react";
// import { motion } from "framer-motion";
// import { Book, Clock, Users } from 'lucide-react';

// const CoursePage = ({ match }) => {
//   const [userDetails, setUserDetails] = useState(null);
//   const [courseDetails, setCourseDetails] = useState(null);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       setUserDetails(JSON.parse(storedUserDetails));
//     }
//   }, []);

//   useEffect(() => {
//     if (userDetails && match.params.courseName) {
//       const course = userDetails.course;
//       setCourseDetails(course);
//     }
//   }, [userDetails, match.params.courseName]);

//   const fadeInUp = {
//     initial: { opacity: 0, y: 20 },
//     animate: { opacity: 1, y: 0 },
//     transition: { duration: 0.6 }
//   };

//   return (
//     <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4 sm:px-6 lg:px-8">
//       <motion.div
//         initial="initial"
//         animate="animate"
//         variants={fadeInUp}
//         className="max-w-3xl mx-auto"
//       >
//         {courseDetails ? (
//           <div className="bg-white shadow-xl rounded-lg overflow-hidden">
//             <div className="relative h-64 sm:h-80">
//               <img
//                 src={`https://nec.geoneer.com.np${courseDetails.coursePhoto}`}
//                 alt={courseDetails.courseName}
//                 className="w-full h-full object-cover"
//               />
//               <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//                 <h1 className="text-3xl sm:text-4xl font-bold text-white text-center px-4">
//                   {courseDetails.courseName}
//                 </h1>
//               </div>
//             </div>
//             <div className="p-6 sm:p-8">
//               <p className="text-gray-600 mb-6">{courseDetails.courseDescription}</p>
//               <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
//                 <div className="flex items-center">
//                   <Book className="w-6 h-6 text-blue-500 mr-2" />
//                   <span className="text-sm text-gray-600">12 Modules</span>
//                 </div>
//                 <div className="flex items-center">
//                   <Clock className="w-6 h-6 text-blue-500 mr-2" />
//                   <span className="text-sm text-gray-600">6 Weeks</span>
//                 </div>
//                 <div className="flex items-center">
//                   <Users className="w-6 h-6 text-blue-500 mr-2" />
//                   <span className="text-sm text-gray-600">500+ Enrolled</span>
//                 </div>
//               </div>
//               <div className="flex justify-center">
//                 <a href="/course-detail/">
//                   <button
//                     type="button"
//                     className="px-6 py-3 bg-blue-600 text-white rounded-full font-semibold hover:bg-blue-700 transition-colors duration-200 transform hover:scale-105"
//                   >
//                     Explore Course
//                   </button>
//                 </a>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <div className="bg-white shadow-md rounded-lg p-6 text-center">
//             <div className="animate-pulse flex flex-col items-center">
//               <div className="rounded-full bg-blue-200 h-20 w-20"></div>
//               <div className="mt-4 h-4 bg-blue-200 rounded w-3/4"></div>
//               <div className="mt-2 h-4 bg-blue-200 rounded w-1/2"></div>
//             </div>
//             <p className="mt-4 text-gray-600">Loading course details...</p>
//           </div>
//         )}
//       </motion.div>
//     </div>
//   );
// };

// export default CoursePage;


import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
// import { Book, Clock, Users } from "lucide-react";

const CoursePage = ({ match }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [courseDetails, setCourseDetails] = useState(null);

  useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails");
    if (storedUserDetails) {
      setUserDetails(JSON.parse(storedUserDetails));
    }
  }, []);

  useEffect(() => {
    if (userDetails && match.params.courseName) {
      const course = userDetails.course;
      setCourseDetails(course);
    }
  }, [userDetails, match.params.courseName]);

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white mt-20 py-8 px-4">
      <motion.div
        initial="initial"
        animate="animate"
        variants={fadeInUp}
        className="max-w-lg mx-auto"
      >
        {courseDetails ? (
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            {/* Card Header */}
            <div className="relative h-48">
              <img
                src={`https://nec.geoneer.com.np${courseDetails.coursePhoto}`}
                alt={courseDetails.courseName}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <h1 className="text-lg sm:text-xl font-bold text-white text-center px-2">
                  {courseDetails.courseName}
                </h1>
              </div>
            </div>

            {/* Card Body */}
            <div className="p-4">
              <p className="text-sm text-gray-600 mb-4 line-clamp-3">
               <bold>{courseDetails.courseDescription}</bold> 
              </p>
              {/* <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 mb-4">
                <div className="flex items-center">
                  <Book className="w-5 h-5 text-blue-500 mr-1" />
                  <span className="text-xs text-gray-600">12 Modules</span>
                </div>
                <div className="flex items-center">
                  <Clock className="w-5 h-5 text-blue-500 mr-1" />
                  <span className="text-xs text-gray-600">6 Weeks</span>
                </div>
                <div className="flex items-center">
                  <Users className="w-5 h-5 text-blue-500 mr-1" />
                  <span className="text-xs text-gray-600">500+ Enrolled</span>
                </div>
              </div> */}
              <div className="flex justify-center">
                <a href="/course-detail/">
                  <button
                    type="button"
                    className="px-4 py-2 bg-blue-600 text-white rounded-full text-sm font-medium hover:bg-blue-700 transition-colors duration-200 transform hover:scale-105"
                  >
                    Explore Course
                  </button>
                </a>
              </div>
            </div>
          </div>
        ) : (
          // Loading State
          <div className="bg-white shadow-md rounded-lg p-4 text-center">
            <div className="animate-pulse flex flex-col items-center">
              <div className="rounded-full bg-blue-200 h-16 w-16"></div>
              <div className="mt-3 h-3 bg-blue-200 rounded w-3/4"></div>
              <div className="mt-2 h-3 bg-blue-200 rounded w-1/2"></div>
            </div>
            <p className="mt-4 text-gray-600 text-sm">Loading course details...</p>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default CoursePage;







