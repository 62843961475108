// import React, { useState, useEffect ,useContext} from "react";
// // import { useParams } from "react-router-dom";
// import { useLocation } from 'react-router-dom';
// import AuthContext from '../../context/AuthContext';


// const ViewCheckedAnswer = () => {
//   const [answerDetails, setAnswerDetails] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const location = useLocation();
//   const testId = location.state?.id;
//   const {  user } = useContext(AuthContext);


//   useEffect(() => {
//     const fetchAnswerDetails = async () => {
//       try {
//         const response = await fetch(
//           `https://nec.geoneer.com.np/api/exam/view_answer/${testId}/${user.user_id}/`,
//           {
//             method: "GET",
           
//           }
//         );

//         if (!response.ok) {
//           throw new Error("Failed to fetch answer details");
//         }

//         const data = await response.json();
//         setAnswerDetails(data);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAnswerDetails();
//   }, [testId,user]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
//       <h1>Checked Answer Details</h1>
//       {answerDetails ? (
//         <div>
//           <div>
//             <strong>Marks Awarded:</strong> {answerDetails.marks_awarded ?? "Not yet marked"}
//           </div>
//           <div>
//             <strong>Comments:</strong>{" "}
//             {answerDetails.comments?.trim() ? answerDetails.comments : "No comments provided"}
//           </div>
//           <div>
//             <strong>Marked Status:</strong>{" "}
//             {answerDetails.is_marked ? "Marked" : "Not Marked"}
//           </div>
//           <div>
//             <strong>Uploaded At:</strong> {new Date(answerDetails.uploaded_at).toLocaleString()}
//           </div>
//           {answerDetails.pdf_answer && (
//             <div>
//               <strong>Answer PDF:</strong>{" "}
//               <a
//                 href={answerDetails.pdf_answer}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 style={{ color: "blue" }}
//               >
//                 View Answer Sheet
//               </a>
//             </div>
//           )}
//         </div>
//       ) : (
//         <div>No details available</div>
//       )}
//     </div>
//   );
// };

// export default ViewCheckedAnswer;



import React, { useState, useEffect, useContext } from "react";
import { useLocation } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { motion } from 'framer-motion';
import { FileText, AlertCircle, CheckCircle, Calendar, User, Award } from 'lucide-react';

const ViewCheckedAnswer = () => {
  const [answerDetails, setAnswerDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const testId = location.state?.id;
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchAnswerDetails = async () => {
      try {
        const response = await fetch(
          `https://nec.geoneer.com.np/api/exam/view_answer/${testId}/${user.user_id}/`,
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch answer details");
        }

        const data = await response.json();
        setAnswerDetails(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAnswerDetails();
  }, [testId, user]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <div className="w-16 h-16 border-4 border-green-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-xl font-semibold text-gray-700">Loading answer details...</p>
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg shadow-md"
          role="alert"
        >
          <div className="flex items-center">
            <AlertCircle className="w-6 h-6 mr-2" />
            <p className="font-bold">Error</p>
          </div>
          <p className="mt-2">{error}</p>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white shadow-xl rounded-lg overflow-hidden"
        >
          <div className="bg-green-600 px-6 py-4">
            <h1 className="text-2xl font-bold text-white">Checked Answer Details</h1>
          </div>

          {answerDetails ? (
            <div className="p-6 space-y-6">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1, duration: 0.5 }}
                className="bg-green-50 border-l-4 border-green-500 p-4 rounded-lg"
              >
                <div className="flex items-center">
                  <Award className="w-6 h-6 text-green-600 mr-2" />
                  <span className="text-lg font-semibold text-green-800">
                    Marks Awarded: {answerDetails.marks_awarded ?? "Not yet marked"}
                  </span>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="bg-gray-50 p-4 rounded-lg"
              >
                <div className="flex items-start">
                  <User className="w-6 h-6 text-gray-600 mr-2 mt-1" />
                  <div>
                    <span className="font-semibold text-gray-700">Comments:</span>
                    <p className="mt-1 text-gray-600">
                      {answerDetails.comments?.trim() ? answerDetails.comments : "No comments provided"}
                    </p>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                className="flex flex-wrap -mx-2"
              >
                <div className="w-full sm:w-1/2 px-2 mb-4 sm:mb-0">
                  <div className="bg-blue-50 p-4 rounded-lg h-full">
                    <div className="flex items-center">
                      <CheckCircle className="w-6 h-6 text-blue-600 mr-2" />
                      <span className="font-semibold text-blue-800">
                        Status: {answerDetails.is_marked ? "Marked" : "Not Marked"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 px-2">
                  <div className="bg-purple-50 p-4 rounded-lg h-full">
                    <div className="flex items-center">
                      <Calendar className="w-6 h-6 text-purple-600 mr-2" />
                      <div>
                        <span className="font-semibold text-purple-800">Uploaded At:</span>
                        <p className="text-sm text-purple-600 mt-1">
                          {new Date(answerDetails.uploaded_at).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>

              {answerDetails.pdf_answer && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4, duration: 0.5 }}
                  className="mt-6"
                >
                  <a
                    href={answerDetails.pdf_answer}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-150 ease-in-out"
                  >
                    <FileText className="w-5 h-5 mr-2" />
                    View Answer Sheet
                  </a>
                </motion.div>
              )}
            </div>
          ) : (
            <div className="p-6 text-center text-gray-500">No details available</div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default ViewCheckedAnswer;


