// import React, { useContext,useState,useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import jwt_decode from 'jwt-decode';
// import { AuthContext } from '../context/AuthContext';
// import { Link } from 'react-router-dom';
// import logo from './logo.png';
// // import userImage from './R.png'; // Change this to the correct path of the user's image
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css'




// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);

//   let username = '';

//   if (token) {
//     const decoded = jwt_decode(token);
//     username = decoded.username;
    
//   }

//   // Function to close the menu
//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   // Close the menu when the window is resized to a larger screen
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         closeMenu();
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);


//   return (
//     <h4>
//      <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav  className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar" >
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//              <img style={{ width: '180px', padding: '6px' }} src={logo} alt="" />
//           </a>
          
//                     <button
//             className="navbar-toggler"
//             type="button"
//             data-bs-toggle="collapse"  
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul  className="navbar-nav">
//               {token === null && (

//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>

//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/quiz" onClick={closeMenu}>
//                       Take a Quiz
//                     </Link>
//                   </li> */}

//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
                    
//                   </li>
                  
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/notice" onClick={closeMenu}>
//                       Notice
//                     </Link>
//                   </li> */}

                
//                 </>
//               )}

//               {token !== null && (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/dashboard" onClick={closeMenu}>
//                       Dashboard
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/quiz" onClick={closeMenu}>
//                       Take a Quiz
//                     </Link>
//                   </li> */}
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/rquiz" onClick={closeMenu}>
//                       Take a RQuiz
//                     </Link>
//                   </li> */}
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/chapterlist" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/sallybus" onClick={closeMenu}>
//                       Syllabus
//                     </Link>
//                   </li> */}
//                   <li className="nav-item">
//                   <button className="nav-link" onClick={() => {
//                                                       logoutUser();
//                                                       closeMenu();
//                                                     }} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
//                              Logout
//                     </button>
//                   </li>
            
            
//                 </>



                
//               )}


//             </ul>

//             <div>
//                   <li className="nav-item mr-5 ml-auto d-flex align-items-center justify-content-end user-greeting" style={{font:'12px'}}> 
//                     <span>Hello, {username}!</span>
//                     {/* <img
//                       src={userImage}
//                       alt="User Profile"
//                       className="user-avatar rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     /> */}
//                   </li>
//                   </div>
//           </div>
     
//         </div>
        
//       </nav>
//     </div>
//     </h4>
//   );
// }

// export default Navbar;




// import React, { useContext, useState, useEffect } from 'react';
// import { AuthContext } from '../context/AuthContext';
// import { Link } from 'react-router-dom';
// import logo from './logo.png';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css';

// function Navbar() {
//   const {  logout, isAuthenticated } = useContext(AuthContext);
//   // const { user, logout, isAuthenticated } = useContext(AuthContext);
//   const [menuOpen, setMenuOpen] = useState(false);

//   // Function to close the menu
//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   // Close the menu when the window is resized to a larger screen
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         closeMenu();
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   return (
//     <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar">
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//             <img style={{ width: '180px', padding: '6px' }} src={logo} alt="" />
//           </a>
          
//           <button
//             className="navbar-toggler"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul className="navbar-nav">
//               {!isAuthenticated() && (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/chapterlist" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
//                   </li>
//                 </>
//               )}

//               {isAuthenticated() && (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>
//                   {/* <li className="nav-item">        
//                        <Link className="nav-link" to="/dashboard" onClick={closeMenu}>                     Dashboard
//                   </Link>
//                 </li>  */}
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/chapterlist" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <button
//                       className="nav-link"
//                       onClick={() => {
//                         logout();
//                         closeMenu(); // Close the menu after logging out
//                       }}
//                       style={{ cursor: 'pointer', border: 'none', background: 'none' }}
//                     >
//                       Logout
//                     </button>
//                   </li>
//                 </>
//               )}
//             </ul>

//             {isAuthenticated() && (
//               <div className="ml-auto d-flex align-items-center justify-content-end user-greeting">
//                 <li className="nav-item mr-5" style={{ fontSize: '12px' }}>
//                   {/* <span>Hello, {user?.username || 'User'}!</span>
//          */}
//                 </li>
//               </div>
//             )}
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// }

// export default Navbar;


// //navbar for SO-PSC

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import React, { useContext,useState,useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import jwt_decode from 'jwt-decode';
// import  AuthContext  from '../context/AuthContext';
// import { Link } from 'react-router-dom';
// import logo from './logo.png';
// import userImage from './R.png'; // Change this to the correct path of the user's image
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css'




// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);
//   let username = '';
  


//   if (token) {
//     const decoded = jwt_decode(token);
//     username = decoded.username;
    
//   }

//   // Function to close the menu
//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   // Close the menu when the window is resized to a larger screen
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         closeMenu();
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);


//   return (
//     <h4>
//      <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav  className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar" >
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//              <img style={{ width: '120px', padding: '6px' }} src={logo} alt="" />
//           </a>
          
//                     <button
//             className="navbar-toggler justify-content-end"
//             type="button"
//             data-bs-toggle="collapse"  
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul  className="navbar-nav">
//               {token === null && (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>

//                   <li className="nav-item">
//                     <Link className="nav-link" to="/cun" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>

//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
                    
//                   </li>
                  
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/notice" onClick={closeMenu}>
//                       Notice
//                     </Link>
//                   </li> */}

                
//                 </>
//               )}

//               {token !== null && (
//                 <>
//                                   <li className="nav-item">
//                     <Link className="nav-link" to="/" onClick={closeMenu}>
//                       Home
//                     </Link>
//                   </li> 
//                                   <li className="nav-item">
//                     <Link className="nav-link" to="/course/:courseName" onClick={closeMenu}>
//                       Dashboard
//                     </Link>
//                   </li> 
//               {/* <li className="nav-item">
//                     <Link className="nav-link" to="/chapterlist" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>
              
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/rquiz" onClick={closeMenu}>
//                    License practice exam
//                     </Link>
//                   </li> */}
//                                        <li className="nav-item">
//                     <Link className="nav-link" to="/profile" onClick={closeMenu}>
//                       Profile
//                     </Link>
//                   </li> 



//                   <li className="nav-item justify-content-end ml-auto">
//                   <button className="nav-link" onClick={() => {
//                                                       logoutUser();
//                                                       closeMenu();
//                                                     }} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
//                              Logout
//                     </button>
//                   </li>
//                   <li className="nav-item ml-auto d-flex align-items-center text-right user-greeting"> 
//                     <span>Hello,  {username} !</span>
//                     <img
//                       src={userImage}
//                       alt="User Profile"
//                       className="user-avatar rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     />
//                   </li>

//                            </>
//               )}
//             </ul>
//           </div>
//         </div>
        
//       </nav>
//     </div>
//     </h4>
//   );
// }

// export default Navbar;



// //navbar for nec 



// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import React, { useContext,useState,useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import jwt_decode from 'jwt-decode';
// import  AuthContext  from '../context/AuthContext';
// import { Link } from 'react-router-dom';
// import logo from './logo.png';
// import userImage from './R.png'; // Change this to the correct path of the user's image
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css'




// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);
//   let username = '';
  


//   if (token) {
//     const decoded = jwt_decode(token);
//     username = decoded.username;
    
//   }

//   // Function to close the menu
//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   // Close the menu when the window is resized to a larger screen
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         closeMenu();
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);


//   return (
//     <h4>
//      <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav  className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar" >
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//              <img style={{ width: '120px', padding: '6px' }} src={logo} alt="" />
//           </a>
          
//                     <button
//             className="navbar-toggler justify-content-end"
//             type="button"
//             data-bs-toggle="collapse"  
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul  className="navbar-nav">
//               {token === null && (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>

//                   <li className="nav-item">
//                     <Link className="nav-link" to="/cun" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>

//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
                    
//                   </li>
                  
//                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/notice" onClick={closeMenu}>
//                       Notice
//                     </Link>
//                   </li> */}

                
//                 </>
//               )}

//               {token !== null && (
//                 <>
//                                   <li className="nav-item">
//                     <Link className="nav-link" to="/" onClick={closeMenu}>
//                       Home
//                     </Link>
//                   </li> 
//                                   {/* <li className="nav-item">
//                     <Link className="nav-link" to="/course/:courseName" onClick={closeMenu}>
//                       Dashboard
//                     </Link>
//                   </li>  */}
//               <li className="nav-item">
//                     <Link className="nav-link" to="/chapterlist" onClick={closeMenu}>
//                       Chapter Wise
//                     </Link>
//                   </li>
              
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/rquiz" onClick={closeMenu}>
//                    License practice exam
//                     </Link>
//                   </li>
//                                        {/* <li className="nav-item">
//                     <Link className="nav-link" to="/profile" onClick={closeMenu}>
//                       Profile
//                     </Link>
//                   </li>  */}



//                   <li className="nav-item justify-content-end ml-auto">
//                   <button className="nav-link" onClick={() => {
//                                                       logoutUser();
//                                                       closeMenu();
//                                                     }} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
//                              Logout
//                     </button>
//                   </li>
//                   <li className="nav-item ml-auto d-flex align-items-center text-right user-greeting"> 
//                     <span>Hello,  {username} !</span>
//                     <img
//                       src={userImage}
//                       alt="User Profile"
//                       className="user-avatar rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     />
//                   </li>

//                            </>
//               )}
//             </ul>
//           </div>
//         </div>
        
//       </nav>
//     </div>
//     </h4>
//   );
// }

// export default Navbar;


// import React, { useContext, useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import jwt_decode from 'jwt-decode';
// import AuthContext from '../context/AuthContext';
// import logo from './logo.png';
// import userImage from './R.png';
// import './navbar.css';

// function Navbar({ courseCode }) {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);
//   let username = '';

//   if (token) {
//     const decoded = jwt_decode(token);
//     username = decoded.username;
//   }

//   const closeMenu = () => setMenuOpen(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) {
//         closeMenu();
//       }
//     };
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   // Define course-specific links
//   const links = {
//     'SO-PSC': [
//       { path: '/', label: 'Home' },
//       { path: '/course/:courseName', label: 'Dashboard' },
//       { path: '/profile', label: 'Profile' },
//     ],
//     'AGeE': [
//       { path: '/', label: 'Home' },
//       { path: '/chapterlist', label: 'Chapter Wise' },
//       { path: '/rquiz', label: 'License Practice Exam' },
//     ],
//   };

//   return (
//     <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white">
//       <div className="container-fluid">
//         <a className="navbar-brand" href="/">
//           <img style={{ width: '120px', padding: '6px' }} src={logo} alt="Logo" />
//         </a>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarNav"
//           aria-controls="navbarNav"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//           onClick={() => setMenuOpen(!menuOpen)}
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//           <ul className="navbar-nav">
//             {token === null
//               ? (
//                 <>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/" onClick={closeMenu}>
//                       Home
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
//                   </li>
//                 </>
//               )
//               : (
//                 <>
//                   {links['SO-PSC']?.map((link, index) => (
//                     <li className="nav-item" key={index}>
//                       <Link className="nav-link" to={link.path} onClick={closeMenu}>
//                         {link.label}
//                       </Link>
//                     </li>
//                   ))}
//                   <li className="nav-item">
//                     <button
//                       className="nav-link"
//                       onClick={() => {
//                         logoutUser();
//                         closeMenu();
//                       }}
//                       style={{ cursor: 'pointer', border: 'none', background: 'none' }}
//                     >
//                       Logout
//                     </button>
//                   </li>
//                   <li className="nav-item d-flex align-items-center">
//                     <span>Hello, {username}!</span>
//                     <img
//                       src={userImage}
//                       alt="User Avatar"
//                       className="rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     />
//                   </li>
//                 </>
//               )}
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Navbar;


// import React, { useContext, useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

// import AuthContext from '../context/AuthContext';
// import logo from './logo.png';
// import userImage from './R.png'; // Adjust path accordingly
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css';

// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [userDetails, setUserDetails] = useState(null);

//   useEffect(() => {
//     // Retrieve user details from localStorage once (only on mount)
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       setUserDetails(JSON.parse(storedUserDetails));
//     }
//   }, []); // Runs only once on mount

//   // Extract the courseCode if userDetails is available
//   const courseCode = userDetails?.course?.courseCode;

//   const links = {
//     'SO-PSC': [
//       { path: '/', label: 'Home' },
//       { path: '/course/:courseName', label: 'Dashboard' },
//       { path: '/profile', label: 'Profile' },
//     ],
//     'AGeE': [
//       { path: '/', label: 'Home' },
//       { path: '/chapterlist', label: 'Chapter Wise' },
//       { path: '/rquiz', label: 'License Practice Exam' },
//       { path: '/profile', label: 'Profile' },
//     ],
//   };

//   const closeMenu = () => setMenuOpen(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) closeMenu();
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return (
//     <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar">
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//             <img style={{ width: '120px', padding: '6px' }} src={logo} alt="Logo" />
//           </a>
//           <button
//             className="navbar-toggler justify-content-end"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul className="navbar-nav">
//               {token === null ? (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
//                   </li>
//                 </>
//               ) : (
//                 <>
//                   {links[courseCode]?.map((link, index) => (
//                     <li className="nav-item" key={index}>
//                       <Link className="nav-link" to={link.path} onClick={closeMenu}>
//                         {link.label}
//                       </Link>
//                     </li>
//                   ))}
//                   <li className="nav-item justify-content-end ml-auto">
//                     <button
//                       className="nav-link"
//                       onClick={() => {
//                         logoutUser();
//                         closeMenu();
//                       }}
//                       style={{ cursor: 'pointer', border: 'none', background: 'none' }}
//                     >
//                       Logout
//                     </button>
//                   </li>
//                   <li className="nav-item ml-auto d-flex align-items-center text-right user-greeting">
//                     <span>Hello, {userDetails?.first_name}!</span>
//                     <img
//                       src={userImage}
//                       alt="User Profile"
//                       className="user-avatar rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     />
//                   </li>
//                 </>
//               )}
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// }

// export default Navbar;

//above is the working Navbar






// import React, { useContext, useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

// import AuthContext from '../context/AuthContext';
// import logo from './logo.png';
// import userImage from './R.png'; // Adjust path accordingly
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './navbar.css';

// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [userDetails, setUserDetails] = useState(null);
//   const [isExpired, setIsExpired] = useState(false);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);
//       setUserDetails(parsedDetails);

//       // Validity Expiry Check
//       const validityDate = new Date(parsedDetails?.validity || ''); // Parse validity date
//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true); // Set the flag if validity has expired
//       }
//     }
//   }, []);

//   const courseCode = userDetails?.course?.courseCode;

//   const links = {
//     'SO-PSC': [
//       { path: '/', label: 'Home' },
//       { path: '/course/:courseName', label: 'Dashboard' },
//       { path: '/profile', label: 'Profile' },
//     ],
//     'AGeE': [
//       { path: '/', label: 'Home' },
//       { path: '/chapterlist', label: 'Chapter Wise' },
//       { path: '/rquiz', label: 'License Practice Exam' },
//       { path: '/profile', label: 'Profile' },
//     ],
//   };

//   const closeMenu = () => setMenuOpen(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 992) closeMenu();
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return (
//     <div style={{ display: 'flex', alignItems: 'center' }}>
//       <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white justify-content-between enhanced-navbar">
//         <div className="container-fluid">
//           <a className="navbar-brand" href="/">
//             <img style={{ width: '120px', padding: '6px' }} src={logo} alt="Logo" />
//           </a>
//           <button
//             className="navbar-toggler justify-content-end"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarNav"
//             aria-controls="navbarNav"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>

//           <div className={`collapse navbar-collapse${menuOpen ? ' show' : ''}`} id="navbarNav">
//             <ul className="navbar-nav">
//               {token === null ? (
//                 <>
//                   <li className="nav-item">
//                     <a className="nav-link active" aria-current="page" href="/" onClick={closeMenu}>
//                       Home
//                     </a>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/login" onClick={closeMenu}>
//                       Login
//                     </Link>
//                   </li>
//                   <li className="nav-item">
//                     <Link className="nav-link" to="/register" onClick={closeMenu}>
//                       Register
//                     </Link>
//                   </li>
//                 </>
//               ) : (
//                 <>
//                   {links[courseCode]?.map((link, index) => (
//                     <li className="nav-item" key={index}>
//                       <Link
//                         className={`nav-link${isExpired && link.label !== 'Home' && link.label !== 'Profile' ? ' disabled' : ''}`}
//                         to={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? '#' : link.path}
//                         onClick={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? (e) => e.preventDefault() : closeMenu}
//                       >
//                         {link.label}
//                       </Link>
//                     </li>
//                   ))}
//                   <li className="nav-item justify-content-end ml-auto">
//                     <button
//                       className="nav-link"
//                       onClick={() => {
//                         logoutUser();
//                         closeMenu();
//                       }}
//                       style={{ cursor: 'pointer', border: 'none', background: 'none' }}
//                     >
//                       Logout
//                     </button>
//                   </li>
//                   <li className="nav-item ml-auto d-flex align-items-center text-right user-greeting">
//                     <span>Hello, {userDetails?.first_name}!</span>
//                     <img
//                       src={userImage}
//                       alt="User Profile"
//                       className="user-avatar rounded-circle ml-2"
//                       style={{ width: '40px', height: '40px' }}
//                     />
//                   </li>
//                 </>
//               )}
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// }

// export default Navbar;



// import React, { useContext, useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import AuthContext from '../context/AuthContext';
// import logo from './logo.png';
// import userImage from './R.png'; // Adjust path accordingly

// function Navbar() {
//   const { logoutUser } = useContext(AuthContext);
//   const token = localStorage.getItem('authTokens');
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [userDetails, setUserDetails] = useState(null);
//   const [isExpired, setIsExpired] = useState(false);

//   useEffect(() => {
//     const storedUserDetails = localStorage.getItem("userDetails");
//     if (storedUserDetails) {
//       const parsedDetails = JSON.parse(storedUserDetails);
//       setUserDetails(parsedDetails);

//       const validityDate = new Date(parsedDetails?.validity || '');
//       const today = new Date();
//       if (validityDate < today) {
//         setIsExpired(true);
//       }
//     }
//   }, []);

//   const courseCode = userDetails?.course?.courseCode;

//   const links = {
//     'SO-PSC': [
//       { path: '/', label: 'Home' },
//       { path: '/course/:courseName', label: 'Dashboard' },
//       { path: '/profile', label: 'Profile' },
//     ],
//     'AGeE': [
//       { path: '/', label: 'Home' },
//       { path: '/chapterlist', label: 'Chapter Wise' },
//       { path: '/rquiz', label: 'License Practice Exam' },
//       { path: '/profile', label: 'Profile' },
//     ],
//   };

//   const closeMenu = () => setMenuOpen(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 1024) closeMenu();
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return (
//     <nav className="bg-white shadow-lg fixed top-0 left-0 right-0 z-50">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex justify-between h-16">
//           <div className="flex-shrink-0 flex items-center">
//             <Link to="/" className="flex items-center">
//               <img className="h-8 w-auto" src={logo} alt="Logo" />
//               <span className="ml-2 text-xl font-bold text-gray-800">Examneer</span>
//             </Link>
//           </div>
//           <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
//             {token === null ? (
//               <>
//                 <Link to="/" className="text-gray-900 hover:bg-gray-100 inline-flex items-center px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out">
//                   Home
//                 </Link>
//                 <Link to="/login" className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 inline-flex items-center px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out">
//                   Login
//                 </Link>
//                 <Link to="/register" className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 inline-flex items-center px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out">
//                   Register
//                 </Link>
//               </>
//             ) : (
//               <>
//                 {links[courseCode]?.map((link, index) => (
//                   <Link
//                     key={index}
//                     to={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? '#' : link.path}
//                     className={`${
//                       isExpired && link.label !== 'Home' && link.label !== 'Profile'
//                         ? 'text-gray-300 cursor-not-allowed'
//                         : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
//                     } inline-flex items-center px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out`}
//                     onClick={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? (e) => e.preventDefault() : closeMenu}
//                   >
//                     {link.label}
//                   </Link>
//                 ))}
//               </>
//             )}
//           </div>
//           <div className="flex items-center">
//             {token !== null && (
//               <div className="hidden lg:flex lg:items-center">
//                 <button
//                   onClick={logoutUser}
//                   className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out"
//                 >
//                   Logout
//                 </button>
//                 <div className="ml-3 relative flex items-center">
//                   <span className="text-gray-700 text-sm mr-2">Hello, {userDetails?.first_name}!</span>
//                   <img
//                     className="h-8 w-8 rounded-full object-cover"
//                     src={userImage}
//                     alt="User avatar"
//                   />
//                 </div>
//               </div>
//             )}
//             <div className="flex items-center lg:hidden">
//               <button
//                 onClick={() => setMenuOpen(!menuOpen)}
//                 className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
//                 aria-expanded="false"
//               >
//                 <span className="sr-only">Open main menu</span>
//                 {menuOpen ? (
//                   <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
//                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
//                   </svg>
//                 ) : (
//                   <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
//                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
//                   </svg>
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Mobile menu */}
//       <div className={`${menuOpen ? 'block' : 'hidden'} lg:hidden`}>
//         <div className="px-2 pt-2 pb-3 space-y-1">
//           {token === null ? (
//             <>
//               <Link to="/" className="text-gray-900 hover:bg-gray-100 block px-3 py-2 rounded-md text-base font-medium" onClick={closeMenu}>
//                 Home
//               </Link>
//               <Link to="/login" className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium" onClick={closeMenu}>
//                 Login
//               </Link>
//               <Link to="/register" className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium" onClick={closeMenu}>
//                 Register
//               </Link>
//             </>
//           ) : (
//             <>
//               {links[courseCode]?.map((link, index) => (
//                 <Link
//                   key={index}
//                   to={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? '#' : link.path}
//                   className={`${
//                     isExpired && link.label !== 'Home' && link.label !== 'Profile'
//                       ? 'text-gray-300 cursor-not-allowed'
//                       : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
//                   } block px-3 py-2 rounded-md text-base font-medium`}
//                   onClick={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? (e) => e.preventDefault() : closeMenu}
//                 >
//                   {link.label}
//                 </Link>
//               ))}
//               <button
//                 onClick={() => {
//                   logoutUser();
//                   closeMenu();
//                 }}
//                 className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium w-full text-left"
//               >
//                 Logout
//               </button>
//               <div className="pt-4 pb-3 border-t border-gray-200">
//                 <div className="flex items-center px-4">
//                   <div className="flex-shrink-0">
//                     <img className="h-10 w-10 rounded-full object-cover" src={userImage} alt="User avatar" />
//                   </div>
//                   <div className="ml-3">
//                     <div className="text-base font-medium text-gray-800">Hello, {userDetails?.first_name}!</div>
//                   </div>
//                 </div>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Navbar;



import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import logo from './logo.png';
import userImage from './R.png';
import { motion, AnimatePresence } from 'framer-motion';

function Navbar() {
  const { logoutUser } = useContext(AuthContext);
  const token = localStorage.getItem('authTokens');
  const [menuOpen, setMenuOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const storedUserDetails = localStorage.getItem("userDetails");
    if (storedUserDetails) {
      const parsedDetails = JSON.parse(storedUserDetails);
      setUserDetails(parsedDetails);

      const validityDate = new Date(parsedDetails?.validity || '');
      const today = new Date();
      if (validityDate < today) {
        setIsExpired(true);
      }
    }
  }, []);

  const courseCode = userDetails?.course?.courseCode;

  const links = {
    'SO-PSC': [
      { path: '/', label: 'Home' },
      { path: '/course/:courseName', label: 'Dashboard' },
      { path: '/profile', label: 'Profile' },
    ],
    'AGeE': [
      { path: '/', label: 'Home' },
      { path: '/chapterlist', label: 'Chapter Wise' },
      { path: '/rquiz', label: 'License Practice Exam' },
      { path: '/profile', label: 'Profile' },
    ],
  };

  const closeMenu = () => setMenuOpen(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) closeMenu();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className="bg-white shadow-lg fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="flex items-center group">
              <img className="h-10 w-auto transition-transform duration-300 ease-in-out group-hover:scale-110" src={logo} alt="Logo" />
              {/* <span className="ml-2 text-xl font-bold text-gray-800 transition-colors duration-300 ease-in-out group-hover:text-green-600">Examneer</span> */}
            </Link>
          </div>
          <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
            {token === null ? (
              <>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/login">Login</NavLink>
                <NavLink to="/register">Register</NavLink>
              </>
            ) : (
              <>
                {links[courseCode]?.map((link, index) => (
                  <NavLink
                    key={index}
                    to={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? '#' : link.path}
                    disabled={isExpired && link.label !== 'Home' && link.label !== 'Profile'}
                    onClick={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? (e) => e.preventDefault() : closeMenu}
                  >
                    {link.label}
                  </NavLink>
                ))}
              </>
            )}
          </div>
          <div className="flex items-center">
            {token !== null && (
              <div className="hidden lg:flex lg:items-center">
                <button
                  onClick={logoutUser}
                  className="text-gray-600 hover:bg-green-100 hover:text-green-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out"
                >
                  Logout
                </button>
                <div className="ml-3 relative flex items-center">
                  <span className="text-gray-700 text-sm mr-2">Hello, {userDetails?.first_name}!</span>
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    className="h-10 w-10 rounded-full object-cover border-2 border-green-500"
                    src={userImage}
                    alt="User avatar"
                  />
                </div>
              </div>
            )}
            <div className="flex items-center lg:hidden">
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 transition duration-300 ease-in-out"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <motion.svg
                  animate={menuOpen ? "open" : "closed"}
                  variants={{
                    open: { rotate: 180 },
                    closed: { rotate: 0 }
                  }}
                  transition={{ duration: 0.3 }}
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </motion.svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="lg:hidden"
          >
            <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg rounded-b-lg">
              {token === null ? (
                <>
                  <MobileNavLink to="/" onClick={closeMenu}>Home</MobileNavLink>
                  <MobileNavLink to="/login" onClick={closeMenu}>Login</MobileNavLink>
                  <MobileNavLink to="/register" onClick={closeMenu}>Register</MobileNavLink>
                </>
              ) : (
                <>
                  {links[courseCode]?.map((link, index) => (
                    <MobileNavLink
                      key={index}
                      to={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? '#' : link.path}
                      disabled={isExpired && link.label !== 'Home' && link.label !== 'Profile'}
                      onClick={isExpired && link.label !== 'Home' && link.label !== 'Profile' ? (e) => e.preventDefault() : closeMenu}
                    >
                      {link.label}
                    </MobileNavLink>
                  ))}
                  <button
                    onClick={() => {
                      logoutUser();
                      closeMenu();
                    }}
                    className="text-gray-600 hover:bg-green-100 hover:text-green-700 block px-3 py-2 rounded-md text-base font-medium w-full text-left transition duration-300 ease-in-out"
                  >
                    Logout
                  </button>
                  <div className="pt-4 pb-3 border-t border-gray-200">
                    <div className="flex items-center px-4">
                      <div className="flex-shrink-0">
                        <motion.img whileHover={{ scale: 1.1 }} className="h-10 w-10 rounded-full object-cover border-2 border-green-500" src={userImage} alt="User avatar" />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-gray-800">Hello, {userDetails?.first_name}!</div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}

const NavLink = ({ children, ...props }) => (
  <Link
    className="text-gray-600 hover:bg-green-100 hover:text-green-700 inline-flex items-center px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out"
    {...props}
  >
    {children}
  </Link>
);

const MobileNavLink = ({ children, ...props }) => (
  <Link
    className="text-gray-600 hover:bg-green-100 hover:text-green-700 block px-3 py-2 rounded-md text-base font-medium transition duration-300 ease-in-out"
    {...props}
  >
    {children}
  </Link>
);

export default Navbar;









